import clsx from "clsx";
import { UsersThree, Clock } from "phosphor-react";
import { Link } from "react-router-dom";
import { useAppPaths } from "../Routes";
import { TalentSummary } from "../models/app/talent";
import Card from "./Card";
import Chip, { ChipContainer } from "./Chip";
import ProfileImage from "./ProfileImage";
import Typography from "./Typography";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { getClickableLink } from "../utils/getClickableLink";
import Tooltip from "./Tooltip";
import BadgeList from "./BadgeList";

export type TalentSummaryCardProps = {
    talent: TalentSummary
}

const TalentSummaryCard = ({
    talent,
}: TalentSummaryCardProps) => {
    const appPaths = useAppPaths();
    const { organizationConfig, talentTerminology } = useOrganizationContext();
    const name = `${talent.firstName} ${talent.lastName}`;
    const displayBadgeSection = talent.badges.length > 0;

    return (
        <Link to={appPaths.talents.findTalentDetails(talent.id)} className="block">
            <Card className={clsx(
                "items-start text-center space-y-6 hover:shadow-xl cursor-pointer",
                "sm:flex sm:text-left sm:space-y-0"
            )}>
                <ProfileImage
                    className="inline-block mx-auto sm:block"
                    imageUrl={talent.profileImageThumbnailUrl}
                    name={name}
                />
                <div className="flex flex-col justify-between self-stretch grow sm:ml-6">
                    <Typography variant="title-large" component="p" className="text-center sm:text-left mb-[0.25rem] font-normal break-words">{name}</Typography>
                    {talent.tradingName && <Typography variant="body" component="p" className="text-center sm:text-left mb-[0.5rem] font-medium">{talent.tradingName}</Typography>}
                    <Tooltip tooltipText="Number of hours completed">
                        <div className="flex flex-row items-start justify-center md:justify-start space-x-2 mb-[1rem]">
                            <Clock size={24} />
                            <Typography variant="body" component="p" className="text-center sm:text-left">{talent.gigCompletedHours}</Typography>
                        </div>
                    </Tooltip>
                    {organizationConfig.isTalentAgencyEnabled && talent?.agency && (
                        <span className="flex mx-auto sm:mx-0 border rounded-md px-[0.5rem] w-fit mb-4">
                            <Tooltip
                                tooltipText={talent.agency.description && talent.agency.description.length > 0 ? talent.agency.description :
                                    `This is the agency that the ${talentTerminology.toLowerCase()} is affiliated with.`
                                }
                            >
                                {talent.agency.url ? (
                                    <a
                                        href={getClickableLink(talent.agency.url)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="w-fit flex flex-row items-center space-x-1"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <UsersThree size={18} />
                                        <p className="text-[0.9rem]">
                                            <b>{talent.agency.name}</b>
                                        </p>
                                    </a>
                                ) : (
                                    <span className="w-fit flex flex-row items-center space-x-1">
                                        <UsersThree size={18} />
                                        <p className="text-[0.9rem]">
                                            <b>{talent.agency.name}</b>
                                        </p>
                                    </span>
                                )}
                            </Tooltip>
                        </span>
                    )}
                    <ChipContainer >
                        {talent.skills.map(skill => (
                            <Chip key={skill.skillId} label={skill.skillName} variant="secondary" />
                        ))}
                    </ChipContainer>
                    {displayBadgeSection &&
                        <BadgeList badges={talent.badges} keyPrefix={talent.id.toString()} className="justify-center mt-4 sm:justify-start" size="sm" />
                    }
                </div>
            </Card>
        </Link>
    );
};

export default TalentSummaryCard;
