import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
    buildNullableUrlValidator,
    linkedinURLValidator,
} from "./AddedExtrasFormValidators";

const schemaFields = {
    portfolioUrl: buildNullableUrlValidator(),
    linkedInUrl: linkedinURLValidator(),
};

export type AddedExtrasFromValues = {
    portfolioUrl?: string
    linkedInUrl?: string
}

const validationSchema = yup.object(schemaFields);

export const useAddedExtrasForm = (defaultValues?: AddedExtrasFromValues) => {
    return useForm<AddedExtrasFromValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            portfolioUrl: defaultValues?.portfolioUrl || "",
            linkedInUrl: defaultValues?.linkedInUrl || "",
        },
    });
};
