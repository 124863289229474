import { ApiGigDetails, ApiGigSummary } from "../api/gig";
import { CreateGigDto, EditableGigFields, expectedDurationOptions, GigDetails, GigSummary } from "../app/gig";

export const mapFromApiGigSummary = (apiDto: ApiGigSummary): GigSummary => ({
    ...apiDto,
    expectedDurationLabel: expectedDurationOptions.find(_ => _.value === apiDto.expectedDurationId)?.label || "",
});

export const mapFromApiGigDetails = (apiDto: ApiGigDetails): GigDetails => ({
    ...mapFromApiGigSummary(apiDto),
    matchesCount: apiDto.matchesCount,
    matches: apiDto.matches,
    isFetchingMatches: apiDto.isFetchingMatches,
    externalMatches: apiDto.externalMatches
});

export const mapFromApiGigDetailsToCreateGig = (dto: GigDetails): CreateGigDto => ({
    ...dto,
    industryId: dto.industry?.id || "",
    skillIds: [dto.skills[0].id],
    languageId: dto.language?.id ?? "",
    locationId: dto.location?.countryId || "",
    localityId: dto.location?.locality.localityId || "",
    title: dto.title
});

export const mapToApiGig = <TApi extends EditableGigFields>(apiDto?: TApi):
    TApi extends undefined ? undefined : Omit<TApi, "startDate" | "deadlineDate"> & { startDate: string, deadlineDate?: string } => {
    if (apiDto === undefined) {
        return undefined as ReturnType<typeof mapToApiGig>;
    }

    return {
        ...apiDto,
        startDate: apiDto.startDate,
    } as ReturnType<typeof mapToApiGig>;
};