import { useState, useEffect } from "react";
import { getCurrentOrganizationId } from "../api/organizations";
import config from "../config.json";

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

const readGiggedApiScope = config.AuthGigApiReadScope;

export type UseLoginRequestReturn = {
    isLoading: boolean
    loginRequest?: LoginRequest
}

export type LoginRequest = {
    scopes: string[]
    extraQueryParameters: {
        organizationId: string
    }
}

export const useLoginRequest = (): UseLoginRequestReturn => {
    const [loginRequest, setLoginRequest] = useState<LoginRequest | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            
            try {
                const organizationId = await getCurrentOrganizationId();
    
                setLoginRequest({
                    scopes: [readGiggedApiScope],
                    extraQueryParameters: { 
                        organizationId: organizationId
                    }
                });
            } catch (error) {
                console.error("Failed to fetch organization ID:", error);
            } finally {
                setIsLoading(false);
            }

            setIsLoading(false);
        };

        fetchData();
    }, []);

    return {
        isLoading: isLoading,
        loginRequest: loginRequest
    };
};