
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Tab } from "@headlessui/react";
import ProgressBar from "./ProgressBar";

export type TabStepperPage = {
    title: string
    content: JSX.Element
}

type TabStepperProps = {
    pages: TabStepperPage[]
    selectedIndex: number
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
}

const TabStepper = ({
    pages,
    selectedIndex,
    setSelectedIndex,
}: TabStepperProps) => {
    const [maxCompletedIndex, setMaxCompletedIndex] = useState(-1);

    useEffect(() => {
        setMaxCompletedIndex(Math.max(maxCompletedIndex, selectedIndex));
    }, [selectedIndex]);

    return (
        <div>
            <div className="mx-8">
                <ProgressBar currentStepIndex={selectedIndex} numberOfSteps={pages.length} />
            </div>
            <Tab.Group selectedIndex={selectedIndex} onChange={newIndex => setSelectedIndex(newIndex)}>
                <Tab.List className="space-x-1 rounded-xl p-1 mb-4 hidden md:flex">
                    {pages.map((page, index) => (
                        <Tab
                            key={index}
                            className={({ selected }) =>
                                clsx(
                                    "w-full rounded-lg py-1 text-sm font-medium leading-5 pointer-events-none",
                                    selected && "bg-gray-400",
                                    index > maxCompletedIndex && "text-gray-400"
                                )
                            }
                        >
                            {page.title}
                        </Tab>
                    ))}
                </Tab.List>
                <p className="mb-4 mt-2 text-center md:hidden">
                    {pages[selectedIndex].title}
                </p>
                <Tab.Panels>
                    {pages.map((page, index) => (
                        <Tab.Panel key={index}>
                            {page.content}
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};

export default TabStepper;