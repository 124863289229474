import clsx from "clsx";
import { Info } from "phosphor-react";

export type InfoBoxProps = {
    children: React.ReactNode
    className?: string
    variant?: "info" | "warning"
    RootElement?: "p" | "div"
}

const InfoBox = ({
    children,
    className,
    variant = "warning",
    RootElement = "p"
}: InfoBoxProps) => {
    return (
        <div className={clsx(
            "flex items-center border-l-8 p-4 rounded-lg",
            variant === "info" ? "border-secondary bg-surface-accent" : "border-warning bg-surface-warning",
            className,
        )}>
            <Info size="24" className={clsx(
                "shrink-0",
                variant === "info" ? "text-secondary" : "text-warning",
            )}/>
            <RootElement className="ml-4">{children}</RootElement>
        </div>
    );
};

export default InfoBox;
