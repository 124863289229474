import { Link } from "react-router-dom";

import Card from "../../components/Card";
import { useAppPaths } from "../../Routes";
import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import UserAvatar from "../../components/UserAvatar";
import { useTalent } from "../../api/talent";
import { ClientGigInvitesDto } from "../../models/api/client";
import Typography from "../../components/Typography";
import { formatDateTime } from "../../utils/dateFormatters";
import GigInviteStatusChip from "../../components/GigInviteStatusChip";
import AuditDataList from "../../components/AuditData/AuditDataList";
import useCurrentUser from "../../auth/useCurrentUser";
import { hasClaim } from "../../auth/hasClaim";
import { UserRole } from "../../models/app/userMe";

export type GigInviteCardProps = {
    invite: ClientGigInvitesDto
}

const GigInviteCard = ({
    invite,
}: GigInviteCardProps) => {
    const appPaths = useAppPaths();
    const { talent } = useTalent(invite.talentId);
    const { claims } = useCurrentUser();
    const isTalent = hasClaim(UserRole.AC, claims);

    const {
        isLoading: isLoadingClientPersonalInformation
    } = useClientPersonalInformationContext();

    if (isLoadingClientPersonalInformation) {
        return <p>Loading...</p>;
    }


    return (
        <Card className="grow">
            <div className="flex flex-col md:flex-row gap-6">
                <div className="sm:w-28 rounded-full flex justify-start w-full">
                    <Link to={appPaths.talents.findTalentDetails(invite.talentId)}>
                        <UserAvatar name={invite.talentName} userImageUrl={talent?.profileImageUrl} showName={false} size="large" />
                    </Link>
                </div>
                <div className="w-full flex flex-row justify-between items-center">
                    <div className="flex flex-col flex-wrap">
                        <div className="flex flex-row justify-between w-full">
                            <div className="flex flex-col space-y-2">
                                <Link to={appPaths.talents.findTalentDetails(invite.talentId)} className="flex-none">
                                    <Typography component="span" variant="body" className="font-bold text-primary-hover">{invite.talentName}</Typography>
                                </Link>
                                <Typography component="span" variant="body">{`${talent?.location?.locality.locality}, ${talent?.location?.country}`}</Typography>
                                {invite.declinedReason && (
                                    <div className="flex flex-col">
                                        <span className="font-bold">Decline reason: </span>
                                        {invite.declinedReason}
                                    </div>
                                )}
                                {!isTalent && <AuditDataList sentBy={invite.sentBy} />}
                            </div>
                        </div>
                    </div>
                    <GigInviteStatusChip inviteStatusId={invite.inviteStatusId} />
                </div>
            </div>
        </Card >
    );
};

export default GigInviteCard;
