import { Clock, UsersThree } from "phosphor-react";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useTalent } from "../../api/talent";
import useCurrentUser from "../../auth/useCurrentUser";
import InfoBox from "../../components/InfoBox";
import ProfileImage from "../../components/ProfileImage";
import ReportButton from "../../components/Reports";
import SanitizeHtml from "../../components/SanitizeHtml";
import Typography from "../../components/Typography";
import { useTalentId } from "../../Routes";
import InviteToGigButton from "./InviteToGigFormDialog";
import SkillsAndExperienceCard from "./SkillsAndExperienceCard";
import TalentProfileDetailsCard from "./TalentProfileDetailsCard";
import Loader from "../../components/Loader";
import { getClickableLink } from "../../utils/getClickableLink";
import Tooltip from "../../components/Tooltip";
import BadgeList from "../../components/BadgeList";
import { DeliveryTypeDisplay } from "./DeliveryTypeDisplay";
import { hasClaim } from "../../auth/hasClaim";
import SplitChip from "../../components/SplitChip";
import { UserRole } from "../../models/app/userMe";
import { useIsSmallScreen } from "../../hooks/screenSizes";

const FindTalentDetailsPage = () => {
    const { talentId: currentUserTalentId, claims } = useCurrentUser();
    const isDefaultClient = hasClaim(UserRole.Default, claims);
    const { organizationConfig, talentTerminology } = useOrganizationContext();
    const talentId = useTalentId();
    const isSmallScreen = useIsSmallScreen();

    const canViewProfile =
        !organizationConfig?.isTalentProfilesPrivate ||
        currentUserTalentId === talentId ||
        hasClaim(UserRole.Default, claims);

    if (!canViewProfile) {
        return (
            <div className="flex flex-col my-auto items-center mt-8">
                <InfoBox className="w-1/2">
                    Sorry, you don't have the correct privileges to view this page.
                </InfoBox>
            </div>);
    }

    const { talent, isLoading } = useTalent(talentId);

    if (!talent || isLoading) return <Loader />;

    const talentName = `${talent.firstName} ${talent.lastName}`;
    const displayBadgeSection = talent?.badges.length > 0;

    return (
        <>
            <div className="pt-20 md:pt-20 pb-20 md:pb-12 bg-tertiary">
                <div className="max-w-6xl mx-auto">
                    <div className="mx-4 md:mx-8">
                        <div className="items-start text-center space-y-6 sm:flex sm:text-left sm:space-y-0">
                            <ProfileImage
                                className="inline-block mx-auto sm:block"
                                imageUrl={talent.profileImageUrl}
                                name={talentName}
                                size="lg"
                            />
                            <div className="self-stretch grow sm:ml-6">
                                <Typography variant="headline-small" component="h1" className="text-center sm:text-left my-2 text-tertiary-bg-text break-words">{talentName}</Typography>
                                {talent.tradingName && <p className="text-tertiary-bg-text mb-4">{talent.tradingName}</p>}

                                {organizationConfig.isTalentAgencyEnabled && talent?.agency && (
                                    <span className="flex mx-auto sm:mx-0 !sm:mr-auto bg-surface rounded-md px-[0.5rem] w-fit mb-4">
                                        <Tooltip
                                            tooltipText={talent.agency.description && talent.agency.description.length > 0 ? talent.agency.description :
                                                `This is the agency that the ${talentTerminology.toLowerCase()} is affiliated with.`
                                            }
                                        >
                                            {talent.agency.url ? (
                                                <a
                                                    href={talent.agency?.url ? getClickableLink(talent.agency?.url) : ""}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="w-fit flex flex-row items-center space-x-1"
                                                >
                                                    <UsersThree size={18} />
                                                    <p className="text-[0.9rem]">
                                                        <b> {talent.agency.name}</b>
                                                    </p>
                                                </a>
                                            ) : (
                                                <span className="w-fit flex flex-row items-center space-x-1">
                                                    <UsersThree size={18} />
                                                    <p className="text-[0.9rem]">
                                                        <b> {talent.agency.name}</b>
                                                    </p>
                                                </span>
                                            )}
                                        </Tooltip>
                                    </span>
                                )}
                                {isDefaultClient && (
                                    <Tooltip tooltipText="Number of hours completed">
                                        <div className="flex flex-row items-start justify-center md:justify-start space-x-2 mb-[1rem] ">
                                            <Clock size={24} className="text-tertiary-bg-text"/>
                                            <Typography variant="body" component="p" className="text-center text-tertiary-bg-text sm:text-left">{talent.gigCompletedHours}</Typography>
                                        </div>
                                    </Tooltip>
                                )}
                                {displayBadgeSection &&
                                    <BadgeList badges={talent.badges} keyPrefix={talent.id.toString()} className="mb-4" />
                                }
                                <div className={`flex ${isSmallScreen ? "space-x-2" : "flex-col space-y-2"} mt-2`}>
                                    {hasClaim(UserRole.Resources, claims) && (
                                        <div className="flex-shrink-0">
                                            <InviteToGigButton talentId={talent.id} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="bg-primary h-[3px] mt-8 mb-8 sm:mb-12" />
                        <div className="block sm:flex space-x-0 space-y-8 sm:space-x-12 sm:space-y-0">
                            <div className="grid grid-cols-2 sm:flex sm:flex-col gap-4 sm:w-1/4">
                                {talent.location && (
                                    <div>
                                        <label className="text-tertiary-bg-text text-label-small whitespace-nowrap font-semibold">Location</label>
                                        <p className="text-tertiary-bg-text">{`${talent.location.locality.locality}, ${talent.location.country}`}</p>
                                    </div>
                                )}
                                {talent.languages && talent.languages.length > 0 && (
                                    <div>
                                        <label className="text-tertiary-bg-text text-label-small whitespace-nowrap font-semibold">Languages</label>
                                        {talent.languages?.length === 1 ? (
                                            <p className="text-tertiary-bg-text">{`${talent.languages[0].languageName} (${talent.languages[0].languageProficiencyName})`}</p>
                                        ) : (
                                            <ul className="list-disc pl-4">
                                                {talent.languages.map((language) => (
                                                    <li key={language.languageId} className="text-tertiary-bg-text">
                                                        {`${language.languageName} (${language.languageProficiencyName})`}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                )}
                                {talent.deliveryTypes && talent.deliveryTypes.length > 0 && (
                                    <div>
                                        <label className="text-tertiary-bg-text text-label-small whitespace-nowrap font-semibold">Delivery types</label>
                                        <DeliveryTypeDisplay deliveryTypes={talent.deliveryTypes || []} />
                                    </div>
                                )}
                                {talent.wheelPosition && (
                                    <div className="space-y-2">
                                        <label className="text-tertiary-bg-text text-label-small whitespace-nowrap font-semibold">Wheel position</label>
                                        <SplitChip
                                            primaryValue={talent.wheelPosition.wheelPosition}
                                            primaryBgColor={talent.wheelPosition.primaryBgColor}
                                            primaryTextColor={talent.wheelPosition.primaryTextColor}
                                            secondaryValue={talent.wheelPosition.secondary}
                                            secondaryBgColor={talent.wheelPosition.secondaryBgColor}
                                            secondaryTextColor={talent.wheelPosition.secondaryTextColor}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="space-y-6">
                                {talent.aboutMe && (
                                    <p className="text-tertiary-bg-text whitespace-pre-wrap">
                                        <SanitizeHtml html={talent.aboutMe} />
                                    </p>
                                )}
                                {organizationConfig.isReportsEnabled && (
                                    <div>
                                        <ReportButton
                                            type="Talent"
                                            entityId={talentId}
                                            className="text-white hover:text-surface-accent"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="-mt-6 max-w-6xl mx-auto">
                <div className="sm:flex sm:items-start space-y-4 sm:space-y-0 sm:space-x-6 mx-4 md:mx-8">
                    <TalentProfileDetailsCard className="sm:w-1/2 grow" talent={talent} />
                    <SkillsAndExperienceCard className="sm:min-w-[355px]" talent={talent} />
                </div>
            </div>
        </>
    );
};

export default FindTalentDetailsPage;
