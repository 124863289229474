import clsx from "clsx";
import { LinkedinLogo } from "phosphor-react";
import Button from "../../components/Button";
import Card from "../../components/Card";
import FilePreview from "../../components/FilePreview";
import Typography from "../../components/Typography";
import { ApiClientProfileDto } from "../../models/api/clientProfile";
import { getClickableLink } from "../../utils/getClickableLink";

export type ClientProfileDetailsCardProps = {
    clientProfile: ApiClientProfileDto
    className?: string
}

const ClientProfileDetailsCard = ({
    clientProfile,
    className,
}: ClientProfileDetailsCardProps) => {

    return (
        <Card className={clsx("space-y-8 max-w-full md:max-w-[25%]", className)}>
            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Website</Typography>
                {!clientProfile.companyWebsite ? (
                    <p>None added yet.</p>
                ) : (
                    <div className="flex flex-row gap-6">
                        <a
                            href={getClickableLink(clientProfile.companyWebsite)}
                            target="_blank"
                            className="underline font-bold truncate"
                        >
                            {clientProfile.companyWebsite}
                        </a>
                    </div>
                )}
            </div>

            <div>
                <Typography variant="title-large" component="h2" underline className="mb-6">Brochures</Typography>
                {clientProfile.brochures.length === 0 ? (<p>None added yet.</p>) : (
                    <div className="flex flex-col gap-2">
                        {clientProfile.brochures.map(brochure => (
                            <FilePreview
                                url={brochure.file.url}
                                name={brochure.file.name}
                                totalBytes={brochure.file.totalBytes}
                            />
                        ))}
                    </div>
                )}
            </div>
        </Card>
    );
};

export default ClientProfileDetailsCard;
