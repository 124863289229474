import { useState } from "react";
import { useGig } from "../../api/gig";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Button from "../Button";
import DialogBox, { useDialogBoxState } from "../DialogBox";
import FormTextAreaInput from "../FormTextAreaInput";
import { InviteTalentFormDialogValues, useInviteToGigForm } from "./InviteToGigFormDialogValues";
import ApiError from "../../api/common/apiError";
import GeneralFormError from "../GeneralFormError";
import { dialogWidths } from "../DialogBox/DialogBox";

export type InviteToGigFormDialogProps = {
    talentId: string
    isInvited: boolean
    gigId: string
    className?: string
}
const InviteToGigFormDialog = ({
    talentId,
    isInvited,
    gigId,
    className,
}: InviteToGigFormDialogProps) => {
    const { inviteToGig } = useGig(gigId);
    const methods = useInviteToGigForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const dialogState = useDialogBoxState();
    const { gigTerminology, talentTerminology } = useOrganizationContext();

    const handleConfirmInvite = async (values: InviteTalentFormDialogValues) => {
        setIsSubmitting(true);

        const response = await inviteToGig({
            message: values.message,
            talentId,
        });

        setIsSubmitting(false);

        if (response.success) {
            dialogState.close();
            return;
        }

        setSubmissionError(response.error);
    };

    return (
        <span className={className}>
            <div className="m-auto">
                <Button onClick={dialogState.onOpen} disabled={isInvited} className="w-full mt-6 sm:mt-0">
                    {isInvited ? "Invited" : `Invite to ${gigTerminology.toLowerCase()}`}
                </Button>
            </div>
            <form onSubmit={methods.handleSubmit(handleConfirmInvite)}>
                <DialogBox
                    {...dialogState}
                    title={`Invite to ${gigTerminology.toLowerCase()}`}
                    minWidth={dialogWidths.medium}
                >
                    <FormTextAreaInput
                        id="invite-to-gig-message"
                        rows={7}
                        placeholder="You can use this message to provide the AC with any additional information about the event. You may also include reasons why they have been selected."
                        register={methods.register("message")}
                        error={methods.formState.errors.message}
                        disabled={methods.formState.isSubmitting}
                    />
                    
                    <GeneralFormError error={submissionError} />
                    
                    <div className="mt-4">
                        <Button className="w-full" type="submit" loading={isSubmitting}>Send invite</Button>
                    </div>
                </DialogBox>
            </form>
        </span>
    );
};

export default InviteToGigFormDialog;