import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { ApiTalentDeliveryTypeDto } from "../models/api/talentDeliveryType";

const useAddTalentDeliveryType = () => useAuthenticatedGigApiFetcher<never, void, number>("POST",
    (deliveryTypeId) => ({
        url: `api/me/delivery-type/${deliveryTypeId}`,
    })
);

const useRemoveTalentDeliveryType = () => useAuthenticatedGigApiFetcher<never, void, number>("DELETE",
    (deliveryTypeId) => ({
        url: `api/me/delivery-type/${deliveryTypeId}`,
    })
);

export type UseTalentDeliveryTypeReturn =
    UseGiggedApiSWRReturn<ApiTalentDeliveryTypeDto[]> &
    {
        talentDeliveryTypes: ApiTalentDeliveryTypeDto[]
        addTalentDeliveryType: (deliveryTypeId: number) => Promise<GigApiFetcherResponse<void>>
        removeTalentDeliveryType: (deliveryTypeId: number) => Promise<GigApiFetcherResponse<void>>
        isAddingTalentDeliveryType: boolean
        isRemovingTalentDeliveryType: boolean
    }

export const useTalentDeliveryType = (): UseTalentDeliveryTypeReturn => {
    const apiCall = useGiggedApiSWR<ApiTalentDeliveryTypeDto[]>("api/me/delivery-type");
    const [addTalentDeliveryType, isAddingTalentDeliveryType] = useAddTalentDeliveryType();
    const [removeTalentDeliveryType, isRemovingTalentDeliveryType] = useRemoveTalentDeliveryType();

    return {
        ...apiCall,
        talentDeliveryTypes: apiCall.data || [],
        addTalentDeliveryType: async (deliveryTypeId: number) => {
            const response = await addTalentDeliveryType(deliveryTypeId);

            if (response.success) apiCall.mutate();

            return response;
        },
        removeTalentDeliveryType: async (deliveryTypeId: number) => {
            const response = await removeTalentDeliveryType(deliveryTypeId);

            if (response.success) apiCall.mutate();

            return response;
        },
        isAddingTalentDeliveryType,
        isRemovingTalentDeliveryType
    };
};