import { useState } from "react";
import { useGig } from "../../api/gig";
import Button from "../../components/Button";
import DialogBox from "../../components/DialogBox";
import { gigStatuses } from "../../models/app/gig";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import ApiError from "../../api/common/apiError";
import GeneralFormError from "../../components/GeneralFormError";
import Typography from "../../components/Typography";

export type CompleteGigButtonProps = {
    gigId: string
    className?: string
    disabled?: boolean
}
const CompleteGigButton = ({
    gigId,
    className,
    disabled,
}: CompleteGigButtonProps) => {
    const { completeGig, gig } = useGig(gigId);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { gigTerminology } = useOrganizationContext();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const handleConfirm = async () => {
        setIsSubmitting(true);

        const response = await completeGig(gigId);

        setIsSubmitting(false);

        if (response.success) {
            setIsDialogOpen(false);
            return;
        }

        setSubmissionError(response.error);
    };

    if (gig?.gigStatusId === gigStatuses.cancelled) return null;

    return (
        <span className={className}>
            <div className="m-auto">
                <Button
                    onClick={() => setIsDialogOpen(true)}
                    className="w-full"
                    disabled={gig?.gigStatusId !== gigStatuses.hired || disabled}
                >
                    {gig?.gigStatusId === gigStatuses.hired ? `Complete ${gigTerminology.toLowerCase()}` : "Completed"}
                </Button>
            </div>
            <DialogBox
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                title={`Complete ${gigTerminology.toLowerCase()}`}
            >
                <GeneralFormError error={submissionError} className="ml-8" />
                <div className="w-full space-y-6 flex flex-col">
                    <Typography variant="body" component="p">Are you sure you want to mark this {gigTerminology.toLowerCase()} as complete?</Typography>
                    <div className="w-full justify-between flex text-right">
                        <Button className="w-fit" type="button" loading={isSubmitting} onClick={() => setIsDialogOpen(false)} variant="secondary">Back</Button>
                        <Button className="w-fit" type="submit" loading={isSubmitting} onClick={handleConfirm}>Complete {gigTerminology.toLowerCase()}</Button>
                    </div>
                </div>
            </DialogBox>
        </span>
    );
};

export default CompleteGigButton;