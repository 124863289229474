import Chip, { ChipContainer } from "../../components/Chip";
import { ApiTalentDeliveryTypeDto } from "../../models/api/talentDeliveryType";

export type TalentDeliveryTypeChipsProps = {
    deliveryTypes: ApiTalentDeliveryTypeDto[],
    removeTalentDeliveryType: (deliveryTypeId: number) => void,
    isRemovingDeliveryType: boolean
}

const TalentDeliveryTypeChips = ({
    deliveryTypes,
    removeTalentDeliveryType,
    isRemovingDeliveryType,
}: TalentDeliveryTypeChipsProps) => {

    if (isRemovingDeliveryType) return (
        <p className="mt-2">Loading...</p>
    );

    return (
        <ChipContainer className="pt-6">
            {deliveryTypes
                .sort((a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()))
                .map(deliveryType => (
                    <Chip
                        key={`talent-delivery-type-${deliveryType.deliveryTypeId}`}
                        label={deliveryType.deliveryTypeName}
                        onDelete={() => removeTalentDeliveryType(deliveryType.deliveryTypeId)}
                    />
                ))
            }
        </ChipContainer>
    );
};

export default TalentDeliveryTypeChips;