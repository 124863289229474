import { ApiTalentPersonalInformationDto, ApiUpdateTalentPersonalInformationDto } from "../api/talentPersonalInformation";
import { TalentPersonalInformationDto, UpdateTalentPersonalInformationDto } from "../app/talentPersonalInformation";

export const mapFromApiTalentPersonalInformation = (apiDto: ApiTalentPersonalInformationDto): TalentPersonalInformationDto => ({
    ...apiDto,
    insuranceExpiryDate: apiDto.insuranceExpiryDate ? new Date(apiDto.insuranceExpiryDate) : null
});

export const mapToApiUpdateTalentPersonalInformation = (dto: UpdateTalentPersonalInformationDto): ApiUpdateTalentPersonalInformationDto => {
    return {
        ...dto,
        insuranceExpiryDate: dto.insuranceExpiryDate ? dto.insuranceExpiryDate.toISOString() : undefined
    };
};