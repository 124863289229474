import clsx from "clsx";
import { X } from "phosphor-react";

export type ChipProps = {
  label: string | string[]
  onDelete?: () => void
  variant?: "primary" | "secondary"
  className?: string
  size?: "normal" | "large"
  isStatus?: boolean
}

const variantClasses = {
    primary: "bg-chip-primary text-chip-primary-text",
    secondary: "bg-chip-secondary text-chip-secondary-text"
};

const statusClasses = {
    primary: "bg-status-primary text-status-primary-text",
    secondary: "bg-status-secondary text-status-secondary-text",
    undefined: "bg-status-undefined text-status-undefined-text"
};

const Chip = ({
    label,
    onDelete,
    variant,
    className,
    size = "normal",
    isStatus = false
}: ChipProps) => {

    const variantClass = variant ? variantClasses[variant] : variantClasses["primary"];
    const statusClass = variant && isStatus ? statusClasses[variant] : variant === undefined && isStatus ? statusClasses["undefined"] : "";

    return (
        <span className={clsx(
            "rounded-full inline-flex gap-3 align-center w-max",
            size === "large" ? "py-[0.75rem] px-[1.5rem]" : "px-4 py-2 text-chip",
            variantClass,
            statusClass,
            className
        )}>
            <span className={clsx(
                "font-bold",
                size === "normal" && "text-chip",
            )}>{label}</span>
            {onDelete && (
                <button 
                    className="bg-transparent hover focus:outline-none cursor-pointer"
                    onClick={onDelete}
                >
                    <X
                        weight="bold"
                        className="h-4 w-4"
                        aria-hidden="true"
                    />
                </button>
            )}
        </span>
    );
};

export default Chip;