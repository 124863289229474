import { SortOrder } from "../../api/common/sortOrder";
import { ApiCancelGigDto, ApiCreateGigDto, ApiEditableGigFields, ApiGigDetails, ApiGigSkill, ApiGigSummary, ApiUpdateGigDto } from "../api/gig";

type AppGig<T> = Omit<T, "deadlineDate">;

export type EditableGigFields = AppGig<ApiEditableGigFields>

export type CreateGigDto = AppGig<ApiCreateGigDto>
export type UpdateGigDto = AppGig<ApiUpdateGigDto>

export type GigSummary =  AppGig<ApiGigSummary> & {
    expectedDurationLabel: string
}

export type GigDetailsSkill = ApiGigSkill

export type GigDetails = AppGig<ApiGigDetails> & {
    expectedDurationLabel: string
}

export type InviteToGigDto = {
    talentId: string
    message: string
}

export type AddFileToGigDto = {
    fileDataUri: string
}

export type DeleteFileFromGigDto = {
    fileId: string
}


export type CancelGigDto = ApiCancelGigDto;

export const gigStatuses = {
    draft: 0,
    posted: 1,
    hired: 2,
    cancelled: 3,
    completed: 4,
    pending: 5,
};

export const expectedDurationOptions = [
    { label: "1 hour", value: 0 },
    { label: "2 hours", value: 1 },
    { label: "3 hours", value: 2 },
    { label: "4 hours", value: 3 },
    { label: "5 hours", value: 4 },
    { label: "6 hours", value: 5 },
    { label: "7 hours", value: 6 },
    { label: "1 day", value: 7 },
    { label: "2 days", value: 8 },
    { label: "3 days", value: 9 },
    { label: "4 days", value: 10 },
    { label: "5 days", value: 11 },
];

export const radioOptions = [
    {label: "Yes", value: "true"},
    {label: "No", value: "false"},
];

export const deliveryTypeOptions = [
    {label: "Face to face", value: 0},
    {label: "Virtual", value: 1}
];

export const remoteFilterOptions = [
    {label: "Yes", value: "true"},
    {label: "No", value: "false"},
    {label: "Show all", value: ""}
];

const gigOrderByFields = [
    { value: "Title", label: "Title" },
    { value: "CreatedAt", label: "Created At" },
    { value: "StartDate", label: "Start Date" },
    { value: "DeadlineDate", label: "Deadline Date" },
    { value: "ExpectedDurationId", label: "Expected Duration" },
] as const;

export type GigOrderByFieldKeys = typeof gigOrderByFields[number]["value"] |
    "PostedDate" |
    "CompletedDate" |
    "CancelledDate";

export const gigOrderByOptions = gigOrderByFields.flatMap(({ value, label }): {
    value: `${GigOrderByFieldKeys}:${SortOrder}`
    label: string
}[] => {
    return [
        {
            value: `${value}:asc`,
            label: `Sort by ${label.toLowerCase()} ASC`,
        },
        {
            value: `${value}:desc`,
            label: `Sort by ${label.toLowerCase()} DESC`,
        },
    ];
});