import FilterButton from "./FilterButton";
import { useFindPageContext } from "./findPageContext";
import FindPagePanel from "./FindPagePanel";
import { FindPagePanelSectionProps } from "./FindPagePanelSection";

export type FindPageProps = {
    children: React.ReactNode
    filterPanelSections: FindPagePanelSectionProps[]
    title: string
    searchTerm: string
    anyActiveFilters: boolean
    setSearchTerm: (value: string) => void
    clearAllFilters: () => void
    searchNow: () => void
    sortOrderDropdown: React.ReactNode
    searchPlaceholder: string
}

const FindPageComponent = ({
    children,
    filterPanelSections,
    title,
    searchTerm,
    anyActiveFilters,
    setSearchTerm,
    clearAllFilters,
    searchNow,
    sortOrderDropdown,
    searchPlaceholder
}: FindPageProps) => {
    const { isFilterPanelOpen, setIsFilterPanelOpen} = useFindPageContext();

    return (
        <div className="m-4 lg:mx-8">
            <FilterButton
                anyActiveFilters={anyActiveFilters}
                clearAllFilters={clearAllFilters}
                setIsFilterPanelOpen={setIsFilterPanelOpen}
            />
            {sortOrderDropdown}
            <div className="flex space-x-6">
                <FindPagePanel
                    panelSections={filterPanelSections}
                    searchTerm={searchTerm}
                    title={title}
                    anyActiveFilters={anyActiveFilters}
                    isFilterPanelOpen={isFilterPanelOpen}
                    setIsFilterPanelOpen={setIsFilterPanelOpen}
                    setSearchTerm={setSearchTerm}
                    clearAllFilters={clearAllFilters}
                    searchNow={searchNow}
                    searchPlaceholder={searchPlaceholder}
                />
                {children}
            </div>
        </div>
    );
};

export default FindPageComponent;
