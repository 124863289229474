import { useEffect, useState } from "react";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";

import useCurrentUser from "../../auth/useCurrentUser";
import { hasClaim } from "../../auth/hasClaim";
import { useGig } from "../../api/gig";
import Button from "../../components/Button";
import GigDetailsCard from "../../components/GigDetailsCard";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useGigId } from "../../Routes";
import MatchesList from "./MatchesList";
import Loader from "../../components/Loader";
import MatchingProgressIndicator from "./MatchingProgressIndicator";
import TitleUnderline from "../../components/TitleUnderline";
import { UserRole } from "../../models/app/userMe";

const GigsMatchesListPage = () => {
    const user = useCurrentUser();
    const gigId = useGigId();
    const { gig, isLoading, regenerateMatches, isRegeneratingMatches, reload } = useGig(gigId);
    const [forceReload, setForceReload] = useState(false);
    const { gigTerminology } = useOrganizationContext();

    useEffect(() => {
        const interval = setInterval(() => {
            setForceReload(value => !value);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (isLoading) return;
        if (gig?.isFetchingMatches) reload();
    }, [isLoading, forceReload]);

    if (isLoading || !gig) return <Loader />;

    return (
        <PageContent>
            <Typography variant="display-medium" component="h1" gutterBottom>{gigTerminology}</Typography>
            <div className="mb-8">
                <GigDetailsCard gig={gig} />
            </div>
            {!hasClaim(UserRole.Sales, user.claims) && (
                <div className="sm:flex items-center mb-4 space-y-4 sm:space-y-0">
                    <Typography variant="display-small" component="h2">Matches</Typography>
                    <Button className="ml-auto" variant="secondary" onClick={regenerateMatches} disabled={isRegeneratingMatches || gig.isFetchingMatches}>Refresh matches</Button>
                </div>
            )}
            {(isRegeneratingMatches || gig.isFetchingMatches) ? (
                <div className="mt-6 sm:mt-8 text-center">
                    <MatchingProgressIndicator />
                </div>
            ) : (
                <>
                    {hasClaim(UserRole.Sales, user.claims) ? (
                        <div className="flex flex-col space-y-4 text-center items-center">
                            <TitleUnderline />
                            <Typography variant="title-large" component="h3">Matching Complete</Typography>
                            <Typography variant="title-small" component="h3">{gig.matchesCount} matches were found based on the specifications of the workshop.</Typography>
                        </div>
                    ) : (
                        <div className="flex flex-col space-y-8 my-8">
                            <MatchesList
                                gigId={gig.id}
                                matches={gig.matches}
                                externalMatches={gig.externalMatches?.matches || []}
                            />
                        </div>
                    )}
                </>
            )}
        </PageContent>
    );
};

export default GigsMatchesListPage;