
import { useState } from "react";
import { useGig } from "../api/gig";
import Button from "./Button";
import DialogBox from "./DialogBox";
import GeneralFormError from "./GeneralFormError";
import Typography from "./Typography";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import ApiError from "../api/common/apiError";
import useCurrentUser from "../auth/useCurrentUser";
import { hasClaim } from "../auth/hasClaim";
import { UserRole } from "../models/app/userMe";
import clsx from "clsx";

export type ConfirmGigButtonProps = {
    gigId: string
    className?: string
}
const ConfirmGigButton = ({
    gigId,
    className,
}: ConfirmGigButtonProps) => {
    const { confirmGig, isConfirmingGig, gig } = useGig(gigId);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { gigTerminology, talentTerminology } = useOrganizationContext();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const { claims } = useCurrentUser();
    const isResources = hasClaim(UserRole.Resources, claims);

    const handleConfirm = async () => {
        if (!gig) return;

        setIsSubmitting(true);

        const response = await confirmGig(gig.id);

        setIsSubmitting(false);

        if (response.success) {
            setIsDialogOpen(false);
            return;
        }

        setSubmissionError(response.error);
    };

    return (
        <span className={className}>
            <div className="m-auto">
                <Button
                    onClick={() => setIsDialogOpen(true)}
                    className={clsx("w-full", !isResources && !gig?.isConfirmed && "hidden")}
                    disabled={!gig || isConfirmingGig || gig?.isConfirmed || !isResources}
                    loading={!gig}
                    variant={!gig?.isConfirmed ? "primary" : "secondary"}
                >
                    {!gig?.isConfirmed ? "Confirm" : "Confirmed"}
                </Button>
            </div>
            <DialogBox
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                title={`Confirm ${gigTerminology.toLowerCase()}`}
            >
                <GeneralFormError error={submissionError} className="ml-8" />
                <div className="w-full space-y-6 flex flex-col">
                    <Typography variant="body" component="p">Are you sure you want to mark this {gigTerminology.toLowerCase()} as confirmed by the client?</Typography>
                    <Typography variant="body" component="p">Once confirmed, the {talentTerminology.toLowerCase()} will be notified.</Typography>
                    <div className="w-full justify-between flex text-right">
                        <Button className="w-fit" type="button" disabled={isSubmitting} onClick={() => setIsDialogOpen(false)} variant="secondary">Back</Button>
                        <Button className="w-fit" type="submit" loading={isSubmitting} onClick={handleConfirm}>Confirm {gigTerminology.toLowerCase()}</Button>
                    </div>
                </div>
            </DialogBox>
        </span>
    );
};

export default ConfirmGigButton;