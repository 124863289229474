import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { tomorrowAtMidnight } from "../../utils/dateFormatters";

export type TalentPersonalDetailsFormValues = {
    firstName: string
    lastName: string
    tradingName: string
    aboutMe?: string | null
    locationId: string | null
    localityId: string | null
    genderTypeId?: number | null
    phoneNumber: string
    insuranceExpiryDate?: Date | null
    knownCompetitors?: string
    wheelPositionId?: string | null
}

const buildPersonalDetailsFormValidationSchema = () => yup.object({
    firstName: yup.string().required("First name is required.").max(64, "The maximum length for first name is 64 characters."),
    lastName: yup.string().required("Last name is required.").max(64, "The maximum length for last name is 64 characters."),
    tradingName: yup.string().max(100, "The maximum length for company name is 100 characters.").required("Company name is required."),
    aboutMe: yup.string().max(1000, "Business summary must be 1000 characters or less.").nullable(),
    locationId: yup.string().required("Country is required."),
    localityId: yup.string().required("Locality is required."),
    genderTypeId: yup.number().nullable(),
    insuranceExpiryDate: yup.date()
        .min(tomorrowAtMidnight(), "Insurance expiry date must any day from tomorrow.")
        .nullable()
        .typeError("Insurance expiry date must be a valid date."),
    knownCompetitors: yup.string().max(500, "The maximum length for this field is 500 characters."),
    wheelPositionId: yup.string().nullable(),
    phoneNumber: yup.string().max(20, "The maximum length for phone number is 20 characters").required("Phone number is required.")
});

export const usePersonalDetailsForm = (
    initialValues?: TalentPersonalDetailsFormValues
) => useForm<TalentPersonalDetailsFormValues>({
    resolver: yupResolver(buildPersonalDetailsFormValidationSchema()),
    defaultValues: initialValues
});
