import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FieldError } from "react-hook-form";

import FormInputLabel from "./FormInputLabel";
import FormTextInput from "./FormTextInput";

export type FormDateInputProps = {
    id: string,
    label: string
    value: Date | null
    onChange: (value: Date | null) => void
    error?: FieldError
    minDate?: Date
    maxDate?: Date
    required?: boolean
    hideHelpText?: boolean
    helperText?: string
    tooltip?: string
}

const FormDateInput = ({
    id,
    label,
    value,
    onChange,
    error,
    minDate,
    maxDate,
    required,
    hideHelpText,
    helperText = "day/month/year",
    tooltip
}: FormDateInputProps) => {
    return (
        <div className="w-full">
            <FormInputLabel tooltipText={tooltip} htmlFor={id} required={required}>{label}</FormInputLabel>
            <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={value}
                onOpen={() => {
                    if (!value && minDate) {
                        onChange(minDate);
                    }
                }}
                onChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                renderInput={params => (
                    <div className="flex" ref={params.ref}>
                        <FormTextInput
                            id={id}
                            className="w-full"
                            ref={params.inputRef}
                            error={error}
                            helpText={hideHelpText ? undefined : helperText}
                            {...params.inputProps}
                        />
                        <span className="mt-[28px]">
                            {params.InputProps?.endAdornment}
                        </span>
                    </div>
                )}
            />
        </div>
    );
};

export default FormDateInput;