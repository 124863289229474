import { formatLocationDateTimeToLocaleString } from "../utils/dateFormatters";

type DateDisplayLocaleProps = {
    date: Date | string;
    timezone?: string;
    showTimezone?: boolean;
    label?: string;
    className?: string;
};

const DateDisplayLocale = ({ 
    date, 
    timezone, 
    showTimezone = true, 
    label = "Event Local Time", 
    className = "" 
}: DateDisplayLocaleProps) => {
    const { datePart, timezonePart } = formatLocationDateTimeToLocaleString(date, timezone);
    
    return (
        <div className={`flex flex-wrap items-baseline gap-1 ${className}`}>
            <span className="font-semibold">{label}:</span>
            <span>{datePart}</span>
            {showTimezone && (
                <span className="flex flex-wrap items-baseline gap-1">
                    <span>{timezonePart}</span>
                </span>
            )}
        </div>
    );
};

export default DateDisplayLocale;