import { useGigs } from "../api/gigs";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import Card, { CardDataFieldLabel } from "../components/Card";
import LinkButton from "../components/LinkButton";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import UserAvatar from "../components/UserAvatar";
import { useAppPaths } from "../Routes";
import { gigStatuses } from "../models/app/gig";
import { useIsLargeScreen } from "../hooks/screenSizes";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import AuditDataList from "../components/AuditData/AuditDataList";
import ConfirmGigButton from "../components/ConfirmGigButton";
import { Chip } from "@mui/material";
import { formatLocationDateToString, formatLocationTimeToString } from "../utils/dateFormatters";
import { Tooltip } from "../components/Tooltip";
import DateDisplayLocale from "../components/DateDisplayLocale";

const GigsOngoingClientPage = () => {
    const appPaths = useAppPaths();
    const { gigs, isLoading } = useGigs({
        gigStatusIds: [gigStatuses.hired, gigStatuses.pending],
        initialOrderBy: "PostedDate",
    });
    const isLargeScreen = useIsLargeScreen();

    const { gigTerminologyPlural, talentTerminology } = useOrganizationContext();

    if (isLoading || !gigs) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Booked {gigTerminologyPlural}</Typography>
            {gigs.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {gigs.map(gig => (
                        <div key={gig.id} className="md:flex md:items-stretch break-words">
                            <Card className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                                <div className="space-y-2 md:mr-8">
                                    <Typography variant="title-large" component="h3" className="max-w-md">{gig.title}</Typography>
                                    <Typography variant="title-small" component="h3">{gig.skills[0].name}</Typography>
                                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 pt-2 justify-items-center md:justify-items-start">
                                        <CardDataFieldLabel iconStart iconSize={32} iconName="Calendar" textClass="font-normal">{formatLocationDateToString(gig.startDate, gig.ianaId)}</CardDataFieldLabel>
                                        <CardDataFieldLabel iconStart iconSize={32} iconName="Clock" textClass="font-normal">
                                            <div className="flex items-center">
                                                {formatLocationTimeToString(gig.startDate, gig.ianaId)}
                                                <Tooltip className="ml-2" tooltipText="" dateDisplay={<DateDisplayLocale date={gig.startDate} />}></Tooltip>
                                            </div>
                                        </CardDataFieldLabel>
                                    </div>
                                    <AuditDataList id={gig.id} createdBy={gig.createdBy} lastUpdatedBy={gig.lastUpdatedBy} />
                                </div>
                                <div className="flex flex-col md:flex-row md:space-x-2 md:ml-auto items-center ">
                                    {gig.gigStatusId === gigStatuses.pending ? (
                                        <Chip label={`Awaiting ${talentTerminology} confirmation`} color="warning" variant="filled" size="small" className="mt-4 md:mt-0"/>
                                    ) : (
                                        <>
                                            <LinkButton variant="tertiary" to={appPaths.gigs.edit(gig.id)}>Edit</LinkButton>
                                            <LinkButton to={appPaths.gigs.details(gig.id)} variant="tertiary">View details</LinkButton>
                                            <ConfirmGigButton gigId={gig.id} />
                                        </>                                        
                                    )}
                                    
                                </div>
                            </Card>
                            {gig.acceptedTalentId && gig.acceptedTalentName && (
                                <Card className="shrink-0 md:w-[230px] flex items-center justify-center rounded-t-none md:rounded-t-lg pt-2 md:pt-6">
                                    <Link to={appPaths.talents.findTalentDetails(gig.acceptedTalentId)} target="_blank"><UserAvatar name={gig.acceptedTalentName} userImageUrl={gig.acceptedTalentProfileImageThumbnailUrl} /></Link>
                                </Card>
                            )}
                        </div>
                    ))}
                </div>
            )
            }
        </PageContent>
    );
};

export default GigsOngoingClientPage;