import clsx from "clsx";
import { CaretLeft } from "phosphor-react";
import Typography from "../Typography";

export type ListHeaderProps = {
    name: string
    variant?: "dark" | "light",
    className?: string
    handleBackClick?: () => void
    profileImage?: () => JSX.Element
}
const ListHeader = ({
    name,
    variant,
    className,
    handleBackClick,
    profileImage
}: ListHeaderProps) => {
    return (
        <div className={clsx(
            "min-h-[6rem] bg-white flex justify-start items-center py-6 px-6 gap-3 w-full",
            variant === "dark" && "bg-secondary",
            className
        )}>
            {profileImage && profileImage()}
            {handleBackClick && <CaretLeft size={32} onClick={handleBackClick} className="cursor-pointer" />}
            <Typography className={clsx(
                "text-secondary font-bold overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[32rem]",
                variant === "dark" && "!text-white"
            )} component="span" variant="title-medium">
                {name}
            </Typography>
            <div />
        </div>
    );
};

export default ListHeader;