import React, { useEffect, useState } from "react";
import { useAppPaths } from "../../Routes";
import { useNavigate } from "react-router-dom";
import { successToast, errorToast } from "../../toast";
import srcConfig from "../../configuration";
import { useExchangeCodeForGrant } from "../../api/nylas";
import useAccessToken from "../../auth/useAccessToken";

const NylasCallback: React.FC = () => {    
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const [exchangeCodeForGrant, isFetching] = useExchangeCodeForGrant();
    const accessToken = useAccessToken();
    const [hasAttemptedExchange, setHasAttemptedExchange] = useState(false);

    useEffect(() => {
        const fetchGrantExchange = async () => {
            if (hasAttemptedExchange) return;

            const code = new URLSearchParams(window.location.search).get("code");
            if (!code) {
                console.warn("Nylas callback: No code found in URL parameters.");
                errorToast("Authentication failed. Please try again.");
                navigate(appPaths.dashboard);
                return;
            }

            if (!accessToken) return;

            setHasAttemptedExchange(true);

            const nylasRedirectUri = window.location.origin + srcConfig.nylas.nylasAuthCallbackUri;

            try {
                const response = await exchangeCodeForGrant({ redirectUri: nylasRedirectUri, code });
                if (response.success) {
                    console.log("Nylas authentication successful.");
                    successToast("Calendar connected successfully.");
                } else {
                    throw new Error(response.error?.message || "Failed to exchange code for grant");
                }
            } catch (error) {
                console.error("Nylas authentication error:", error);
                errorToast("Failed to connect calendar. Please try again.");
            } finally {
                navigate(appPaths.dashboard);
            }
        };

        fetchGrantExchange();
    }, [navigate, exchangeCodeForGrant, accessToken, hasAttemptedExchange]);

    if (!accessToken || isFetching) {
        return <div>Connecting your calendar...</div>;
    }

    return null;
};

export default NylasCallback;
