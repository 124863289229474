import { useState } from "react";
import { Controller } from "react-hook-form";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import useCurrentUser from "../../auth/useCurrentUser";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import FormDialogBox, { FormDialogBoxProps } from "../../components/FormDialogBox";
import FormDropdown, { FormDropdownOption } from "../../components/FormDropdown";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import { GigSummary } from "../../models/app/gig";
import { CreateDisputeFormValues, useCreateDisputeForm } from "./CreateDisputeFormValues";
import { hasClaim } from "../../auth/hasClaim";
import { UserRole } from "../../models/app/userMe";

export type CreateDisputeFormProps = Omit<FormDialogBoxProps, "title" | "children"> & {
    initialValues: CreateDisputeFormValues
    resetOnSubmit?: boolean
    isEdit?: boolean
    onSubmit: (values: CreateDisputeFormValues) => Promise<GigApiFetcherResponse<unknown>>
    disputableGigs: GigSummary[]
}

export const CreateDisputeFormDialog = ({
    initialValues,
    resetOnSubmit,
    isEdit,
    onSubmit,
    disputableGigs,
    ...dialogProps
}: CreateDisputeFormProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { claims } = useCurrentUser();
    const { gigTerminologyPlural, gigTerminology, giggedClientTerminology, talentTerminology } = useOrganizationContext();
    const methods = useCreateDisputeForm(gigTerminology, initialValues);
    const { isDirty } = methods.formState;
    const isTalent = hasClaim(UserRole.AC, claims);

    const confirmCancelDialogState = useDialogBoxState();
    const { onClose } = dialogProps;

    const handleSubmit = async (values: CreateDisputeFormValues) => {
        setIsSubmitting(true);

        try {
            const result = await onSubmit(values);

            if (result.success) {
                methods.reset(resetOnSubmit ? initialValues : values);
                methods.clearErrors();
                onClose();
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const handleCancelButton = () => {
        if (isDirty) {
            confirmCancelDialogState.open();
            return;
        }
        methods.clearErrors();
        onClose();
    };

    const handleDiscardChanges = () => {
        confirmCancelDialogState.close();
        methods.reset(initialValues);
        methods.clearErrors();
        onClose();
    };
    
    const gigOptions: FormDropdownOption<string>[] = disputableGigs
        .map(gig => ({
            value: gig.id,
            label: gig.title,
        }));

    const reasonOptions = isTalent ? [
        { label: `${giggedClientTerminology} is not releasing the payments`, value: "Client is not releasing the payments" },
        { label: `${giggedClientTerminology} has cancelled the gig`, value: "Client has cancelled the gig" },
    ] : [
        { label: `${talentTerminology} did not respond`, value: "Talent did not respond" },
        { label: `${talentTerminology} did not work as I was expecting`, value: "Talent did not work as I was expecting" },
    ];

    return (
        <>
            <FormDialogBox
                {...dialogProps}
                isOpen={dialogProps.isOpen && !confirmCancelDialogState.isOpen}
                onClose={handleCancelButton}
                title="Dispute"
            >
                <p>Disputes can only be made for cancelled or ongoing {gigTerminologyPlural.toLowerCase()}.</p>
                <form onSubmit={methods.handleSubmit(handleSubmit)} className="space-y-6">
                    <div className="space-y-4">
                        <div className="w-full">
                            <Controller
                                name="gigId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        required
                                        label={`Select ${gigTerminology.toLowerCase()}`}
                                        options={gigOptions}
                                        error={methods.formState.errors.gigId}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isSubmitting}
                                    />
                                )}
                            />
                        </div>
                        <div className="w-full">
                            <Controller
                                name="reason"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        required
                                        label="Select reason"
                                        options={reasonOptions}
                                        error={methods.formState.errors.reason}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isSubmitting}
                                    />
                                )}
                            />
                        </div>
                        <div className="w-full">
                            <FormTextAreaInput
                                required
                                id="create-dispute-form-description"
                                label="Description"
                                placeholder="Add description"
                                error={methods.formState.errors.description}
                                register={methods.register("description")}
                            />
                        </div>
                    </div>
                    <div className="flex items-center sm:justify-end sm:space-x-4 flex-col-reverse sm:flex-row">
                        <Button type="button" className="w-full sm:w-fit mt-4 sm:mt-0" disabled={isSubmitting} variant="tertiary" onClick={handleCancelButton}>Cancel</Button>
                        <Button type="submit" className="w-full sm:w-fit" disabled={isEdit && !isDirty} loading={isSubmitting} variant="primary">Create dispute</Button>
                    </div>
                </form>
            </FormDialogBox>
            <DialogBox {...confirmCancelDialogState} title="Discard changes?">
                <div className="flex justify-between">
                    <Button type="button" variant="secondary" onClick={confirmCancelDialogState.close}>Back</Button>
                    <Button type="button" onClick={handleDiscardChanges}>Discard</Button>
                </div>
            </DialogBox>
        </>
    );
};