import React, { useEffect, useState } from "react";
import { ApiTalentPersonalInformationDto, ApiUpdateTalentPersonalInformationDto } from "../models/api/talentPersonalInformation";
import contextPlaceholderFunc from "../utils/contextPlaceholderFun";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { mapFromApiTalentPersonalInformation, mapToApiUpdateTalentPersonalInformation } from "../models/mappers/talentPersonalInformation";
import { TalentPersonalInformationDto, UpdateTalentPersonalInformationDto } from "../models/app/talentPersonalInformation";

// NOTE: This file exports a single context (rather than a hook that can be used in multiple places) so that when
// a user updates their profile photo it is immediately reflected in the side bar.

const useTalentUpdatePersonalInformation = () => useAuthenticatedGigApiFetcher<ApiUpdateTalentPersonalInformationDto, void, UpdateTalentPersonalInformationDto>("PUT",
    (dto) => ({
        url: "api/me/talent-personal-information",
        body: mapToApiUpdateTalentPersonalInformation(dto)
    }));

export type UseTalentPersonalInformationReturn = {
    talentPersonalInformation?: TalentPersonalInformationDto
    updatePersonalInformation: (dto: UpdateTalentPersonalInformationDto) => Promise<GigApiFetcherResponse<void>>
    isUpdatingPersonalInformation: boolean
    isLoading: boolean
    isValidating: boolean
    reload: () => void
}

const useTalentPersonalInformation = (): UseTalentPersonalInformationReturn => {
    const [talentPersonalInformation, setTalentPersonalInformation] = useState<TalentPersonalInformationDto | undefined>();
    const apiCall = useGiggedApiSWR<ApiTalentPersonalInformationDto>("api/me/talent-personal-information");

    const [updateTalentPersonalInformation, isUpdatingPersonalInformation] = useTalentUpdatePersonalInformation();

    useEffect(() => {
        if (apiCall.data === undefined){
            setTalentPersonalInformation(undefined);
            return;
        }

        setTalentPersonalInformation(mapFromApiTalentPersonalInformation(apiCall.data));
    }, [apiCall.data]);

    return {
        talentPersonalInformation,
        updatePersonalInformation: async (dto: UpdateTalentPersonalInformationDto) => {
            const response = await updateTalentPersonalInformation(dto);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isUpdatingPersonalInformation,
        isLoading: apiCall.isLoading,
        isValidating: apiCall.isLoading,
        reload: apiCall.refetch,
    };
};

const TalentPersonalInformationContext = React.createContext<UseTalentPersonalInformationReturn>({
    talentPersonalInformation: undefined,
    updatePersonalInformation: contextPlaceholderFunc,
    isUpdatingPersonalInformation: false,
    isLoading: false,
    isValidating: false,
    reload: contextPlaceholderFunc,
});

export const useTalentPersonalInformationContext = () => React.useContext(TalentPersonalInformationContext);

export const TalentPersonalInformationContextProvider = ({
    children,
}: { children: React.ReactNode }) => {
    const talentPersonalInformation = useTalentPersonalInformation();

    return (
        <TalentPersonalInformationContext.Provider value={talentPersonalInformation}>
            {children}
        </TalentPersonalInformationContext.Provider>
    );
};
