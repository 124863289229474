import { LanguageDto } from "../models/api/language";
import gigApiFetcher from "./common/fetching";

export const fetchLanguages = async (query = "", accessToken: string | null): Promise<LanguageDto[]> => {
    const queryString = query ? `?searchTerm=${query}` : "";

    const response = await gigApiFetcher<never, LanguageDto[]>({
        url: `api/language${queryString}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchLanguageOptions = async (query: string, accessToken: string | null) => {
    const languagesResponse = await fetchLanguages(query, accessToken);

    return languagesResponse
        .map(language => ({
            value: language.id,
            label: language.name,
        }));
};