import clsx from "clsx";
import Tooltip from "./Tooltip";

export type WorksomeIconProps = {
    className: string
    tooltipText: string
}

const WorksomeIcon = ({
    className,
    tooltipText
}: WorksomeIconProps) => {
    return (
        <div className={clsx(className)}>
            <Tooltip tooltipText={tooltipText}>
                <img 
                    className="block max-h-[40px]"
                    src="/worksome-icon-40x40.svg" 
                    alt="Worksome logo" 
                />
            </Tooltip>
        </div>
    );
};

export default WorksomeIcon;