import React, { useEffect, useState } from "react";
import Button from "../Button";
import { errorToast } from "../../toast";
import srcConfig from "../../configuration";
import DialogBox, { useDialogBoxState } from "../DialogBox";
import Typography from "../Typography";
import useCurrentUser from "../../auth/useCurrentUser";
import { SignOutButton } from "../PageLayout/SignOutButton";
import { dialogWidths } from "../DialogBox/DialogBox";
import { useFetchNylasAuthUrl } from "../../api/nylas";

type NylasAuthProps = {
    isCalendarConnected: boolean
};

const NylasAuth: React.FC<NylasAuthProps> = ({ isCalendarConnected }) => {
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const dialogState = useDialogBoxState();
    const { idpUserId: userId } = useCurrentUser();
    const nylasRedirectUri = encodeURIComponent(window.location.origin + srcConfig.nylas.nylasAuthCallbackUri || "");
    const [shouldFetchAuthUrl, setShouldFetchAuthUrl] = useState(false);
    const { authUrl, error } = useFetchNylasAuthUrl(nylasRedirectUri, shouldFetchAuthUrl);

    useEffect(() => {
        if (!isCalendarConnected) {
            dialogState.open();
        } else {
            dialogState.close();
        }
    }, [isCalendarConnected]);

    const initiateNylasAuth = () => {
        if (!userId) {
            errorToast("User ID not found.");
            setErrorMessage("An error has occurred. Please retry.");
            return;
        }

        setErrorMessage(null);
        setIsAuthenticating(true);
        setShouldFetchAuthUrl(true);
    };

    useEffect(() => {
        if (shouldFetchAuthUrl) {
            if (authUrl) {
                setIsAuthenticating(false);
                setShouldFetchAuthUrl(false);
                window.location.href = authUrl;
            } else if (error) {
                console.error("Error fetching Nylas auth URL:", error);
                setErrorMessage("An error has occurred. Please retry.");
                errorToast("Failed to fetch authentication URL. Please check the console for more details.");
                setIsAuthenticating(false);
                setShouldFetchAuthUrl(false);
            }
        }
    }, [authUrl, error, shouldFetchAuthUrl]);

    return (
        <>
            <DialogBox
                {...dialogState}
                title={"Connect your calendar to continue"}
                showCloseButton={false}
                maxWidth={dialogWidths.small}
            >
                <Typography variant="body" component="p">
                    Please connect your calendar to begin using the platform by clicking
                    the button below.
                </Typography>
                {errorMessage && (
                    <Typography variant="body" component="p" className="text-error mt-4">
                        {errorMessage}
                    </Typography>
                )}
                <div className="flex flex-col w-full items-center justify-between gap-4">
                    <Button
                        variant="primary"
                        className="rounded-full w-full"
                        onClick={initiateNylasAuth}
                        loading={isAuthenticating}
                        disabled={isAuthenticating}
                    >
                        {isAuthenticating
                            ? "Connecting..."
                            : errorMessage
                                ? "Retry Connection"
                                : "Connect Calendar"}
                    </Button>
                    <SignOutButton />
                </div>
            </DialogBox>
        </>
    );
};

export default NylasAuth;
