import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useTalentOngoingGigs } from "../api/talentMyOngoingGigs";
import Card from "../components/Card";
import LinkButton from "../components/LinkButton";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import UserAvatar from "../components/UserAvatar";
import { useAppPaths } from "../Routes";
import { gigStatuses } from "../models/app/gig";
import { useIsLargeScreen } from "../hooks/screenSizes";
import Loader from "../components/Loader";
import WorksomeIcon from "../components/WorksomeIcon";

const GigsCancelledTalentPage = () => {
    const appPaths = useAppPaths();
    const { gigs, isLoading } = useTalentOngoingGigs([gigStatuses.cancelled], "CancelledDate");
    const { gigTerminologyPlural, gigTerminology } = useOrganizationContext();
    const isLargeScreen = useIsLargeScreen();

    if (isLoading) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Cancelled {gigTerminologyPlural}</Typography>
            {gigs.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {gigs.map(gig => (
                        <div key={gig.id} className="md:flex md:items-stretch break-words">
                            <Card className="space-y-6 md:flex md:space-y-0 items-center grow md:mr-4 rounded-b-none md:rounded-b-lg relative">
                                {gig.isWorksome && (
                                    <WorksomeIcon className="absolute right-2 top-2 transform translate-x-0 translate-y-0" tooltipText={`Payments and contractual details for this ${gigTerminology.toLowerCase()} will be handled via Worksome`}/>
                                )}
                                <div className="space-y-4 mr-auto">
                                    <Typography variant="title-large" component="p" className="max-w-md">{gig.title}</Typography>
                                    <p className="italic line-clamp-3 mr-8">{gig.description}</p>
                                </div>
                                <LinkButton variant="tertiary" to={appPaths.gigs.details(gig.id)}>View details</LinkButton>
                            </Card>
                            <Card className="shrink-0 md:w-[230px] flex items-center justify-center rounded-t-none md:rounded-t-lg pt-0 md:pt-6">
                                <UserAvatar name={gig.giggedClientName || gig.giggedClientCompanyName} />
                            </Card>
                        </div>
                    ))}
                </div>
            )}
        </PageContent>
    );
};

export default GigsCancelledTalentPage;