import Typography from "./Typography";
import LinkButton from "./LinkButton";
import Card, { CardDataField, CardDataFieldLabel } from "./Card";
import TitleUnderline from "./TitleUnderline";
import { GigSummary, deliveryTypeOptions } from "../models/app/gig";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useEffect, useRef, useState } from "react";
import AuditDataList from "./AuditData/AuditDataList";
import { formatLocationDateToString, formatLocationTimeToString } from "../utils/dateFormatters";
import Tooltip from "./Tooltip";
import DateDisplayLocale from "./DateDisplayLocale";

export type GigSummaryCardProps = {
    gig: GigSummary
    viewGigLink: string
}

const GigSummaryCard = ({
    gig,
    viewGigLink,
}: GigSummaryCardProps) => {
    const { gigTerminology } = useOrganizationContext();
    const [lineClamp, setLineClamp] = useState<boolean>(true);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        const checkOverflow = () => {
            const element = textRef.current;
            if (element) {
                setIsOverflowing(element.scrollHeight > element.clientHeight);
            }
        };

        checkOverflow();
        window.addEventListener("resize", checkOverflow);

        return () => {
            window.removeEventListener("resize", checkOverflow);
        };
    }, [gig]);

    const handleReadMore = () => setLineClamp(lineClamp => !lineClamp);

    return (
        <Card key={gig.id}>
            <div className="w-full break-words relative space-y-6">
                <div className="flex flex-col">
                    <div className="flex sm:flex-row flex-col-reverse justify-between">
                        <div>
                            <div className="space-y-4 pr-2 mb-2">
                                <Typography variant="title-large" component="h2" className="max-w-2xl">{gig?.title}</Typography>
                                <Typography variant="title-small" component="h3">{`${gig.location?.locality.locality}, ${gig.location?.country}`}</Typography>
                            </div>
                            {isOverflowing && <div onClick={handleReadMore} className="cursor-pointer font-bold hover:underline w-fit text-[0.95rem] mb-2">{lineClamp ? "Read more" : "Read less"}</div>}
                        </div>
                    </div>
                    <TitleUnderline fullWidth />
                    <div className="flex sm:space-x-8 flex-col sm:flex-row gap-2 pt-2">
                        <CardDataField label="Insights workshop type">{gig?.skills[0]?.name}</CardDataField>
                        <CardDataField label="Delivery format">{deliveryTypeOptions[gig?.deliveryTypeId].label}</CardDataField>
                    </div>
                    <div className="flex sm:space-x-8 flex-col sm:flex-row gap-2 pt-2">
                        <CardDataFieldLabel iconStart iconSize={32} iconName="Calendar" textClass="font-normal">{formatLocationDateToString(gig.startDate, gig.ianaId)}</CardDataFieldLabel>
                        <CardDataFieldLabel iconStart iconSize={32} iconName="Clock" textClass="font-normal">
                            <div className="flex items-center">
                                {formatLocationTimeToString(gig.startDate, gig.ianaId)}
                                <Tooltip className="ml-2" tooltipText="" dateDisplay={<DateDisplayLocale date={gig.startDate} />}></Tooltip>
                            </div>
                        </CardDataFieldLabel>
                    </div>
                </div>
                <div className="flex flex-wrap space-y-4">
                    <div className="block sm:self-end sm:mt-0">
                        <LinkButton
                            to={viewGigLink}
                            variant="secondary"
                            scrollToTop
                        >
                            View {gigTerminology.toLowerCase()}
                        </LinkButton>
                    </div>
                </div>
                <AuditDataList id={gig.id} createdBy={gig.createdBy} lastUpdatedBy={gig.lastUpdatedBy} />
            </div>
        </Card>
    );
};

export default GigSummaryCard;
