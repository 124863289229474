export type ProgressIndicatorProps = {
    value: number
}

const ProgressIndicator = ({
    value
}: ProgressIndicatorProps) => {
    return (
        <div className="flex flex-row items-center space-x-4">
            <div className="w-full bg-surface h-4 rounded-full">
                <div
                    className="bg-primary h-4 rounded-full"
                    style={{ width: `${value}%` }}
                    role="progressbar"
                    aria-valuenow={value}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>
            <span className="font-bold">{value}%</span>
        </div>
    );
};

export default ProgressIndicator;