import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";


export type TalentDeliveryTypeFormValues = {
    deliveryTypeId: number;
};

export const validationSchemaFields = {
    deliveryTypeId: yup.number().required("Please select an option."),
};

const schema = yup.object(validationSchemaFields);

export const useTalentDeliveryTypeForm = () => {
    return useForm<TalentDeliveryTypeFormValues>({
        resolver: yupResolver(schema)
    });
};