import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { List } from "phosphor-react";
import { Link, useNavigate } from "react-router-dom";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import useCurrentUser from "../../auth/useCurrentUser";
import { useAppPaths } from "../../Routes";
import Button from "../Button";
import MobileMenuDropdown from "./MobileMenuDropdown";
import OrganizationLogo from "../OrganizationLogo";
import NotificationEnvelope from "./NotificationBell";
import UserMenu from "./UserMenu";
import { hasClaim } from "../../auth/hasClaim";
import { UserRole } from "../../models/app/userMe";

export type TopNavMenuProps = {
    className?: string
}

const TopNavMenu = ({
    className
}: TopNavMenuProps) => {
    const appPaths = useAppPaths();
    const { organizationConfig, talentTerminologyPlural } = useOrganizationContext();
    const user = useCurrentUser();
    const { userRole } = user;
    const navigate = useNavigate();
    const isAdmin = hasClaim(UserRole.Admin, user.claims);
    const isResources = hasClaim(UserRole.Resources, user.claims);

    const navBarItems = [];

    if (hasClaim(UserRole.Resources, user.claims)) {
        navBarItems.push({ name: `Find ${talentTerminologyPlural}`, href: appPaths.talents.findTalent });
    }

    const handleSignInClicked = () => {
        navigate(appPaths.login);
    };

    return (
        <nav className={clsx(className, "px-4 sm:px-6 lg:px-8")}>
            <div className="relative flex items-center h-16">
                {userRole !== undefined && (
                    <Disclosure.Button className={clsx(
                        "focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black mr-4",
                        "xl:hidden"
                    )}>
                        <span className="sr-only">Open main menu</span>
                        <List className="block h-6 w-6" aria-hidden="true" />
                    </Disclosure.Button>
                )}
                {userRole === undefined && (
                    <div className="mr-4 sm:hidden">
                        <MobileMenuDropdown />
                    </div>
                )}
                <Link
                    to={"/"}
                    className="mr-auto"
                >
                    <OrganizationLogo />
                </Link>
                <div className="hidden sm:flex sm:ml-6 mr-6">
                    {navBarItems.map((navItem) => (
                        <Link
                            key={navItem.name}
                            to={navItem.href}
                            className="px-4 py-2 rounded-md font-bold text-primary-text"
                        >
                            {navItem.name}
                        </Link>
                    ))}

                    {organizationConfig.name === "Gigged.AI" && (
                        <a
                            href="https://gigged.ai"
                            target="_blank"
                            className="px-4 py-2 rounded-md font-bold text-primary-text"
                        >
                            Back to Home Page
                        </a>
                    )}
                </div>
                <div className="flex flex-row items-center space-x-4 cursor-pointer">
                    {!isAdmin && userRole === undefined ? (
                        <Button onClick={handleSignInClicked}>Sign in</Button>
                    ) : (
                        <>
                            {isResources && <NotificationEnvelope />}
                            <UserMenu />
                        </>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default TopNavMenu;