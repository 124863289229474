import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useOrganizationContext } from "./api/current-organization/organizationContext";
import RequireAuthenticated from "./auth/RequireAuthenticated";
import useCurrentUser from "./auth/useCurrentUser";
import WithTitle from "./components/PageLayout/WithTitle";
import { FourOFourPage } from "./pages/404";
import { FourOThreePage } from "./pages/403";
import ClientDashboardPage from "./pages/ClientDashboard/ClientDashboardPage";
import ClientDetails from "./pages/ClientDetails";
import ClientProfileEditPage from "./pages/ClientProfileEdit/ClientProfileEditPage";
import DisputesPage from "./pages/Disputes";
import FindAGigDetailsPage from "./pages/FindAGigDetails";
import FindTalentPage from "./pages/FindTalent";
import FindTalentDetails from "./pages/FindTalentDetails";
import GigCreatePage from "./pages/GigCreate";
import GigCreateIntroPage from "./pages/GigCreateIntro";
import GigDetailsPage from "./pages/GigDetails";
import GigEditPage from "./pages/GigEdit";
import GigInvitesPage from "./pages/GigInvites";
import GigsMatchesListPage from "./pages/GigMatchesList";
import GigsCancelledClientPage from "./pages/GigsCancelledClient";
import GigsCancelledTalentPage from "./pages/GigsCancelledTalent";
import GigsCompletedClientPage from "./pages/GigsCompletedClient";
import GigsCompletedTalentPage from "./pages/GigsCompletedTalent";
import GigsListDraftPage from "./pages/GigsListDraft";
import GigsListPostedPage from "./pages/GigsListPosted";
import GigsOngoingClientPage from "./pages/GigsOngoingClient";
import GigsOngoingTalentPage from "./pages/GigsOngoingTalent";
import { InboxPage, InboxArchivePage } from "./pages/Inbox";
import ManageUsersPage from "./pages/ManageUsers/ManageUsersPage";
import SignUpPage from "./pages/SignUp";
import TalentDashboardPage from "./pages/TalentDashboard";
import TalentProfileEditPage from "./pages/TalentProfileEdit";
import LoginPage from "./components/Login";
import { useUserGetMe } from "./api/userGetMe";
import NylasCallback from "./components/Nylas/NylasCallback";
import GigInvitesList from "./pages/GigInvitesList";
import { hasClaim } from "./auth/hasClaim";
import { UserRole } from "./models/app/userMe";

export const fourOFourPath = "/404";
export const fourOThreePath = "/403";
export const signUpPath = "/sign-up";
export const nylasCallbackPath = "/oauth/nylas/callback";

const appPaths = (gigTerminology: string, giggedTerminologyPlural: string, giggedClientTerminologyPlural: string, talentTerminology: string, talentTerminologyPlural: string) => {
    const gig = gigTerminology.toLowerCase().replace(" ", "-");
    const gigPlural = giggedTerminologyPlural.toLowerCase().replace(" ", "-");
    const clientPlural = giggedClientTerminologyPlural.toLowerCase().replace(" ", "-");
    const talentPlural = talentTerminologyPlural.toLowerCase().replace(" ", "-");

    return ({
        root: "/",
        login: "/login",
        gigs: {
            indexDraft: `/draft-${gigPlural}`,
            indexPosted: `/posted-${gigPlural}`,
            invites: "/invites",
            ongoing: `/ongoing-${gigPlural}`,
            completed: `/completed-${gigPlural}`,
            cancelled: `/cancelled-${gigPlural}`,
            details: (gigId: string) => `/${gigPlural}/${gigId}`,
            createIntro: `/${gigPlural}/create-intro`,
            create: `/${gigPlural}/create`,
            gigInvites: (gigId: string) => `/${gigPlural}/${gigId}/invites`,
            matches: (gigId: string) => `/${gigPlural}/${gigId}/matches`,
            findAGig: `/find-a-${gig}`,
            findAGigDetails: (gigId: string) => `/find-a-${gig}/${gigId}`,
            edit: (gigId: string) => `/${gig}/${gigId}/edit`,
        },
        clients: {
            details: (clientId: string) => `/${clientPlural}/${clientId}`
        },
        talents: {
            findTalent: `/find-${talentPlural}`,
            findTalentDetails: (talentId: string) => `/find-${talentPlural}/${talentId}`
        },
        dashboard: "/dashboard",
        proposals: {
            index: "/proposals",
        },
        profile: {
            edit: "/edit-profile"
        },
        disputes: {
            index: "/disputes",
        },
        fourOFour: fourOFourPath,
        fourOThree: fourOThreePath,
        nylasCallback: nylasCallbackPath,
        inbox: {
            index: "/inbox",
            archive: "/inbox/archive",
            conversation: (conversationId: string) => `/inbox/${conversationId}`
        },
        account: {
            manageUsers: "/manage-users"
        }
    });
};

export const useAppPaths = () => {
    const {
        gigTerminology,
        gigTerminologyPlural,
        giggedClientTerminologyPlural,
        talentTerminology,
        talentTerminologyPlural
    } = useOrganizationContext();

    return appPaths(
        gigTerminology,
        gigTerminologyPlural,
        giggedClientTerminologyPlural,
        talentTerminology,
        talentTerminologyPlural
    );
};

const AppRoutes: React.FC = () => {
    const { claims } = useCurrentUser();
    const routeAppPaths = useAppPaths();
    const { organizationConfig, gigTerminology, gigTerminologyPlural, talentTerminology, talentTerminologyPlural } = useOrganizationContext();
    const { giggedClientIsVerified } = useUserGetMe();

    const isOtm = organizationConfig.name === "Gigged.AI";
    const isDefaultClient = hasClaim(UserRole.Default, claims);
    const isTalent = hasClaim(UserRole.AC, claims);
    const isResources = hasClaim(UserRole.Resources, claims);

    const disableFindTalentRoute =
        organizationConfig?.isTalentProfilesPrivate &&
        isTalent;

    const currentPath = window.location.pathname;
    const talentTerminologyRedirect = currentPath.replace(/\/find-talent/i, `/find-${talentTerminologyPlural.toLowerCase()}`);

    return (
        <Routes>
            <Route element={<RequireAuthenticated />}>
                <Route path={routeAppPaths.dashboard} element={isDefaultClient ? (
                    <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={ClientDashboardPage} />
                ) : isTalent ? (
                    <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={TalentDashboardPage} />
                ) : <Navigate to={routeAppPaths.fourOThree} replace />} />

                <Route path={routeAppPaths.gigs.indexDraft} element={<WithTitle pageTitle={`Draft ${gigTerminologyPlural} - Gigged.AI`} C={GigsListDraftPage} />} />
                <Route path={routeAppPaths.gigs.invites} element={<WithTitle pageTitle={`${gigTerminology} Invites - Gigged.AI`} C={GigInvitesPage} />} />
                <Route path={routeAppPaths.gigs.indexPosted} element={<WithTitle pageTitle={`Posted ${gigTerminologyPlural} - Gigged.AI`} C={GigsListPostedPage} />} />
                {isDefaultClient ? (
                    <Route path={routeAppPaths.gigs.ongoing} element={<WithTitle pageTitle={`Ongoing ${gigTerminologyPlural} - Gigged.AI`} C={GigsOngoingClientPage} />} />
                ) : (
                    <Route path={routeAppPaths.gigs.ongoing} element={<WithTitle pageTitle={`Ongoing ${gigTerminologyPlural} - Gigged.AI`} C={GigsOngoingTalentPage} />} />
                )}
                {isDefaultClient ? (
                    <Route path={routeAppPaths.gigs.completed} element={<WithTitle pageTitle={`Completed ${gigTerminologyPlural} - Gigged.AI`} C={GigsCompletedClientPage} />} />
                ) : (
                    <Route path={routeAppPaths.gigs.completed} element={<WithTitle pageTitle={`Completed ${gigTerminologyPlural} - Gigged.AI`} C={GigsCompletedTalentPage} />} />
                )}
                {isDefaultClient ? (
                    <Route path={routeAppPaths.gigs.cancelled} element={<WithTitle pageTitle={`Cancelled ${gigTerminologyPlural} - Gigged.AI`} C={GigsCancelledClientPage} />} />
                ) : (
                    <Route path={routeAppPaths.gigs.cancelled} element={<WithTitle pageTitle={`Cancelled ${gigTerminologyPlural} - Gigged.AI`} C={GigsCancelledTalentPage} />} />
                )}
                <Route path={routeAppPaths.gigs.details(":gigId")} element={<WithTitle pageTitle={`${gigTerminology} Details - Gigged.AI`} C={GigDetailsPage} />} />
                {(!isOtm || giggedClientIsVerified) && (
                    <>
                        <Route path={routeAppPaths.gigs.createIntro} element={<WithTitle pageTitle={`Post a ${gigTerminology} - Gigged.AI`} C={GigCreateIntroPage} />} />
                        <Route path={routeAppPaths.gigs.create} element={<WithTitle pageTitle={`Post a ${gigTerminology} - Gigged.AI`} C={GigCreatePage} />} />
                    </>
                )}
                <Route path={routeAppPaths.gigs.matches(":gigId")} element={<WithTitle pageTitle={"Matches - Gigged.AI"} C={GigsMatchesListPage} />} />
                <Route path={routeAppPaths.gigs.edit(":gigId")} element={<WithTitle pageTitle={`Edit ${gigTerminology} - Gigged.AI`} C={GigEditPage} />} />
                <Route path={routeAppPaths.gigs.gigInvites(":gigId")} element={<WithTitle pageTitle={"Invites - Gigged.AI"} C={GigInvitesList} />} />
                <Route path={routeAppPaths.talents.findTalentDetails(":talentId")} element={<WithTitle pageTitle={`${talentTerminology} Details - Gigged.AI`} C={FindTalentDetails} />} />
                {isDefaultClient ? (
                    <Route path={routeAppPaths.profile.edit} element={<WithTitle pageTitle={"Edit Profile - Gigged.AI"} C={ClientProfileEditPage} />} />
                ) : (
                    <Route path={routeAppPaths.profile.edit} element={<WithTitle pageTitle={"Edit Profile - Gigged.AI"} C={TalentProfileEditPage} />} />
                )}
                <Route path={routeAppPaths.fourOFour} element={<WithTitle pageTitle={"404 - Gigged.AI"} C={FourOFourPage} />} />
                <Route path={routeAppPaths.fourOThree} element={<WithTitle pageTitle={"403 - Gigged.AI"} C={FourOThreePage} />} />
                <Route path={routeAppPaths.clients.details(":clientId")} element={<WithTitle pageTitle={"Client Profile - Gigged.AI"} C={ClientDetails} />} />
            
                {(isTalent || isResources) && (
                    <>
                        <Route path={routeAppPaths.inbox.index} element={<WithTitle pageTitle="Inbox - Gigged.AI" C={InboxPage} />} />
                        <Route path={routeAppPaths.inbox.conversation(":conversationId")} element={<WithTitle pageTitle="Inbox - Gigged.AI" C={InboxPage} />} />
                        <Route path={routeAppPaths.inbox.archive} element={<WithTitle pageTitle={"Inbox Archive - Gigged.AI"} C={InboxArchivePage} />} />
                    </>
                )}
                {organizationConfig?.isDisputesEnabled && <Route path={routeAppPaths.disputes.index} element={<WithTitle pageTitle={"Disputes - Gigged.AI"} C={DisputesPage} />} />}
                {organizationConfig.isAllowGiggedClientUserToInviteEnabled && <Route path={routeAppPaths.account.manageUsers} element={<WithTitle pageTitle={"Manage Users - Gigged.AI"} C={ManageUsersPage} />} />}

                <Route path={routeAppPaths.nylasCallback} element={<NylasCallback />} />

                <Route path={routeAppPaths.root} element={isTalent ? (
                    <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={TalentDashboardPage} />
                ) : isDefaultClient ? <WithTitle pageTitle="Your Dashboard - Gigged.AI" C={ClientDashboardPage} />
                    : <Navigate to={routeAppPaths.fourOThree} replace />} />

                {/* <Route path={routeAppPaths.gigs.findAGig} element={<WithTitle pageTitle={`Find a ${gigTerminology} - Gigged.AI`} C={FindAGigPage} /> }/> */}

                <Route path={routeAppPaths.gigs.findAGigDetails(":gigId")} element={<WithTitle pageTitle={`Find a ${gigTerminology} - Details`} C={FindAGigDetailsPage} />} />

            </Route>
            {!disableFindTalentRoute && (<Route path={routeAppPaths.talents.findTalent} element={<WithTitle pageTitle={`Find ${talentTerminology} - Gigged.AI`} C={FindTalentPage} />} />)}

            <Route path={routeAppPaths.login} element={<LoginPage />} />

            <Route path={signUpPath} element={<WithTitle pageTitle={"Sign Up - Gigged.AI"} C={SignUpPage} />} />

            {currentPath.startsWith("/find-talent") && (
                <Route path="*" element={<Navigate to={talentTerminologyRedirect} replace />} />
            )}

            <Route path="*" element={<Navigate to={routeAppPaths.login} replace />} />

        </Routes>
    );
};

export const useGigId = (): string => {
    const { gigId } = useParams();

    if (!gigId) throw new Error("No gig id param available.");

    return gigId;
};

export const useTalentId = (): string => {
    const { talentId } = useParams();

    if (!talentId) throw new Error("No talent id param available.");

    return talentId;
};

export const useClientId = (): string => {
    const { clientId } = useParams();

    if (!clientId) throw new Error("No client id param available.");

    return clientId;
};

export const useInvoiceId = (): number => {
    const { invoiceId } = useParams();

    if (!invoiceId) throw new Error("No invoice id param available.");

    return parseInt(invoiceId);
};

export default AppRoutes;