import React, { useCallback, useState } from "react";
import useNylasCalendar from "./useNylasCalendar";
import { Calendar as CalendarComponent } from "../Calendar/Calendar";
import {
    EventInput,
    EventClickArg,
    EventSourceFuncArg
} from "@fullcalendar/core";
import { useDialogBoxState } from "../DialogBox";
import { EventImpl } from "@fullcalendar/core/internal";
import EventDetailsDialog from "./NylasEventDetailsDialog";

export type NylasCalendarProps = {
    talentId?: string;
    calendarContentHeight?: string | number;
};

const NylasCalendar: React.FC<NylasCalendarProps> = ({ talentId = undefined, calendarContentHeight }) => {
    const { events, error, fetchEvents } = useNylasCalendar(talentId);
    const [selectedEvent, setSelectedEvent] = useState<EventImpl | null>(null);    
    const dialogState = useDialogBoxState();

    const fetchCalendarEvents = useCallback(
        async (info: EventSourceFuncArg, successCallback: (events: EventInput[]) => void, failureCallback: (error: Error) => void) => {
            try {
                const startEpoch = Math.floor(new Date(info.startStr).getTime() / 1000);
                const endEpoch = Math.floor(new Date(info.endStr).getTime() / 1000);

                await fetchEvents(startEpoch, endEpoch);

                successCallback(events);
            } catch (error) {
                failureCallback(new Error("Error fetching events"));
            }
        },
        [events, fetchEvents]
    );

    const handleEventClick = useCallback((clickInfo: EventClickArg) => {
        setSelectedEvent(clickInfo.event);
        dialogState.open();
    }, [dialogState]);

    return (
        <>
            <CalendarComponent 
                events={fetchCalendarEvents}
                onEventClick={handleEventClick}
                contentHeight={calendarContentHeight}
            />
            {selectedEvent && (
                <EventDetailsDialog
                    event={selectedEvent}
                    isOpen={dialogState.isOpen}
                    onClose={() => dialogState.close()}
                />
            )}
        </>
    );
};

export default NylasCalendar;
