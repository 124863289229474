import { useClientGigInvites } from "../../api/clientGigInvites";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { useGig } from "../../api/gig";
import GigDetailsCard from "../../components/GigDetailsCard";
import Loader from "../../components/Loader";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { useGigId } from "../../Routes";
import GigInviteCard from "./GigInviteCard";

const GigsInvitesListPage = () => {
    const gigId = useGigId();
    const { gig } = useGig(gigId);
    const { gigInvites, isLoading: isInvitesLoading } = useClientGigInvites(gigId);
    const { gigTerminology } = useOrganizationContext();

    if (!gig) return <Loader />;

    return (
        <PageContent>
            <Typography variant="display-medium" component="h1" gutterBottom>{gigTerminology}</Typography>
            <div className="mb-8">
                <GigDetailsCard gig={gig} />
            </div>
            <Typography variant="display-small" component="h2" gutterBottom>Sent invites</Typography>
            <div className="space-y-8">
                {gigInvites.length === 0 && (
                    <div className="bg-surface p-8">
                        <Typography variant="title-large" component="p">None yet...</Typography>
                    </div>
                )}
                {isInvitesLoading ? <>Loading...</> : gigInvites.map(invite => (
                    <GigInviteCard key={invite.gigInviteId} invite={invite} />
                ))}
            </div>
        </PageContent>
    );
};

export default GigsInvitesListPage;