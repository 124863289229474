import { Plus } from "phosphor-react";
import { useDisputes } from "../../api/disputes";
import { useGigs } from "../../api/gigs";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import useCurrentUser from "../../auth/useCurrentUser";
import Button from "../../components/Button";
import Card from "../../components/Card";
import { useDialogBoxState } from "../../components/DialogBox";
import LinkButton from "../../components/LinkButton";
import PageContent from "../../components/PageContent";
import Typography from "../../components/Typography";
import { gigStatuses } from "../../models/app/gig";
import { useAppPaths } from "../../Routes";
import { successToast } from "../../toast";
import { CreateDisputeFormDialog } from "./CreateDisputeFormDialog";
import { CreateDisputeFormValues } from "./CreateDisputeFormValues";
import { useIsLargeScreen } from "../../hooks/screenSizes";
import Loader from "../../components/Loader";
import { hasClaim } from "../../auth/hasClaim";
import { UserRole } from "../../models/app/userMe";

const DisputesPage = () => {
    const appPaths = useAppPaths();
    const { talentId, claims } = useCurrentUser();
    const { disputes, isLoading: isLoadingDisputes, createDispute } = useDisputes();
    const { gigTerminology, gigTerminologyPlural } = useOrganizationContext();
    const isTalent = hasClaim(UserRole.AC, claims);
    
    const { 
        gigs: ongoingOrCancelledGigs,
        isLoading: isLoadingGigs,
        refetchGigs
    } = useGigs({ 
        gigStatusIds: [gigStatuses.hired, gigStatuses.cancelled], 
        allClients: isTalent,
        hiredTalentId: isTalent ? talentId : undefined
    });
    const isLargeScreen = useIsLargeScreen();

    const createDisputeDialogState = useDialogBoxState();

    if (isLoadingDisputes || isLoadingGigs) return <Loader />;

    const disputableGigs = ongoingOrCancelledGigs.filter(_ =>
        !_.hasPendingDispute    
    );

    const handleCreateDispute = async (values: CreateDisputeFormValues) => {
        const response = await createDispute(values);

        if (response.success) {
            successToast("Dispute successfully created.");
            createDisputeDialogState.close();
            refetchGigs();
        }

        return response;
    };

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Disputes</Typography>
            
            <div className="pb-4 pr-4">
                <Button 
                    variant="primary" 
                    onClick={createDisputeDialogState.open} 
                    className="flex items-center ml-auto"
                    disabled={disputableGigs.length === 0}
                >
                    {disputableGigs.length === 0 
                        ? `No disputable ${gigTerminologyPlural.toLowerCase()}` 
                        : <><Plus className="mr-2" /> Create a dispute</>}
                    
                </Button>
            </div>
            
            {disputes.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {disputes.map(dispute => (
                        <Card key={dispute.gigId} className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6 break-words">
                            <div className="md:mr-8">
                                <Typography variant="title-large" component="p" className="mb-4">{dispute.gigTitle}</Typography>
                                <p className="mb-2">
                                    <span className="font-bold">Created on: </span>
                                    {dispute.createdAt.toLocaleDateString()}</p>
                                <p className="md:mr-8 mb-2">
                                    <span className="font-bold">Reason: </span>
                                    <span className="whitespace-pre-wrap">{dispute.reason}</span>
                                </p>
                                <p className="md:mr-8">
                                    <span className="font-bold">Status: </span>
                                    <span className="whitespace-pre-wrap">{dispute.disputeStatus === 0 ? "Pending" : "Resolved"}</span>
                                </p>
                            </div>
                            <LinkButton className="block md:ml-auto" to={appPaths.gigs.findAGigDetails(dispute.gigId)} variant="tertiary">View {gigTerminology.toLowerCase()}</LinkButton>
                        </Card>
                    ))}
                </div>
            )
            }
            <CreateDisputeFormDialog
                {...createDisputeDialogState}
                initialValues={{
                    gigId: "",
                    reason: "",
                    description: "",
                }}
                resetOnSubmit
                onSubmit={handleCreateDispute}
                disputableGigs={disputableGigs}
            />
        </PageContent>
    );
};

export default DisputesPage;