import { AuditDto } from "./audit";

export type GigInviteDto = {
    gigId: string
    gigInviteId?: string
    gigTitle: string
    message: string
    dateSent: Date
    talentId: string
    inviteStatusId?: number
    sentByUserName?: string
    acceptedDate?: Date
    declinedDate?: Date
    declinedReason?: string
    sentBy?: AuditDto
    lastUpdatedBy?: AuditDto
    cancelledBy?: AuditDto
    completedBy?: AuditDto
    onHoldBy?: AuditDto
    calendarEventId?: string
}

export const gigInviteStatuses = {
    sent: 0,
    accepted: 1,
    declined: 2,
    completed: 3,
    cancelled: 4,
    onHold: 5,
    expired: 6,
    pendings: 7,
};

export const gigInviteStatusLabels = [
    "Sent", 
    "Accepted",
    "Declined",
    "Completed",
    "Cancelled",
    "On Hold",
    "Expired",
    "Pending"
];