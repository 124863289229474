import { useGig } from "../../api/gig";
import useCurrentUser from "../../auth/useCurrentUser";
import Card from "../../components/Card";
import { useDialogBoxState } from "../../components/DialogBox";
import Typography from "../../components/Typography";
import UserAvatar from "../../components/UserAvatar";
import { useAppPaths, useGigId } from "../../Routes";
import CancelGigDialog from "../../components/CancelGigDialog";
import CompleteGigButton from "./CompleteGigButton";
import { gigStatuses } from "../../models/app/gig";
import GigDetailsCard from "../../components/GigDetailsCard";
import Button from "../../components/Button";
import PageContent from "../../components/PageContent";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { hasClaim } from "../../auth/hasClaim";
import { UserRole } from "../../models/app/userMe";

const GigDetailsPage = () => {
    const gigId = useGigId();
    const { gig, isLoading } = useGig(gigId);
    const { claims } = useCurrentUser();
    const appPaths = useAppPaths();
    const cancelGigDialogState = useDialogBoxState();
    const { gigTerminology, talentTerminology } = useOrganizationContext();
    const isDefaultClient = hasClaim(UserRole.Default, claims);
    const isSalesClient = hasClaim(UserRole.Sales, claims);

    if (isLoading || !gig) return <Loader />;

    const acceptedInvite = gig.acceptedInvite;

    return (
        <>
            <PageContent className="space-y-8">
                <div>
                    <Typography variant="display-small" component="h1" gutterBottom>{gigTerminology} details</Typography>
                    <GigDetailsCard gig={gig} />
                </div>
                {isDefaultClient && (
                    <div>
                        <Typography variant="title-large" component="h2" className="mb-4">Hired {talentTerminology.toLowerCase()}</Typography>
                        {acceptedInvite && gig.acceptedInvite !== undefined && (
                            <div className="md:flex md:space-x-4">
                                <Card className="pb-0 md:max-w-[230px] md:pb-6 flex justify-center items-center rounded-b-none md:rounded-b-lg">
                                    <UserAvatar name={acceptedInvite.talentName} userImageUrl={acceptedInvite.talentProfileImageThumbnailUrl} />
                                </Card>
                                <Card key={acceptedInvite.gigInviteId} className="md:flex md:grow items-center space-y-4 md:space-y-0 rounded-t-none md:rounded-t-lg">
                                    <div className="md:ml-auto text-center">
                                        <Link to={appPaths.talents.findTalentDetails(gig.acceptedInvite.talentId)} target="_blank"><Button className="w-fit" variant="tertiary">View profile</Button></Link>
                                    </div>
                                </Card>
                            </div>
                        )}
                    </div>
                )}

                {isDefaultClient && (
                    <div className="flex flex-col-reverse md:flex-row md:justify-between">
                        {gig.gigStatusId !== gigStatuses.completed && (
                            <Button
                                type="button"
                                className="w-full md:w-auto"
                                variant="secondary"
                                disabled={gig?.gigStatusId === gigStatuses.cancelled}
                                onClick={cancelGigDialogState.open}
                            >
                                {gig.gigStatusId === gigStatuses.cancelled ? "Cancelled" : `Cancel ${gigTerminology.toLowerCase()}`}
                            </Button>
                        )}
                        <div className="grow"></div>
                        {!isSalesClient && (
                            <CompleteGigButton
                                gigId={gig.id}
                                className="w-full mb-4 md:mb-0 md:w-auto"
                            />
                        )}                        
                    </div>
                )}
            </PageContent>
            <CancelGigDialog
                gigId={gig.id}
                isOpen={cancelGigDialogState.isOpen}
                onClose={cancelGigDialogState.onClose}
                requireReason
            />
        </>
    );
};

export default GigDetailsPage;
