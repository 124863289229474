import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useTalentOngoingGigs } from "../api/talentMyOngoingGigs";
import Card, { CardDataFieldLabel } from "../components/Card";
import LinkButton from "../components/LinkButton";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useAppPaths } from "../Routes";
import { gigStatuses } from "../models/app/gig";
import { useIsLargeScreen } from "../hooks/screenSizes";
import Loader from "../components/Loader";
import { formatLocationDateToString, formatLocationTimeToString } from "../utils/dateFormatters";
import Tooltip from "../components/Tooltip";
import DateDisplayLocale from "../components/DateDisplayLocale";
import { GigChangesAcceptButton } from "../components/GigChangesAcceptButton";
import { GigChangesDeclineButton } from "../components/GigChangesDeclineButton";

const GigsOngoingTalentPage = () => {
    const appPaths = useAppPaths();
    const { gigs, isLoading, mutate } = useTalentOngoingGigs([gigStatuses.hired, gigStatuses.pending], "PostedDate");
    const { gigTerminologyPlural } = useOrganizationContext();
    const isLargeScreen = useIsLargeScreen();

    if (isLoading || !gigs) return <Loader />;

    return (
        <PageContent>
            <Typography variant={isLargeScreen ? "display-large" : "display-small"} component="h1" gutterBottom>Accepted {gigTerminologyPlural}</Typography>
            {gigs.length === 0 ? (
                <Card>
                    <Typography variant="title-large" component="p">None yet...</Typography>
                </Card>
            ) : (
                <div className="space-y-8">
                    {gigs.map(gig => (
                        <div key={gig.id} className="md:flex md:items-stretch break-words">
                            <Card className="flex flex-col md:flex-row text-center md:text-left items-center grow md:mr-4 rounded-b-none md:rounded-b-lg pb-2 md:pb-6">
                                <div className="space-y-2 md:mr-8">
                                    <Typography variant="title-large" component="p" className="max-w-md">{gig.title}</Typography>
                                    <Typography variant="title-small" component="h3">{gig.skills[0].name}</Typography>
                                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 pt-2 justify-items-center md:justify-items-start">
                                        <CardDataFieldLabel iconStart iconSize={32} iconName="Calendar" textClass="font-normal">{formatLocationDateToString(gig.startDate, gig.ianaId)}</CardDataFieldLabel>
                                        <CardDataFieldLabel iconStart iconSize={32} iconName="Clock" textClass="font-normal">
                                            <div className="flex items-center">
                                                {formatLocationTimeToString(gig.startDate, gig.ianaId)}
                                                <Tooltip className="ml-2" tooltipText="" dateDisplay={<DateDisplayLocale date={gig.startDate} />}></Tooltip>
                                            </div>
                                        </CardDataFieldLabel>
                                    </div>
                                    <Typography variant="body" component="p">{`Status: ${gig.isConfirmed ? "Confirmed" : "Awaiting confirmation"}`}</Typography>
                                </div>
                                <div className="flex flex-col md:flex-row md:space-x-2 md:ml-auto">
                                    <LinkButton variant="tertiary" to={appPaths.gigs.details(gig.id)} className="w-full md:w-auto text-center">View details</LinkButton>
                                    {gig.gigStatusId === gigStatuses.pending && gig.receivedInviteId && (
                                        <div className="flex flex-col sm:flex-row sm:space-x-4 w-full">
                                            <GigChangesDeclineButton gigInviteId={gig.receivedInviteId} mutate={mutate} />
                                            <GigChangesAcceptButton gigInviteId={gig.receivedInviteId} mutate={mutate} />
                                        </div>
                                    )}
                                </div>
                            </Card>
                        </div>
                    ))}
                </div>                
            )}
        </PageContent>
    );
};

export default GigsOngoingTalentPage;