import clsx from "clsx";
import { gigInviteStatusLabels, gigInviteStatuses } from "../models/app/gigInvite";

export type InviteStatusChipProps = {
    inviteStatusId: number
}

const GigInviteStatusChip = ({
    inviteStatusId,
}: InviteStatusChipProps) => {
    const label = gigInviteStatusLabels[inviteStatusId];
    return (
        <span className={clsx(
            "w-fit rounded-full px-6 py-1.5 font-bold text-white",
            inviteStatusId === gigInviteStatuses.sent && "bg-[#9e9e9e]",
            inviteStatusId === gigInviteStatuses.accepted && "bg-secondary",
            inviteStatusId === gigInviteStatuses.declined && "bg-error",
            inviteStatusId === gigInviteStatuses.completed && "!text-black",
            inviteStatusId === gigInviteStatuses.cancelled && "!text-black",
            inviteStatusId === gigInviteStatuses.onHold && "bg-[#ff8235]",
            inviteStatusId === gigInviteStatuses.expired && "!text-error",
        )}>
            {label}
        </span>
    );
};

export default GigInviteStatusChip;