import { useState } from "react";
import { Controller } from "react-hook-form";

import Button from "../../components/Button";
import FormDropdown from "../../components/FormDropdown";
import ErrorList from "../../components/ErrorList";
import { useTalentLanguage } from "../../api/talentLanguage";
import LanguageDropdown from "../../components/LanguageDropdown";
import { TalentLanguageFormValues, useTalentLanguageForm } from "./TalentLanguageFormValues";
import { languageProficiencyOptions } from "../../models/app/talentLanguage";
import TalentLanguageChips from "./TalentLanguageChips";

const TalentLanguageForm = () => {
    const [errors, setErrors] = useState<string[]>([]);
    const methods = useTalentLanguageForm();
    const {
        talentLanguage,
        addTalentLanguage,
        removeTalentLanguage,
        isRemovingTalentLanguage,
        isAddingTalentLanguage,
    } = useTalentLanguage();

    const handleSubmit = async (values: TalentLanguageFormValues) => {
        setErrors([]);

        const existingLanguage = talentLanguage.find(language => language.languageId === values.languageId);

        if (existingLanguage) {
            setErrors([`Language "${existingLanguage.languageName}" already added.`]);
            methods.reset();
            return;
        }

        const response = await addTalentLanguage(values.languageId, values.languageProficiencyId);

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else {
            methods.reset();
            return;
        }
    };

    return (
        <div>
            <form
                noValidate
                className="flex gap-6 flex-wrap md:flex-nowrap md:items-start"
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                    <div className="w-full">
                        <Controller
                            name="languageId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <LanguageDropdown
                                    required
                                    // tooltip={talentProfileEditTooltips.industrialExperience(giggedClientTerminologyPlural)}
                                    label="Select language"
                                    error={methods.formState.errors.languageId}
                                    onChange={onChange}
                                    value={value}
                                    disabled={isAddingTalentLanguage}
                                />
                            )}
                        />
                    </div>
                    <div className="w-full">
                        <Controller
                            name="languageProficiencyId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="Select proficiency"
                                    options={languageProficiencyOptions}
                                    error={methods.formState.errors.languageProficiencyId}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isAddingTalentLanguage}
                                />
                            )}
                        />
                    </div>
                </div>
                <Button
                    className="w-full md:mt-auto md:w-auto"
                    type="submit"
                    variant="secondary"
                    loading={isAddingTalentLanguage}
                >
                    Add
                </Button>
            </form>

            <ErrorList errors={errors} />
            
            <TalentLanguageChips language={talentLanguage} removeTalentLanguage={removeTalentLanguage} isRemovingLanguage={isRemovingTalentLanguage} />
        </div>
    );
};

export default TalentLanguageForm;