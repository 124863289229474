import { useEffect, useState } from "react";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiCancelGigDto, ApiGigDetails, ApiUpdateGigDto } from "../models/api/gig";
import { mapFromApiGigDetails, mapToApiGig } from "../models/mappers/gig";
import { GigDetails, UpdateGigDto, InviteToGigDto, CancelGigDto, AddFileToGigDto, DeleteFileFromGigDto } from "../models/app/gig";
import ApiError from "./common/apiError";

export const useInviteToGig = () => useAuthenticatedGigApiFetcher<InviteToGigDto, void, { gigId: string, dto: InviteToGigDto }>("POST",
    ({ gigId, dto }) => ({
        url: `api/gigs/${gigId}/invites`,
        body: dto
    }));

export const useAddFileToGig = () => useAuthenticatedGigApiFetcher<AddFileToGigDto, void, { gigId: string, dto: AddFileToGigDto }>("POST",
    ({ gigId, dto }) => ({
        url: `api/gigs/${gigId}/files`,
        body: dto
    }));


export const useDeleteFileFromGig = () => useAuthenticatedGigApiFetcher<DeleteFileFromGigDto, void, { gigId: string, dto: DeleteFileFromGigDto }>("DELETE",
    ({ gigId, dto }) => ({
        url: `api/gigs/${gigId}/files/${dto.fileId}`,
        body: dto
    }));

export const useRegenerateMatches = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (gigId) => ({
        url: `api/gigs/${gigId}/actions/regenerate-matches`
    }));

export const useUpdateGig = () => useAuthenticatedGigApiFetcher<ApiUpdateGigDto, void, { gigId: string, dto: UpdateGigDto }>("PUT",
    ({ gigId, dto }) => ({
        url: `api/gigs/${gigId}`,
        body: mapToApiGig(dto),
    }));

export const useCompleteGig = () => useAuthenticatedGigApiFetcher<string, void, string>("PUT",
    (gigId) => ({
        url: `api/gigs/${gigId}/actions/complete`,
    }));

export const useCancelGig = () => useAuthenticatedGigApiFetcher<ApiCancelGigDto, void, { gigId: string, dto: CancelGigDto }>("PUT",
    ({ gigId, dto }) => ({
        url: `api/gigs/${gigId}/actions/cancel`,
        body: dto,
    }));

export const usePutGigOnHold = () => useAuthenticatedGigApiFetcher<never, void, string>("PUT",
    (gigId) => ({
        url: `api/gigs/${gigId}/actions/draft`,
    }));

export const useConfirmGig = () => useAuthenticatedGigApiFetcher<string, void, string>("PUT",
    (gigId) => ({
        url: `api/gigs/${gigId}/actions/confirm`,
    }));

export type UseGigReturn<TGigId extends string | undefined> = TGigId extends undefined ?
    { gig: undefined } :
    {
        gig?: GigDetails
        isLoading: boolean
        error?: ApiError
        reload: () => Promise<void>
        regenerateMatches: () => Promise<GigApiFetcherResponse<void>>
        updateGig: (dto: UpdateGigDto) => Promise<GigApiFetcherResponse<void>>
        completeGig: (gigId: string) => Promise<GigApiFetcherResponse<void>>
        cancelGig: (dto: CancelGigDto) => Promise<GigApiFetcherResponse<void>>
        inviteToGig: (dto: InviteToGigDto) => Promise<GigApiFetcherResponse<void>>
        addFileToGig: (dto: AddFileToGigDto) => Promise<GigApiFetcherResponse<void>>
        deleteFileFromGig: (dto: DeleteFileFromGigDto) => Promise<GigApiFetcherResponse<void>>
        putGigOnHold: () => Promise<GigApiFetcherResponse<void>>
        confirmGig: (gigId: string) => Promise<GigApiFetcherResponse<void>>
        isAddingFileToGig: boolean
        isDeletingFileFromGig: boolean
        isInvitingToGig: boolean
        isRegeneratingMatches: boolean
        isUpdatingGig: boolean
        isCompletingGig: boolean
        isCancellingGig: boolean
        isPuttingGigOnHold: boolean
        isConfirmingGig: boolean
    }

export const useGig = <TGigId extends string | undefined>(gigId: TGigId): UseGigReturn<TGigId> => {
    const [gig, setGig] = useState<GigDetails | undefined>();

    const url = `api/gigs/${gigId}`;

    const apiCall = useGiggedApiSWR<ApiGigDetails>(url);

    useEffect(() => {
        if (apiCall.data === undefined) {
            setGig(undefined);
            return;
        } 

        setGig(mapFromApiGigDetails(apiCall.data));
    }, [apiCall.data]);

    const [addFileToGig, isAddingFileToGig] = useAddFileToGig();
    const [deleteFileFromGig, isDeletingFileFromGig] = useDeleteFileFromGig();
    const [inviteToGig, isInvitingToGig] = useInviteToGig();
    const [regenerateMatches, isRegeneratingMatches] = useRegenerateMatches();
    const [updateGig, isUpdatingGig] = useUpdateGig();
    const [completeGig, isCompletingGig] = useCompleteGig();
    const [cancelGig, isCancellingGig] = useCancelGig();
    const [putGigOnHold, isPuttingGigOnHold] = usePutGigOnHold();
    const [confirmGig, isConfirmingGig] = useConfirmGig();


    if (gigId === undefined) return { gig: undefined } as UseGigReturn<TGigId>;

    return {
        gig,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        regenerateMatches: async () => {
            const response = await regenerateMatches(gigId);

            if (response.success) {
                await apiCall.mutate();
            }

            return response;
        },
        updateGig: async (dto: UpdateGigDto) => {
            const response = await updateGig({ gigId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        inviteToGig: async (dto: InviteToGigDto) => {
            const response = await inviteToGig({ gigId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        addFileToGig: async (dto: AddFileToGigDto) => {
            const response = await addFileToGig({ gigId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        deleteFileFromGig: async (dto: DeleteFileFromGigDto) => {
            const response = await deleteFileFromGig({ gigId, dto });

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        completeGig: async () => {
            const response = await completeGig(gigId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        cancelGig: async (dto: CancelGigDto) => {
            const response = await cancelGig({gigId, dto });

            if (response.success) {
                apiCall.mutate();
            }
            return response;
        },
        putGigOnHold: async () => {
            const response = await putGigOnHold(gigId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        confirmGig: async () => {
            const response = await confirmGig(gigId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isDeletingFileFromGig,
        isAddingFileToGig,
        isInvitingToGig,
        isRegeneratingMatches,
        isUpdatingGig,
        isCompletingGig,
        isCancellingGig,
        isPuttingGigOnHold,
        isConfirmingGig,
        reload: apiCall.mutate,
    } as UseGigReturn<TGigId>;
};