import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { formClassNames } from "./classNames";
import FormTextInput from "../FormTextInput";
import FormTextAreaInput from "../FormTextAreaInput";

export type CreateGigFormStep4Fields = Pick<GigFormValues, "expectedNumberParticipants" | "description">;

export type CreateGigFormStep4Props = {
    onSubmit: (value: CreateGigFormStep4Fields) => void
    onBackClick: (value: CreateGigFormStep4Fields) => void
    defaultValues: CreateGigFormStep4Fields
}

const CreateGigFormStep4 = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep4Props) => {

    const validationSchema = yup.object({
        expectedNumberParticipants: buildGigFormValidationSchemaFields.expectedNumberParticipants,
        description: buildGigFormValidationSchemaFields.description
    });

    const methods = useForm<CreateGigFormStep4Fields>({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title="Program Objectives for AC"
                description="Please indicate expected number of participants at session and any objectives of the program that the client has indicated to help us give the AC more detail on the event requirements at point of booking."
                content={
                    <span className="space-y-6">
                        <FormTextInput
                            required
                            id="create-gig-form-expected-number-participants"
                            label="What are the expected number of participants?"
                            error={methods.formState.errors.expectedNumberParticipants}
                            defaultValue={defaultValues.expectedNumberParticipants}
                            type="number"
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            placeholder="Number of particpants"
                            {...methods.register("expectedNumberParticipants")}
                        />
                        <FormTextAreaInput
                            id="create-gig-form-project-objectives"
                            label="Are there any set objectives the client wishes to get out of the event?"
                            placeholder="Please provide any objectives which the clients specifies on the event. "
                            register={methods.register("description")}
                            error={methods.formState.errors.description}
                            defaultValue={defaultValues.description}
                            rows={7}
                            isResizeable
                        />
                    </span>
                }
                iconName="Target"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep4;