import clsx from "clsx";
import React from "react";
import { FieldError } from "react-hook-form";
import { MagnifyingGlass, Warning } from "phosphor-react";

import FormInputLabel from "./FormInputLabel";
import FormInputMessage from "./FormInputError";

export type FormTextInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "className"> & {
    label?: string
    error?: FieldError
    helpText?: string
    required?: boolean
    tooltip?: string
    type?: "text" | "number" | "tel"
    labelClassName?: string
    StartAdornmentIcon?: typeof MagnifyingGlass
    fillStartAdornment?: boolean
    className?: string
};

const FormTextInput = React.forwardRef<HTMLInputElement, FormTextInputProps>(({
    id,
    label,
    error,
    helpText,
    required,
    tooltip,
    type = "text",
    labelClassName,
    StartAdornmentIcon,
    fillStartAdornment,
    className,
    ...inputProps
}, ref) => {

    return (
        <div className={clsx("w-full", className)}>
            <div>
                {label && (
                    <FormInputLabel htmlFor={id} required={required} className={labelClassName} tooltipText={tooltip}>{label}</FormInputLabel>
                )}
            </div>
            <div className="relative">
                <input
                    {...inputProps}
                    id={id}
                    type={type}
                    ref={ref}
                    aria-required={required}
                    className={clsx(
                        "border rounded-xl w-full p-4",
                        "focus:outline-none focus:ring-inset focus:ring-2 focus:ring-primary-text",
                        error && "outline-error border-error focus:ring-error outline outline-2 pr-10",
                        StartAdornmentIcon && "pl-12"
                    )}
                />
                {StartAdornmentIcon && (
                    <StartAdornmentIcon
                        weight={fillStartAdornment ? "fill" : undefined}
                        className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3"
                    />
                )}
                {error && (
                    <Warning className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3 text-error" weight="fill" />
                )}
            </div>
            <FormInputMessage error={error} helpText={helpText} />
        </div >
    );
});

export default FormTextInput;
