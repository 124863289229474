import { fetchIndustrialExperienceOptions } from "../../api/industrialExperience";
import { fetchSkillOptions } from "../../api/skills";
import FindPage from "../../components/FindPage";
import SearchableCheckBoxGroup from "../../components/SearchableCheckBoxGroup";
import { useFindTalentContext } from "./findTalentContext";
import SortOrderDropdown from "./SortOrderDropdown";
import TalentsList from "./TalentsList";
import { FindPagePanelSectionProps } from "../../components/FindPage/FindPagePanelSection";
import SearchableSkillsCheckBoxGroup from "../../components/SearchableSkillsCheckboxGroup";
import { fetchLocationOptions } from "../../api/locations";
import { fetchLanguageOptions } from "../../api/languages";

const FindTalentPageComponent = () => {
    const {
        searchTerm,
        skillIds,
        locationIds,
        languageIds,
        industrialExperienceIds,
        anyActiveFilters,
        talents,
        isLoading,
        setSearchTerm,
        setSkillIds,
        setLocationIds,
        setLanguageIds,
        setIndustrialExperienceIds,
        clearAllFilters,
        searchNow,
    } = useFindTalentContext();

    const filterPanelSections: FindPagePanelSectionProps[] = [
        {
            title: "Insights workshop types",
            subtitle: `(${skillIds.length} selected)`,
            content: (
                <SearchableSkillsCheckBoxGroup
                    selectedSkillIds={skillIds}
                    searchPlaceholder="Search by workshop type"
                    onChange={setSkillIds}
                    fetchSkills={fetchSkillOptions}
                    accessTokenNotRequired
                />
            )
        },
        {
            title: "Industry experience",
            subtitle: `(${industrialExperienceIds.length} selected)`,
            content: (
                <SearchableCheckBoxGroup
                    selectedOptionIds={industrialExperienceIds}
                    searchPlaceholder="Search by industry"
                    onChange={setIndustrialExperienceIds}
                    fetchOptions={fetchIndustrialExperienceOptions}
                />
            )
        },
        {
            title: "Location",
            subtitle: `(${locationIds.length} selected)`,
            content: (
                <SearchableCheckBoxGroup
                    selectedOptionIds={locationIds}
                    searchPlaceholder="Search by location"
                    onChange={setLocationIds}
                    fetchOptions={fetchLocationOptions}
                />
            )
        },
        {
            title: "Language",
            subtitle: `(${languageIds.length} selected)`,
            content: (
                <SearchableCheckBoxGroup
                    selectedOptionIds={languageIds}
                    searchPlaceholder="Search by language"
                    onChange={setLanguageIds}
                    fetchOptions={fetchLanguageOptions}
                />
            )
        }
    ];

    return (
        <FindPage
            title="Filter by:"
            searchTerm={searchTerm}
            anyActiveFilters={anyActiveFilters}
            setSearchTerm={setSearchTerm}
            clearAllFilters={clearAllFilters}
            searchNow={searchNow}
            searchPlaceholder="Search by talent name"
            sortOrderDropdown={(
                <SortOrderDropdown />
            )}
            filterPanelSections={filterPanelSections}
        >
            <TalentsList talents={talents} isLoading={isLoading} />
        </FindPage>
    );
};

export default FindTalentPageComponent;