import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm/GigFormValues";
import { formClassNames } from "./classNames";
import FormTextAreaInput from "../FormTextAreaInput";

export type CreateGigFormStep7Fields = Pick<GigFormValues, "notes">;

export type CreateGigFormStep7Props = {
    onSubmit: (value: CreateGigFormStep7Fields) => void
    onBackClick: (value: CreateGigFormStep7Fields) => void
    defaultValues: CreateGigFormStep7Fields
}

const CreateGigFormStep7 = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep7Props) => {

    const validationSchema: yup.SchemaOf<CreateGigFormStep7Fields> = yup.object({
        notes: buildGigFormValidationSchemaFields.notes,
    });

    const methods = useForm<CreateGigFormStep7Fields>({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title="Notes"
                description="Please leave any notes which may aid the resource team in sourcing the right AC for this client."
                content={
                    <FormTextAreaInput
                        id="create-gig-form-notes"
                        label="Notes"
                        error={methods.formState.errors.notes}
                        defaultValue={defaultValues.notes}
                        placeholder="Add any relevant notes"
                        rows={7}
                        register={methods.register("notes")}
                    />
                }
                iconName="Note"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep7;