import TalentSummaryCardSkeleton from "./TalentCardSkeleton";

export const TalentListSkeleton = () => {
    return (
        <div className="flex flex-col w-full space-y-8">
            <TalentSummaryCardSkeleton />
            <TalentSummaryCardSkeleton />
            <TalentSummaryCardSkeleton />
            <TalentSummaryCardSkeleton />
        </div>
    );
};

export default TalentListSkeleton;