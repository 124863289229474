export type UserGetMeDto = {
    talentId?: string
    talentTradingName?: string
    giggedClientId?: string
    giggedClientName?: string
    giggedClientDisplayName?: string
    giggedClientIsVerified?: boolean
    organizationName?: string
    userClaims: UserClaimDto[]
    isCalendarConnected: boolean
}

export type UserClaimDto = {
    claimTypeId: number
    claimTypeName: string
}

export enum UserRole {
    Default = 0, 
    Sales = 1, 
    Resources = 2, 
    AC = 3, 
    Admin = 4, 
    Global = 5
}