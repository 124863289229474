import { ApiTalentDetails } from "../models/api/talent";
import { TalentDetails } from "../models/app/talent";
import { mapFromApiTalentDetails } from "../models/mappers/talent";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export type UseTalentReturn = {
    talent?: TalentDetails & {
        fullName: string
    }
    isLoading: boolean
}

export const useTalent = (talentId?: string): UseTalentReturn => {
    const url = `api/talents/${talentId}`;

    const apiCall = useGiggedApiSWR<ApiTalentDetails>(url);
    const talent = apiCall.data;

    return {
        talent: !talent ? undefined : mapFromApiTalentDetails(talent),
        isLoading: apiCall.isLoading
    };
};