import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import FormDateTimeInput from "../FormDateTimeInput";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { formClassNames } from "./classNames";
import { expectedDurationOptions } from "../../models/app/gig";
import FormDropdown from "../FormDropdown";

export type CreateGigFormStep6Fields = Pick<GigFormValues, "startDate" | "expectedDurationId">;

export type CreateGigFormStep6Props = {
    onSubmit: (value: CreateGigFormStep6Fields) => void
    onBackClick: (value: CreateGigFormStep6Fields) => void
    defaultValues: CreateGigFormStep6Fields
}

const CreateGigFormStep6 = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep6Props) => {

    const validationSchema: yup.SchemaOf<CreateGigFormStep6Fields> = yup.object({
        startDate: buildGigFormValidationSchemaFields.startDate,
        expectedDurationId: buildGigFormValidationSchemaFields.expectedDurationId
    });

    const methods = useForm<CreateGigFormStep6Fields>({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title="What is the date and time of the workshop?"
                description="Please indicate desired date and start time of the event specified by the clients in order for us to find an AC that is available at this time."
                content={
                    <span className="space-y-6">
                        <Controller
                            name="startDate"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDateTimeInput
                                    required
                                    id="create-gig-form-start-date"
                                    label="Select date / time of event"
                                    value={value}
                                    onChange={onChange}
                                    error={methods.formState.errors.startDate}
                                    minDate={new Date()}
                                />
                            )}
                        />
                        <Controller
                            name="expectedDurationId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="Duration"
                                    options={expectedDurationOptions}
                                    error={methods.formState.errors.expectedDurationId}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </span>
                }
                iconName="CalendarCheck"
                buttons={
                    <>
                        <Button onClick={handleBackClick} variant="secondary" type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep6;