import { useStateParams } from "../hooks/useStateParams";
import { TalentOrderByFieldKeys, TalentSummary } from "../models/app/talent";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";

export type UseTalentsParams = {
    pageSize?: number
}

export type UseTalentsReturn = {
    talents: TalentSummary[]
    isLoading: boolean
    sortOrder: SortOrder
    orderBy: TalentOrderByFieldKeys
    searchTerm: string
    skillIds: string[]
    locationIds: string[]
    languageIds: string[]
    industrialExperienceIds: string[]
    setSearchTerm: (value: string) => void
    setSortOrder: (value: SortOrder) => void
    setOrderBy: (value: TalentOrderByFieldKeys) => void
    setSkillIds: (skillIds: string[]) => void
    setLocationIds: (locationIds: string[]) => void
    setLanguageIds: (languageIds: string[]) => void
    setIndustrialExperienceIds: (skillIds: string[]) => void
}

export type UseTalents = (params?: UseTalentsParams) => UseTalentsReturn;

export const useTalents: UseTalents = ({
    pageSize,
} = {}) => {
    const [searchTerm, setSearchTerm] = useStateParams("", "searchTerm", "string");
    const [skillIds, setSkillIds] = useStateParams<string[]>([], "skillIds", "stringArray");
    const [locationIds, setLocationIds] = useStateParams<string[]>([], "locationIds", "stringArray");
    const [languageIds, setLanguageIds] = useStateParams<string[]>([], "languageIds", "stringArray");
    const [industrialExperienceIds, setIndustrialExperienceIds] = useStateParams<string[]>([], "industrialExperienceIds", "stringArray");
    const [sortOrder, setSortOrder] = useStateParams<SortOrder>("desc", "sortOrder", "string");
    const [orderBy, setOrderBy] = useStateParams<TalentOrderByFieldKeys>("FirstName", "orderBy", "string");

    const searchTermQuery = searchTerm && searchTerm !== "" ? `&searchTerm=${searchTerm}` : "";
    const skillsQuery = skillIds.length > 0 ? `&skillIds=${skillIds.join(",")}` : "";
    const locationsQuery = locationIds.length > 0 ? `&locationIds=${locationIds.join(",")}` : "";
    const languagesQuery = languageIds.length > 0 ? `&languageIds=${languageIds.join(",")}` : "";
    const industrialExperienceQuery = industrialExperienceIds.length > 0 ? `&industrialExperienceIds=${industrialExperienceIds.join(",")}` : "";

    const url = "api/talents?pageIndex=0" +
        `&pageSize=${pageSize}` +
        `&sortOrder=${sortOrder}` +
        `&orderBy=${orderBy}` +
        searchTermQuery +
        skillsQuery + 
        locationsQuery +
        industrialExperienceQuery +
        languagesQuery;

    const { data, isLoading } = useGiggedApiSWR<GigApiPaginatedData<TalentSummary>>(url);

    return {
        talents: data?.items || [],
        isLoading,
        orderBy,
        sortOrder,
        searchTerm,
        skillIds,
        locationIds,
        languageIds,
        industrialExperienceIds,
        setSearchTerm,
        setOrderBy,
        setSortOrder,
        setSkillIds,
        setLocationIds,
        setLanguageIds,
        setIndustrialExperienceIds,
    };
};
