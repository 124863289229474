import { useClientProfile } from "../../api/clientProfile";
import { useGigs } from "../../api/gigs";
import GigSummaryCard from "../../components/GigSummaryCard";
import ProfileImage from "../../components/ProfileImage";
import Typography from "../../components/Typography";
import { useAppPaths } from "../../Routes";
import { gigStatuses } from "../../models/app/gig";
import { useClientId } from "../../Routes";
import formatDateOnlyString from "../../utils/formatDateString";
import ClientProfileDetailsCard from "./ClientProfileDetailsCard";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import SanitizeHtml from "../../components/SanitizeHtml";
import ReportButton from "../../components/Reports/ReportButton";
import Loader from "../../components/Loader";
import Tooltip from "../../components/Tooltip";

const ClientDetails = () => {
    const appPaths = useAppPaths();
    const clientId = useClientId();

    const { organizationConfig, giggedClientTerminology } = useOrganizationContext();

    const { 
        clientProfile,
        isLoading: isClientProfileLoading
    } = useClientProfile(clientId);

    const {
        gigs,
        isLoading: isGigsLoading,
    } = useGigs({
        gigStatusIds: [gigStatuses.posted],
        allClients: true,
        giggedClientId: clientId
    });

    if (isClientProfileLoading || isGigsLoading || !clientProfile) return <Loader />;

    return (
        <>
            <div className="pt-20 md:pt-20 pb-20 md:pb-12 bg-primary-text">
                <div className="max-w-6xl mx-auto">
                    <div className="mx-4 md:mx-8">
                        <div className="items-start text-center space-y-6 sm:flex sm:text-left sm:space-y-0">
                            <ProfileImage
                                className="inline-block mx-auto sm:block"
                                imageUrl={clientProfile.profileImageUrl}
                                name={clientProfile.name || giggedClientTerminology}
                                size="lg"
                            />
                            <div className="self-stretch grow sm:ml-6">
                                <Typography variant="headline-small" component="h1" className="text-center sm:text-left my-2 text-white break-words">{clientProfile.name || clientProfile.companyName}</Typography>
                                {clientProfile.companyTagline && (
                                    <Typography variant="title-small" component="p" className="text-center sm:text-left mb-4 text-white break-words">{clientProfile.companyTagline}</Typography>
                                )}
                                {clientProfile.isWorksome && (                                    
                                    <div className="inline-block bg-white rounded-full pl-1.5 pr-1.5 pt-1 pb-1" style={{ maxWidth: "160px" }}>
                                        <Tooltip tooltipText={`This ${giggedClientTerminology.toLowerCase()} is a representative of Worksome`}>
                                            <img
                                                className="max-h-[20px]"
                                                src="/worksome-logo-black-full-150x24.svg"
                                                alt="Worksome logo"
                                            />
                                        </Tooltip>
                                    </div>                                    
                                )}
                            </div>                            
                        </div>
                        <div className="bg-primary h-[3px] mt-8 mb-8 sm:mb-12" />
                        <div className="block sm:flex space-x-0 space-y-8 sm:space-x-12 sm:space-y-0">
                            <div className="flex flex-col gap-4 w-1/4">
                                <div>
                                    <label className="text-white text-label-small whitespace-nowrap font-bold">Member since</label>
                                    <p className="text-white">{formatDateOnlyString(clientProfile.createdAt)}</p>
                                </div>
                                {clientProfile.location && (
                                    <div>
                                        <label className="text-white text-label-small whitespace-nowrap font-bold">Location</label>
                                        <p className="text-white">{clientProfile.location.country}</p>
                                    </div>
                                )}
                            </div>
                            <div className="space-y-6">                                
                                <p className="text-white whitespace-pre-wrap">
                                    <SanitizeHtml html={clientProfile.aboutMe || ""} />
                                </p>
                                {organizationConfig.isReportsEnabled && (
                                    <div>
                                        <ReportButton 
                                            type="GiggedClient" 
                                            entityId={clientId}
                                            className="text-white hover:text-surface-accent"
                                        />
                                    </div>
                                )}
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="-mt-6 max-w-6xl mx-auto">
                <div className="md:flex sm:items-start space-y-4 sm:space-y-0 md:space-x-6 mx-4 md:mx-8">
                    <div className="space-y-8 md:w-4/5 grow mb-8">
                        {gigs.map(gig => (
                            <GigSummaryCard key={gig.id} gig={gig} viewGigLink={appPaths.gigs.findAGigDetails(gig.id)} />
                        ))}                        
                    </div>
                    <ClientProfileDetailsCard className="sm:min-w-[355px]" clientProfile={clientProfile} />
                </div>
            </div>
        </>
    );
};

export default ClientDetails;
