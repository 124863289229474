import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";


export type TalentIndustrialExperienceFormValues = {
    industrialExperienceId: string;
};

export const validationSchemaFields = {
    industrialExperienceId: yup.string().required("Please select an option.")
};

const schema = yup.object(validationSchemaFields);

export const useTalentIndustrialExperienceForm = () => {
    return useForm<TalentIndustrialExperienceFormValues>({
        resolver: yupResolver(schema)
    });
};