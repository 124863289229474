import { useState } from "react";
import { Controller } from "react-hook-form";

import Button from "../../components/Button";

import TalentIndustrialExperienceChips from "./TalentIndustrialExperienceChips";
import { useTalentIndustrialExperience } from "../../api/talentIndustrialExperience";
import { TalentIndustrialExperienceFormValues, useTalentIndustrialExperienceForm } from "./TalentIndustrialExperienceFormValues";
import IndustrialExperienceDropdown from "../../components/IndustrialExperienceDropdown";
import { talentProfileEditTooltips } from "../../tooltipsContent";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import ErrorList from "../../components/ErrorList";

const TalentIndustrialExperienceForm = () => {
    const [errors, setErrors] = useState<string[]>([]);
    const methods = useTalentIndustrialExperienceForm();
    const {
        talentIndustrialExperience,
        addTalentIndustrialExperience,
        removeTalentIndustrialExperience,
        isRemovingTalentIndustrialExperience,
        isAddingTalentIndustrialExperience,
    } = useTalentIndustrialExperience();
    const { giggedClientTerminologyPlural } = useOrganizationContext();

    const handleSubmit = async (values: TalentIndustrialExperienceFormValues) => {
        setErrors([]);

        const existingIndustrialExperience = talentIndustrialExperience.find(industry => industry.industrialExperienceId === values.industrialExperienceId);

        if (existingIndustrialExperience) {
            setErrors([`Industry "${existingIndustrialExperience.industrialExperienceName}" already added.`]);
            methods.reset();
            return;
        }

        const response = await addTalentIndustrialExperience(values.industrialExperienceId);

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else {
            methods.reset();
            return;
        }
    };

    return (
        <div>
            <form
                noValidate
                className="flex gap-6 flex-wrap md:flex-nowrap md:items-start"
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                    <div className="w-full">
                        <Controller
                            name="industrialExperienceId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <IndustrialExperienceDropdown
                                    tooltip={talentProfileEditTooltips.industrialExperience(giggedClientTerminologyPlural)}
                                    error={methods.formState.errors.industrialExperienceId}
                                    onChange={onChange}
                                    value={value}
                                    disabled={isAddingTalentIndustrialExperience}
                                />
                            )}
                        />
                    </div>
                </div>
                <Button
                    className="w-full md:mt-auto md:w-auto"
                    type="submit"
                    variant="secondary"
                    loading={isAddingTalentIndustrialExperience}
                >
                    Add
                </Button>
            </form>

            <ErrorList errors={errors} />
            
            <TalentIndustrialExperienceChips industrialExperience={talentIndustrialExperience} removeIndustrialExperience={removeTalentIndustrialExperience} isRemovingIndustrialExperience={isRemovingTalentIndustrialExperience} />
        </div>
    );
};

export default TalentIndustrialExperienceForm;