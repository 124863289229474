import Chip, { ChipContainer } from "../../components/Chip";
import { ApiTalentLanguageDto } from "../../models/api/talentLanguage";

export type TalentLanguageChipsProps = {
    language: ApiTalentLanguageDto[],
    removeTalentLanguage: (languageId: string) => void,
    isRemovingLanguage: boolean
}

const TalentLanguageChips = ({
    language,
    removeTalentLanguage,
    isRemovingLanguage,
}: TalentLanguageChipsProps) => {

    if (isRemovingLanguage) return (
        <p className="mt-2">Loading...</p>
    );

    return (
        <ChipContainer className="pt-6">
            {language
                .sort((a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()))
                .map(language => (
                    <Chip
                        key={`talent-language-${language.languageId}`}
                        label={`${language.languageName} (${language.languageProficiencyName})`}
                        onDelete={() => removeTalentLanguage(language.languageId)}
                    />
                ))
            }
        </ChipContainer>
    );
};

export default TalentLanguageChips;