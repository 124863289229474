import { useState } from "react";
import Button from "../../components/Button";
import DialogBox, { useDialogBoxState } from "../../components/DialogBox";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import Typography from "../../components/Typography";
import { ApiDeclineGigInviteDto, useTalentGigInvites } from "../../api/talentGigInvites";
import { errorToast, successToast } from "../../toast";
import { FieldValues, useForm } from "react-hook-form";
import { useAppPaths } from "../../Routes";
import { useNavigate } from "react-router-dom";
import { GigDetails } from "../../models/app/gig";
import { gigInviteStatuses } from "../../models/app/gigInvite";

export type TalentInviteActionsProps = {
    gig: GigDetails
}

const TalentInviteActions = ({
    gig
}: TalentInviteActionsProps) => {
    
    const {
        gigInvites,
        acceptGigInvite,
        declineGigInvite,
        isAcceptingInvite,
        isDecliningInvite
    } = useTalentGigInvites();

    const { register, handleSubmit } = useForm();
    const appPaths = useAppPaths();
    const navigate = useNavigate();

    const declineDialogState = useDialogBoxState();
    const acceptDialogState = useDialogBoxState();
    const [isAccepting, setIsAccepting] = useState(false);
    const [isDeclining, setIsDeclining] = useState(false);

    const invite = gigInvites.find(invite => invite.gigId == gig.id);

    const handleAccept = async () => {
        if (!invite?.gigInviteId) {
            return;
        }

        setIsAccepting(true);
        try {
            const response = await acceptGigInvite(invite?.gigInviteId);
            if (response.success) {
                successToast("Invite successfully accepted.");
                navigate(appPaths.gigs.ongoing);
            }
        } catch (error) {
            errorToast(`Error accepting invite: ${error}`);
            console.error("Error accepting invite:", error);
        } finally {
            setIsAccepting(false);
            acceptDialogState.close();
        }
    };

    const handleDecline = handleSubmit(async (data: FieldValues) => {
        if (!invite?.gigInviteId) {
            return;
        }

        setIsDeclining(true);
        try {
            const response = await declineGigInvite(invite?.gigInviteId, data as ApiDeclineGigInviteDto);
            if (response.success) {
                declineDialogState.close();
                successToast("Invite successfully declined.");
            }
        } catch (error) {
            errorToast(`Error declining invite: ${error}`);
            console.error("Error declining invite:", error);
        } finally {
            setIsDeclining(false);
        }
    });

    return (
        <>
            {invite && invite.inviteStatusId === gigInviteStatuses.sent && (
                <div className="flex items-center sm:justify-end sm:space-x-4 flex-col-reverse sm:flex-row gap-4 sm:gap-0">
                    <Button type="button" className="w-full sm:w-28 mx-2 sm:mx-0" variant="secondary" onClick={declineDialogState.open} loading={isDeclining} disabled={isAccepting}>Decline</Button>
                    <Button type="button" className="w-full sm:w-28 mr-auto" onClick={acceptDialogState.open} loading={isAccepting} disabled={isDeclining}>Accept</Button>
                </div>
            )}
            <DialogBox
                {...declineDialogState}
                title={"Decline Invitation"}
            >
                <Typography variant="body" component="p">Please provide a reason for declining this invitation.</Typography>
                <form onSubmit={handleDecline}>
                    <FormTextAreaInput
                        id="decline-reason"
                        placeholder="Enter your reason for declining here..."
                        register={register("reason", { required: "A reason is required." })}
                        disabled={isDeclining}
                    />
                    <div className="mt-4 flex flex-col sm:flex-row-reverse space-y-4 sm:space-y-0 sm:gap-6">
                        <Button type="submit" className="w-full mt-4 sm:mt-0" loading={isDeclining || isDecliningInvite}>
                            Decline Invitation
                        </Button>
                        <Button type="button" className="w-full" variant="secondary" onClick={declineDialogState.close}>
                            Cancel
                        </Button>
                    </div>
                </form>
            </DialogBox>
            <DialogBox
                {...acceptDialogState}
                title={"Accept Invitation"}
            >
                <Typography variant="body" component="p">Are you sure you want to accept this invitation?</Typography>
                <div className="mt-4 flex flex-col sm:flex-row-reverse space-y-4 sm:space-y-0 sm:gap-6">
                    <Button type="button" onClick={handleAccept} className="w-full mt-4 sm:mt-0" loading={isAccepting || isAcceptingInvite}>
                        Accept Invitation
                    </Button>
                    <Button type="button" className="w-full" variant="secondary" onClick={acceptDialogState.close}>
                        Cancel
                    </Button>
                </div>
            </DialogBox>
        </>
    );
};

export default TalentInviteActions;