import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { TalentListSkillsDto, TalentSkillDto } from "../models/app/talentSkill";

const useAddTalentSkill = () => useAuthenticatedGigApiFetcher<never, void, string>("POST",
    (skillId: string) => ({
        url: `api/me/skills/${skillId}`
    }));

const useRemoveTalentSkill = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (skillId: string) => ({
        url: `api/me/skills/${skillId}`,
    }));

export type UseTalentSkillsReturn =
    UseGiggedApiSWRReturn<TalentListSkillsDto> &
    {
        talentSkills: TalentSkillDto[]
        refetchTalentSkills: () => void
        addTalentSkill: (skillId: string) => Promise<GigApiFetcherResponse<void>>
        removeTalentSkill: (skillId: string) => Promise<GigApiFetcherResponse<void>>
        isAddingTalentSkill: boolean
        isRemovingTalentSkill: boolean
    }

export const useTalentSkills = (): UseTalentSkillsReturn => {
    const apiCall = useGiggedApiSWR<TalentListSkillsDto>("api/me/skills");
    const [addSkill, isAddingTalentSkill] = useAddTalentSkill();
    const [removeSkill, isRemovingTalentSkill] = useRemoveTalentSkill();

    return {
        ...apiCall,
        talentSkills: apiCall.data?.skills || [],
        refetchTalentSkills: apiCall.refetch,
        addTalentSkill: async (skillId: string) => {
            const response = await addSkill(skillId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        removeTalentSkill: async (skillId: string) => {
            const response = await removeSkill(skillId);

            if (response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isAddingTalentSkill,
        isRemovingTalentSkill,
    };
};