import { CircularProgress, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import MatchingProgressStep from "./MatchingProgressStep";
import Typography from "../../components/Typography";
import { getRandomInt } from "../../utils/getRandomInt";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";

const MatchingProgressIndictator = () => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const { gigTerminology, talentTerminology, talentTerminologyPlural } = useOrganizationContext();
    const [isDelayed, setIsDelayed] = useState(false);
    const [steps] = useState(["Initialising AI matching algorithm", `Performing ${gigTerminology.toLowerCase()} analysis`, "Initiating AI assessment", `Calculating ${talentTerminology.toLowerCase()} scores`, `Matching ${talentTerminologyPlural.toLowerCase()} to ${gigTerminology.toLowerCase()}`, "Finalising matches"]);
    const extraStep = ["Almost done"];
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setIsDelayed(true);
        }, 25000);
        setInterval(() => {
            setProgress((progress) => {
                const diff = Math.random() * 5;
                return Math.min(progress + diff, 97);
            });
        }, 500);
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        const timer = setTimeout(() => {
            if (activeStep < steps.length - 1) {
                setActiveStep((prev) => prev + 1);
            } else {
                clearInterval(interval);
            }
        }, getRandomInt(2500, 4000));

        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        };
    }, [activeStep]);



    return (
        <div className="space-y-4 flex flex-col items-center">
            <CircularProgress className="mx-auto block" />
            <Typography className="mt-4 text-center" variant="title-medium" component="p">Your gig matches are now being generated</Typography>
            <Typography className="mt-4 text-center" variant="body" component="p">This usually takes less than 30 seconds to complete.</Typography>
            <div className="flex flex-col">
                {(isDelayed ? extraStep : steps).map((step, index) => (
                    <MatchingProgressStep
                        key={index}
                        title={step}
                        isActive={isDelayed ? true : index === activeStep}
                    />
                ))}
            </div>
            <div className="md:w-1/2 w-3/4 mt-8">
                <LinearProgress color="primary" variant="determinate" value={progress} />
            </div>
        </div>
    );
};

export default MatchingProgressIndictator;