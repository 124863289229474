import { CheckCircle, Info } from "phosphor-react";
import Tooltip from "./Tooltip";
import { useIsSmallScreen } from "../hooks/screenSizes";

export type GigMatchedItemProps = {
    itemType: string;
    itemValue?: string;
    score: number;
    isExactMatch: boolean;
    explanation: string;
    language?: string;
    proficiency?: string;
    country?: string;
    locality?: string;
};

const GigMatchedItem = ({
    itemType,
    itemValue,
    score,
    isExactMatch,
    explanation,
    language,
    proficiency,
    country,
    locality,
}: GigMatchedItemProps) => {
    const isSmallScreen = useIsSmallScreen();

    const renderItemLabel = () => {
        if (itemType === "language") {
            return `${language} (${proficiency})`;
        } else if (itemType === "location") {
            return `${locality}, ${country}`;
        } else {
            return itemValue;
        }
    };

    return (
        <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
                <label className="block mr-4">{renderItemLabel()}</label>
                {isExactMatch && (
                    !isSmallScreen ? (
                        <CheckCircle className="ml-2 text-primary" weight="fill" size="24" />
                    ) : (
                        <p className="ml-2 bg-primary text-primary-bg-text px-2 rounded-md font-bold">
                            Exact Match
                        </p>
                    )
                )}
            </div>
            <div className="ml-auto flex items-center">
                <label className="block text-right mr-2">
                    Score: {Math.round(score)}%
                </label>
                <Tooltip
                    tooltipText={explanation}
                    icon={<Info size={16} />}
                />
            </div>
        </div>
    );
};

export default GigMatchedItem;