import { useEffect } from "react";
import { useClientPersonalInformation } from "../../api/clientPersonalInformation";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import Card from "../../components/Card";
import FormAccordion from "../../components/FormAccordion";
import InfoBox from "../../components/InfoBox";
import PageContent from "../../components/PageContent";
import AddedExtras from "../../components/AddedExtras";
import Brochures from "./Brochures";
import ClientPersonalDetailsForm from "./ClientPersonalDetailsForm";


const ClientProfileEditPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const {
        giggedClientTerminology,
        organizationConfig: { hideGiggedClientCompanyInfo },
    } = useOrganizationContext();
    const { clientPersonalInformation } = useClientPersonalInformation();

    const showCompanyInfo = !hideGiggedClientCompanyInfo;

    return (
        <PageContent className="child:mx-0">
            {clientPersonalInformation && !clientPersonalInformation?.isProfileComplete && (
                <InfoBox className="mb-4" variant="info">
                    <p>Please complete these details in your profile before you go any further:
                        <ul className="list-disc ml-8">
                            {showCompanyInfo && <li>Company name</li>}
                            {!showCompanyInfo && <li>{giggedClientTerminology} name</li>}
                            <li>{hideGiggedClientCompanyInfo ? `${giggedClientTerminology} overview` : "Company overview"}</li>
                            {showCompanyInfo && <li>Location</li>}
                        </ul>
                    </p>
                </InfoBox>
            )}
            <Card className="rounded-none sm:rounded-lg">
                <FormAccordion
                    steps={[
                        {
                            title: `${giggedClientTerminology} details`,
                            content: (
                                <ClientPersonalDetailsForm />
                            )
                        },
                        {
                            title: "External links",
                            content: (
                                <AddedExtras />
                            ),
                        },
                        {
                            title: "Brochures",
                            content: (
                                <Brochures />
                            ),
                        },
                    ]}
                />
            </Card>
        </PageContent>
    );
};

export default ClientProfileEditPage;