import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";


export type TalentLanguageFormValues = {
    languageId: string;
    languageProficiencyId: number;
};

export const validationSchemaFields = {
    languageId: yup.string().required("Language is required."),
    languageProficiencyId: yup.number().required("Language proficiency is required.")
};

const schema = yup.object(validationSchemaFields);

export const useTalentLanguageForm = () => {
    return useForm<TalentLanguageFormValues>({
        resolver: yupResolver(schema)
    });
};