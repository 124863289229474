import { useAuthenticatedGigApiFetcher }  from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { Event } from "../models/api/nylas";

export const useFetchNylasAuthUrl = (redirectUri: string, shouldFetch: boolean) => {
    const { data, error } = useGiggedApiSWR<string>(
        shouldFetch ? `api/nylas/auth?redirectUri=${redirectUri}` : null
    );
    return { authUrl: data, error };
};

export const useExchangeCodeForGrant = () => {
    return useAuthenticatedGigApiFetcher<void, void, { redirectUri: string; code: string }>(
        "GET",
        ({ redirectUri, code }) => {
            const url = `api/nylas/exchange?redirectUri=${encodeURIComponent(redirectUri)}&code=${code}`;
            return { url };
        }
    );
};

export const useFetchEvents = (talentId: string, start?: number, end?: number) => {
    const url = `api/nylas/events?talentId=${talentId}${start ? `&start=${start}` : ""}${end ? `&end=${end}` : ""}`;
    return useGiggedApiSWR<Event[]>(url);
};

export const useFetchMyEvents = (start?: number, end?: number) => {
    const url = `api/nylas/events/me${start ? `?start=${start}` : ""}${end ? `&end=${end}` : ""}`;
    return useGiggedApiSWR<Event[]>(url);
};
