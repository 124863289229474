import clsx from "clsx";
import { X } from "phosphor-react";
import { useIsSmallScreen } from "../../hooks/screenSizes";
import Typography from "../Typography";

type DialogBoxProps = {
    title?: string
    isOpen: boolean
    onClose: () => void
    children?: React.ReactNode
    maxWidth?: string
    minWidth?: string
    showCloseButton?: boolean;
}

export const dialogWidths = {
    "extra-small": "200px",
    "small": "350px", 
    "medium": "500px",
    "large": "800px"
};

const DialogBox = ({
    title,
    isOpen,
    onClose,
    children,
    maxWidth,
    minWidth,
    showCloseButton = true
}: DialogBoxProps) => {
    const isSmallScreen = useIsSmallScreen();
    return (
        <>
            <div
                id="dialog-box-overlay"
                className={clsx(
                    "pointer-events-auto fixed inset-0 bg-[#aaa] bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-[1015]",
                    isOpen || "hidden"
                )}
            >
                <div 
                    className="p-6 shadow-lg rounded-lg bg-white space-y-8 z-[1010] max-h-[70vh] overflow-y-auto"
                    style={ isSmallScreen ? {
                        minWidth: minWidth || "fit",
                        maxWidth: maxWidth || "75%"
                    } : {
                        width: "90%",
                    }}
                >
                    <div className="flex items-center">
                        <Typography className="mr-6 text-[1rem] font-bold" variant="headline-small" component="h1">{title}</Typography>
                        {showCloseButton && (
                            <div className="flex ml-auto self-start">
                                <X size={24} className="ml-auto cursor-pointer" onClick={onClose} />
                            </div>
                        )}
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default DialogBox;