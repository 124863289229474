import Button from "../components/Button";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { useNavigate } from "react-router-dom";

export const FourOFourPage = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-2);
    };

    return (
        <PageContent>
            <Typography variant="display-large" component="h1" gutterBottom className="text-center text-primary font-bold md:text-[12rem] mt-8">404</Typography>
            <Typography variant="title-large" component="p" gutterBottom className="text-center md:text-title-large text-title-small">Sorry we couldn't find what you are looking for.</Typography>
            <div className="text-center mt-8">
                <Button variant="primary" onClick={handleGoBack}>
                Go Back
                </Button>
            </div>
        </PageContent>
    );
};
