import { AuditDto } from "../../models/app/audit";
import { formatDate } from "../../utils/dateFormatters";

export type AuditDataListItemProps = {
    label: string
    auditData?: AuditDto
}
export const AuditDataListItem = ({
    label, 
    auditData
}: AuditDataListItemProps) => {
    if (!auditData) return null;

    return (
        <div>
            <strong>{label}:</strong> {auditData.name} {auditData.date ? `on ${formatDate(new Date(auditData.date))}` : ""}
        </div>
    );
};

export default AuditDataListItem;