import * as yup from "yup";
import { isValidOptionalUrl } from "../../utils/yup-validators/isValidOptionalUrl";

const linkedinRegexRule = /^(https?:\/\/)?(www\.)?linkedin\.com/i;

export const buildNullableUrlValidator = () => yup.string()
    .max(2048)
    .test(isValidOptionalUrl)
    .nullable();

export const linkedinURLValidator = () =>
    yup
        .string()
        .max(2048)
        .test(
            "is-linkedin-url",
            "Must be a valid LinkedIn URL.",
            (value) => {
                if (!value) return true;
                return linkedinRegexRule.test(value);
            }
        )
        .nullable();
