import { Message, Participant } from "@twilio/conversations";

export type SetUnreadMessagesType = (
    channelSid: string,
    unreadCount: number
) => void;

export type AddMessagesType = (channelSid: string, messages: Message[]) => void;

export type SetParticipantsType = (
    participants: Participant[],
    sid: string
) => void;

export type SetSidType = (sid: string) => void;

export const messageTypes = {
    GigInvite: "Gig Invite",
    GigCompleted: "Gig Completed",
    GigCancelled: "Gig Cancelled",
    GigPostedNotification: "Gig Posted Notification",
    DisputeCreated: "Dispute Created",
    GigOnHold: "Gig On Hold",
    InviteAccepted: "Invite Accepted",
    InviteDeclined: "Invite Declined",
    BookingConfirmed: "Booking Confirmed",
    GigConfirmed: "Gig Confirmed",
    GigChanges: "Gig Changes",
    GigCustomisationRequirementChanges: "Gig Customisation Requirement Changes",
};