import { ClientGigInvitesDto } from "../models/api/client";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export type UseClientGigInvitesDtoReturn = {
    isLoading: boolean
    gigInvites: ClientGigInvitesDto[]
}

export const useClientGigInvites = (gigId: string): UseClientGigInvitesDtoReturn => {
    const url = `api/me/gigs/${gigId}/invites`;
    const apiCall = useGiggedApiSWR<ClientGigInvitesDto[]>(url);

    return {
        gigInvites: apiCall.data || [],
        isLoading: apiCall.isLoading || apiCall.isValidating,
    };
};