import { useState } from "react";
import { useTalents } from "../../api/talents";
import { silentlyReplaceSearchParams } from "../../utils/searchParams";
import { FindTalentContext } from "./findTalentContext";

export type FindTalentContextProviderProps = {
    children: React.ReactNode
}

const FindTalentContextProvider = ({
    children,
}: FindTalentContextProviderProps) => {
    const {
        talents,
        isLoading,
        sortOrder,
        orderBy,
        searchTerm: searchTermQueryParam,
        skillIds: skillIdsQueryParam,
        locationIds: locationIdsQueryParam,
        languageIds: languageIdsQueryParam,
        industrialExperienceIds: industrialExperienceIdsQueryParam,
        setSearchTerm: setUseTalentsSearchTerm,
        setSkillIds: setUseTalentsSkillIds,
        setLocationIds: setUseTalentsLocationIds,
        setLanguageIds: setUseTalentsLanguageIds,
        setIndustrialExperienceIds: setUseTalentsIndustrialExperienceIds,
        setSortOrder,
        setOrderBy,
    } = useTalents({ pageSize: 100 });

    const [searchTerm, setSearchTerm] = useState(searchTermQueryParam);
    const [skillIds, setSkillIds] = useState<string[]>(skillIdsQueryParam);
    const [locationIds, setLocationIds] = useState<string[]>(locationIdsQueryParam);
    const [languageIds, setLanguageIds] = useState<string[]>(languageIdsQueryParam);
    const [industrialExperienceIds, setIndustrialExperienceIds] = useState<string[]>(industrialExperienceIdsQueryParam);

    const anyActiveFilters =
        searchTerm.length > 0 ||
        skillIds.length > 0 ||
        locationIds.length > 0 ||
        industrialExperienceIds.length > 0 ||
        languageIds.length > 0;

    const clearAllFilters = () => {
        setSearchTerm("");
        setSkillIds([]);
        setLocationIds([]);
        setLanguageIds([]);
        setIndustrialExperienceIds([]);
        setUseTalentsSearchTerm("");
        setUseTalentsSkillIds([]);
        setUseTalentsLocationIds([]);
        setUseTalentsLanguageIds([]);
        setUseTalentsIndustrialExperienceIds([]);
        silentlyReplaceSearchParams("");
    };

    const searchNow = () => {
        setUseTalentsSearchTerm(searchTerm);
        setUseTalentsSkillIds(skillIds);
        setUseTalentsLocationIds(locationIds);
        setUseTalentsIndustrialExperienceIds(industrialExperienceIds);
        setUseTalentsLanguageIds(languageIds);
    };

    return (
        <FindTalentContext.Provider value={{
            talents,
            isLoading,
            searchTerm,
            skillIds,
            locationIds,
            languageIds,
            industrialExperienceIds,
            sortOrder,
            orderBy,
            anyActiveFilters,
            setSearchTerm,
            setSkillIds,
            setLocationIds,
            setLanguageIds,
            setIndustrialExperienceIds,
            setSortOrder,
            setOrderBy,
            clearAllFilters,
            searchNow,
        }}>
            {children}
        </FindTalentContext.Provider>
    );
};

export default FindTalentContextProvider;
