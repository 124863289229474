import clsx from "clsx";
import Card from "../../components/Card";
import Typography from "../../components/Typography";
import { TalentDetails } from "../../models/app/talent";
import TalentProfileVideo from "../TalentProfileEdit/TalentProfileVideo";
import Certification from "./Certification";

export type TalentProfileDetailsCardProps = {
    talent: TalentDetails
    className?: string
}

const TalentProfileDetailsCard = ({
    talent,
    className,
}: TalentProfileDetailsCardProps) => {

    if (
        talent.certifications.length === 0 &&
        talent.profileVideoUrls.length === 0
    ) {
        return <div className={className} />;
    }
    
    return (
        <Card className={clsx("space-y-8", className)}>
            {talent.certifications.length > 0 && (
                <div>
                    <Typography variant="title-large" component="h2" underline className="mb-6">Awards &amp; certifications</Typography>
                    <div>
                        {talent.certifications.map(certification => (
                            <div key={certification.id}>
                                <Certification certification={certification} />
                                <div className="border-b border-surface my-4" />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {talent.profileVideoUrls.length > 0 && (
                <div>
                    <Typography variant="title-large" component="h2" underline className="mb-6">Videos</Typography>
                    <div className="flex flex-row flex-wrap gap-2">
                        {talent.profileVideoUrls.map(url => (
                            <TalentProfileVideo key={url} url={url} />
                        ))}
                    </div>
                </div>
            )}
        </Card>
    );
};

export default TalentProfileDetailsCard;