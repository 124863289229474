import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { GigOrderByFieldKeys, GigSummary } from "../models/app/gig";
import { ApiGigSummary } from "../models/api/gig";
import { mapFromApiGigSummary } from "../models/mappers/gig";

export type UseTalentMyOngoingGigsReturn = {
    gigs: GigSummary[]
    isLoading: boolean
    mutate: () => void
}


export const useTalentOngoingGigs = (gigStatusIds: number[], orderBy: GigOrderByFieldKeys = "Title", sortOrder = "asc"): UseTalentMyOngoingGigsReturn => {
    const statusIdsParam = gigStatusIds.join(",");
    
    const url = "api/me/gigs/queries/hired?" +
        `gigStatusIds=${statusIdsParam}` +
        `&orderBy=${orderBy}` +
        `&sortOrder=${sortOrder}`;

    const { data, isLoading, mutate } =
        useGiggedApiSWR<ApiGigSummary[]>(url);

    const gigs = data ? data.map(mapFromApiGigSummary) : [];

    return {
        isLoading,
        gigs,
        mutate,
    };
};