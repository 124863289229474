import { UserGetMeDto } from "../models/app/userMe";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export const useUserGetMe = () => {
    const url = "api/me";

    const apiCall = useGiggedApiSWR<UserGetMeDto>(url);

    return {
        talentId: apiCall.data?.talentId,
        clientId: apiCall.data?.giggedClientId,
        giggedClientIsVerified: apiCall.data?.giggedClientIsVerified,
        loading: apiCall.isLoading
    };
};