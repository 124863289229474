import { useState } from "react";
import { Controller } from "react-hook-form";

import Button from "../../components/Button";
import FormDropdown from "../../components/FormDropdown";
import ErrorList from "../../components/ErrorList";
import { TalentDeliveryTypeFormValues, useTalentDeliveryTypeForm } from "./TalentDeliveryTypeFormValues";
import { deliveryTypeOptions } from "../../models/app/gig";
import { useTalentDeliveryType } from "../../api/talentDeliveryTypes";
import TalentDeliveryTypeChips from "./TalentDeliveryTypeChips";
import { successToast } from "../../toast";

const TalentDeliveryTypeForm = () => {
    const [errors, setErrors] = useState<string[]>([]);
    const methods = useTalentDeliveryTypeForm();

    const { 
        addTalentDeliveryType, 
        isAddingTalentDeliveryType, 
        removeTalentDeliveryType, 
        isRemovingTalentDeliveryType, 
        talentDeliveryTypes
    } = useTalentDeliveryType();

    const handleSubmit = async (values: TalentDeliveryTypeFormValues) => {
        setErrors([]);

        const existingDeliveryType = talentDeliveryTypes.find(deliveryType => deliveryType.deliveryTypeId === values.deliveryTypeId);

        if (existingDeliveryType) {
            setErrors([`Delivery type "${existingDeliveryType.deliveryTypeName}" already added.`]);
            methods.reset();
            return;
        }

        const response = await addTalentDeliveryType(values.deliveryTypeId);

        if (!response.success) {
            setErrors(response.error.userMessages);
        } else {
            successToast("Delivery type successfully added!");
            methods.reset();
            return;
        }
    };

    return (
        <div>
            <form
                noValidate
                className="flex gap-6 flex-wrap md:flex-nowrap md:items-start"
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <div className="flex gap-6 flex-wrap w-full md:flex-nowrap">
                    <div className="w-full">
                        <Controller
                            name="deliveryTypeId"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormDropdown
                                    required
                                    label="Select delivery type"
                                    options={deliveryTypeOptions}
                                    error={methods.formState.errors.deliveryTypeId}
                                    value={value}
                                    onChange={onChange}
                                    disabled={isAddingTalentDeliveryType}
                                />
                            )}
                        />
                    </div>
                </div>
                <Button
                    className="w-full md:mt-auto md:w-auto"
                    type="submit"
                    variant="secondary"
                    loading={isAddingTalentDeliveryType}
                >
                    Add
                </Button>
            </form>

            <ErrorList errors={errors} />
            
            <TalentDeliveryTypeChips 
                deliveryTypes={talentDeliveryTypes} 
                removeTalentDeliveryType={removeTalentDeliveryType} 
                isRemovingDeliveryType={isRemovingTalentDeliveryType} />
        </div>
    );
};

export default TalentDeliveryTypeForm;