import { ApiGigInviteDto } from "../models/api/gigInvite";
import { GigInviteDto } from "../models/app/gigInvite";
import { mapFromApiGigInvite } from "../models/mappers/gigInvite";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";

export type ApiDeclineGigInviteDto = {
    reason: string;
}

export type UseTalentGigInvitesReturn = {
    gigInvites: GigInviteDto[];
    isLoading: boolean;
    acceptGigInvite: (inviteId: string) => Promise<GigApiFetcherResponse<void>>;
    acceptGigChanges: (inviteId: string) => Promise<GigApiFetcherResponse<void>>;
    declineGigInvite: (inviteId: string, dto: ApiDeclineGigInviteDto) => Promise<GigApiFetcherResponse<void>>;
    declineGigChanges: (inviteId: string) => Promise<GigApiFetcherResponse<void>>;
    isAcceptingInvite: boolean;
    isDecliningInvite: boolean;
    isAcceptingChanges: boolean;
    isDecliningChanges: boolean;
};

export const useTalentGigInvites = (): UseTalentGigInvitesReturn => {
    const url = "api/me/gig-invites";
    const { data: gigInvitesData, isLoading, mutate } = useGiggedApiSWR<ApiGigInviteDto[]>(url);

    const [acceptGigInvite, isAcceptingInvite] = useAuthenticatedGigApiFetcher<void, void, { inviteId: string }>("PUT", ({ inviteId }) => ({
        url: `api/me/gigs/invites/${inviteId}/actions/accept`
    }));
    const [declineGigInvite, isDecliningInvite] = useAuthenticatedGigApiFetcher<ApiDeclineGigInviteDto, void, { inviteId: string, dto: ApiDeclineGigInviteDto }>("PUT", ({ inviteId, dto }) => ({
        url: `api/me/gigs/invites/${inviteId}/actions/decline`,
        body: dto
    }));
    const [acceptGigChanges, isAcceptingChanges] = useAuthenticatedGigApiFetcher<void, void, { inviteId: string }>("PUT", ({ inviteId }) => ({
        url: `api/me/gigs/invites/${inviteId}/actions/accept-changes`
    }));
    const [declineGigChanges, isDecliningChanges] = useAuthenticatedGigApiFetcher<void, void, { inviteId: string }>("PUT", ({ inviteId }) => ({
        url: `api/me/gigs/invites/${inviteId}/actions/decline-changes`
    }));

    const gigInvites = gigInvitesData ? gigInvitesData.map(mapFromApiGigInvite) : [];

    return {
        gigInvites,
        isLoading,
        acceptGigInvite: async (inviteId: string) => {
            const response = await acceptGigInvite({ inviteId });
            if (response.success) {
                await mutate();
            }
            return response;
        },
        acceptGigChanges: async (inviteId: string) => {
            const response = await acceptGigChanges({ inviteId });
            if (response.success) {
                await mutate();
            }
            return response;
        },
        declineGigInvite: async (inviteId: string, dto: ApiDeclineGigInviteDto) => {
            const response = await declineGigInvite({ inviteId, dto });
            if (response.success) {
                await mutate();
            }
            return response;
        },
        declineGigChanges: async (inviteId: string) => {
            const response = await declineGigChanges({ inviteId });
            if (response.success) {
                await mutate();
            }
            return response;
        },
        isAcceptingInvite,
        isDecliningInvite,
        isAcceptingChanges,
        isDecliningChanges
    };
};
