import { useDialogBoxState } from "../../components/DialogBox";
import { successToast } from "../../toast";
import FormInputLabel from "../../components/FormInputLabel";
import { useTalentCertifications } from "../../api/talentCertifications";

import HistoryListAddItemButton from "./HistoryListAddItemButton";
import { CertificationFormValues } from "./CertificationFormValues";
import CertificationCard from "./CertificationCard";
import { CertificationFormDialog } from "./CertificationFormDialog";

export const Certifications = () => {
    const {
        certifications,
        addCertification,
        updateCertification,
        removeCertification,
    } = useTalentCertifications();

    const createCourseDialogState = useDialogBoxState();

    const handleAddCourse = async (values: CertificationFormValues) => {
        if (values.achievementDate === null) throw new Error("Expected achievement date not to be null.");

        const response = await addCertification({
            ...values,
            achievementDate: values.achievementDate as Date,
        });

        if (response.success) {
            successToast("Certification successfully added.");
            createCourseDialogState.close();
        }

        return response;
    };

    const makeUpdateCertificationHandler = (id: string) => async (values: CertificationFormValues) => {
        if (values.achievementDate === null) throw new Error("Expected achievement date not to be null.");

        const response = await updateCertification(id, {
            ...values,
            achievementDate: values.achievementDate as Date,
        });

        if (response.success) {
            successToast("Certification successfully updated.");
        }

        return response;
    };

    const makeRemoveCertificationHandler = (id: string) => async () => {
        const response = await removeCertification(id);

        if (response.success) {
            successToast("Certification successfully removed.");
        }

        return response;
    };

    return (
        <>
            <div>
                <FormInputLabel className="ml-0 mb-4">Awards / certifications</FormInputLabel>
                <div className="space-y-4">
                    <HistoryListAddItemButton onClick={createCourseDialogState.open}>Add certification</HistoryListAddItemButton>
                    {certifications.map(certification => (
                        <CertificationCard
                            key={certification.id}
                            certification={certification}
                            onRemove={makeRemoveCertificationHandler(certification.id)}
                            updateJob={makeUpdateCertificationHandler(certification.id)}
                        />
                    ))}
                </div>
            </div>
            <CertificationFormDialog
                {...createCourseDialogState}
                initialValues={{
                    title: "",
                    achievementDate: null,
                }}
                resetOnSubmit
                onSubmit={handleAddCourse}
            />
        </>
    );
};

export default Certifications;