import {
    autoUpdate, flip, offset, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole
} from "@floating-ui/react-dom-interactions";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { Question, Info } from "phosphor-react";
import React, { useState } from "react";

export type TooltipProps = {
    tooltipText: string
    className?: string
    children?: React.ReactNode
    icon?: React.ReactNode
    dateDisplay?: React.ReactNode
}

export const Tooltip = ({
    tooltipText,
    className,
    children,
    dateDisplay,
    icon = dateDisplay ? <Info size={16} /> : <Question size={18} />,
}: TooltipProps) => {
    const [open, setOpen] = useState(false);

    const { x, y, reference, floating, strategy, context } = useFloating({
        placement: "top",
        open,
        onOpenChange: setOpen,
        middleware: [
            offset(15),
            flip(),
            shift({ padding: 8 })],
        whileElementsMounted: autoUpdate
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, { restMs: 40 }),
        useFocus(context),
        useRole(context, { role: "tooltip" }),
        useDismiss(context)
    ]);

    return (
        <>
            {children ? (
                <div className={clsx("cursor-pointer", className)} ref={reference} {...getReferenceProps()}>{children}</div>
            ) : (
                <div className={clsx("cursor-pointer", className)} ref={reference} {...getReferenceProps()}>{icon}</div>
            )}
            <AnimatePresence>
                {open && (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.85 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: "spring", damping: 20, stiffness: 400 }}
                        ref={floating}
                        className="bg-white text-black pointer-events-none rounded-md px-6 py-6 w-96 shadow-xl font-normal whitespace-normal z-50"
                        style={{
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0
                        }}
                        {...getFloatingProps()}
                    >
                        {dateDisplay ? dateDisplay : tooltipText}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default Tooltip;