export type GigMatchedSkillProps = {
    skillName: string
}

const TalentSkill = ({
    skillName,
}: GigMatchedSkillProps) => {

    return(
        <div>
            <div className="flex items-center justify-between mb-2">
                <label className="block mr-4">{skillName}</label>
            </div>
        </div>
    );
};

export default TalentSkill;
