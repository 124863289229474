import pluralizeString from "./utils/pluralizeString";

export const talentProfileEditTooltips = {
    aboutMe: "Use this section to give a brief overview of your skills, experience, and current availability.",
    industrialExperience: (giggedClientTerminologyPlural: string) => `Some of our ${giggedClientTerminologyPlural.toLowerCase()} will be looking for specific industry experience. Add yours here to make sure you stand out from the crowd.`,
    addSkills: (gigTerminology: string) => `Make sure to add all the Insights workshop types you have relevant to your experience. This will increase your chances of being matched to a ${gigTerminology.toLowerCase()} by our super-smart algorithm!`,
};

export const clientProfileEditTooltips = {
    displayNameItm: (giggedClientTerminology: string) => `This is how this ${giggedClientTerminology.toLowerCase()} profile will appear to other users on the platform.`,
    displayNameOtm: "If you would like to appear to users on the platform as something other than your company name, enter it here. If nothing is entered, company name will be used.",
    companyName: "This is the name your company is known by and will be displayed to other users on the platform.",
    companyTagline: "The tagline/strapline of your company will appear under your company name in your profile. If you don't have one, simply write in a few words what you do or your line of business e.g. Technology Consultancy.",
    companyWebsite: "The website associated with your company.",
    companyOverview: "Describe your company - who you are and what you do. This will be displayed to users on your company profile page.",
    clientOverview: (giggedClientTerminology: string) => `This should be an overview of what this ${giggedClientTerminology.toLowerCase()} does and their role as part of the overall organisation.`,
    profileImage: (giggedClientTerminology: string) => `Add or update the profile image that represents your ${giggedClientTerminology.toLowerCase()}.`,
    phoneNumber: "Add a contact telephone number e.g. 07789 123 456. For non-UK numbers, make sure to include your country code e.g. +1 804 123 4567."
};

export const clientEditGigTooltips = {
    gigName: (gigTerminology: string) => `Use a catchy title - this will help your ${gigTerminology.toLowerCase()} stand out to the right freelancer.`,
    gigStage: (gigTerminology: string) => `Let us know where you are currently at with your ${gigTerminology.toLowerCase()} - has there been any work done on it previously or are you starting from scratch?`,
    gigDuration: (gigTerminology: string) => `Estimate how long your ${gigTerminology.toLowerCase()} will take.`,
    gigSkills: (gigTerminology: string, talentTerminologyPlural: string) => `Make sure to add the top three skills that are required for this ${gigTerminology.toLowerCase()} - this will ensure our matching algorithm matches you with the best ${talentTerminologyPlural.toLowerCase()}.`,
    gigStartDate: (gigTerminology: string) => `Tell us when you need your ${gigTerminology.toLowerCase()} to begin.`,
    gigDeadline: (gigTerminology: string) => `Add a deadline if you have a set date the ${gigTerminology.toLowerCase()} should be completed.`,
    gigDescription: (gigTerminology: string) => `Provide a detailed description of your ${gigTerminology.toLowerCase()}. We recommend including as much information as possible.`,
    gigFiles: (gigTerminology: string) => `Upload any additional files relevant to the ${gigTerminology.toLowerCase()} you are posting for example a job description`,
    complianceCheck: (talentTerminology: string) => `Ensure ${pluralizeString(talentTerminology.toLowerCase())} have undergone a compliance check through our partners at Amiqus before hiring.`,
    gigFilesPublic: (gigTerminology: string, talentTerminology: string) => `If enabled, all attached ${gigTerminology.toLowerCase()} files will be publicly available for ${pluralizeString(talentTerminology.toLowerCase())} to view/download on your ${gigTerminology.toLowerCase()} detail page.`,
    remoteWork: (gigTerminology: string) => `Select whether or not this ${gigTerminology.toLowerCase()} can be completed remotely.`
};

export const calendarAccessTooltips = {
    noAccessToken: "Please sync your calendar in the dashboard to access this feature.",
    matchedCalendar: (talentName: string) => `View ${talentName}'s calendar to check availability.`,
    noAccess: "You do not have access to this person's calendar, or the person you are trying to view has not connected their calendar.",
    notConnected: "This users calendar has not been connected"
};