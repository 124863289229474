import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useClientPersonalInformationContext } from "../../api/clientPersonalInformation";
import ApiError from "../../api/common/apiError";
import { useOrganizationContext } from "../../api/current-organization/organizationContext";
import { fetchLocationOptions } from "../../api/locations";
import useAccessToken from "../../auth/useAccessToken";
import Button from "../../components/Button";
import FormCheckbox from "../../components/FormCheckbox";
import FormDropdown, { FormDropdownOption } from "../../components/FormDropdown";
import FormImageUploadInput, { useFormFileUploadInputState } from "../../components/FormFileUploadInput";
import FormInputLabel from "../../components/FormInputLabel";
import FormTextAreaInput from "../../components/FormTextAreaInput";
import FormTextInput from "../../components/FormTextInput";
import GeneralFormError from "../../components/GeneralFormError";
import { successToast } from "../../toast";
import { clientProfileEditTooltips } from "../../tooltipsContent";
import { ClientPersonalDetailsFormValues, useClientPersonalDetailsForm } from "./ClientPersonalDetailsFormValues";
import { useAppPaths } from "../../Routes";

const ClientPersonalDetailsForm = () => {
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(true);
    const [isClientPersonalInformationLoaded, setIsClientPersonalInformationLoaded] = useState(false);
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);
    const [selectedLocationLocalities, setSelectedLocationLocalities] = useState<FormDropdownOption<string>[]>([]);

    const navigate = useNavigate();
    const appPaths = useAppPaths();

    const accessToken = useAccessToken();
    const { giggedClientTerminology, organizationConfig } = useOrganizationContext();
    const {
        clientPersonalInformation,
        updateClientPersonalInformation,
        isUpdatingClientPersonalInformation,
        isLoading,
        isValidating,
        reload: reloadPersonalInformation,
        setClientProfileCompletionStatus
    } = useClientPersonalInformationContext();

    const clientFormInitialValues = {
        ...clientPersonalInformation,
        locationId: clientPersonalInformation?.location?.countryId || null,
        localityId: clientPersonalInformation?.location?.locality.localityId || null,
        location: undefined,
    };

    const methods = useClientPersonalDetailsForm(
        giggedClientTerminology,
        organizationConfig.hideGiggedClientCompanyInfo,
        clientFormInitialValues
    );
    const { reset, register, formState } = methods;

    const {
        urlOrDataUri: profileImageDataUri,
        loadFile,
        error: profileImageError,
        isDirty: isProfileImageDirty,
        reset: resetImageInput,
        ...fileInputProps
    } = useFormFileUploadInputState({
        maxFileSizeKb: 1000,
        initialUrl: clientPersonalInformation?.profileImageUrl,
    });

    useEffect(() => {
        if (isClientPersonalInformationLoaded) return;

        reset({
            ...clientPersonalInformation,
            locationId: clientPersonalInformation?.location?.countryId || "",
            aboutMe: clientPersonalInformation?.aboutMe || "",
            companyTagline: clientPersonalInformation?.companyTagline || "",
            companyWebsite: clientPersonalInformation?.companyWebsite || "",
            localityId: clientPersonalInformation?.location?.locality.localityId ? clientPersonalInformation.location.locality.localityId : null
        });

        if (clientPersonalInformation) {
            setIsClientPersonalInformationLoaded(true);
        }
    }, [reset, clientPersonalInformation, isClientPersonalInformationLoaded]);

    useEffect(function setLocationOptions() {
        if (accessToken && !organizationConfig.hideGiggedClientCompanyInfo) {
            const loadAllLocations = async () => {
                setIsLoadingLocationOptions(true);
                try {
                    const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                    setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
                }
                finally {
                    setIsLoadingLocationOptions(false);
                }
            };
            loadAllLocations();
        }

    }, [accessToken]);

    const [selectedLocationId, setSelectedLocationId] = useState<string | null>();

    useEffect(function setLocalitiesDropdown() {
        setSelectedLocationId(methods.watch("locationId"));
        const selectedLocation = allLocationOptions.find(location => location.value === selectedLocationId);

        if (selectedLocation && selectedLocation.localities) {
            setSelectedLocationLocalities(selectedLocation.localities);
        } else {
            setSelectedLocationLocalities([]);
        }

    }, [allLocationOptions, methods.watch("locationId"), selectedLocationId]);

    useEffect(function resetLocalityOnLocationChange() {
        if (!isClientPersonalInformationLoaded || clientPersonalInformation?.location?.countryId === selectedLocationId) return;
        methods.setValue("localityId", "");
    }, [selectedLocationId]);

    const handleSaveAndUpdate = async (values: ClientPersonalDetailsFormValues) => {
        const isProfileAlreadyComplete = clientPersonalInformation?.isProfileComplete;

        const response = await updateClientPersonalInformation({
            ...values,
            profileImageDataUri: isProfileImageDirty ? profileImageDataUri : undefined
        });

        if (response.success) {
            resetImageInput();
            successToast(`${giggedClientTerminology} details successfully updated.`);
            reloadPersonalInformation();
            methods.reset(values);
            if (!isProfileAlreadyComplete) {
                setClientProfileCompletionStatus(true);
                navigate(appPaths.dashboard, { replace: true });
            }
            return;
        }

        setSubmissionError(response.error);
    };

    if (isLoading || isValidating) {
        return <p>Loading...</p>;
    }

    const showCompanyInfo = !organizationConfig.hideGiggedClientCompanyInfo;

    return (
        <form onSubmit={methods.handleSubmit(handleSaveAndUpdate)}>
            <div className="space-y-6">
                {showCompanyInfo && (
                    <div className="md:flex md:space-x-6 md:space-y-0">
                        <FormTextInput
                            required
                            label="Company name"
                            placeholder="Enter company name"
                            error={formState.errors.companyName}
                            tooltip={clientProfileEditTooltips.companyName}
                            {...register("companyName")}
                        />
                    </div>
                )}
                <div className="md:flex md:space-x-6 md:space-y-0">
                    <FormTextInput
                        required={!showCompanyInfo}
                        label={!showCompanyInfo ? `${giggedClientTerminology} name` : "Display name"}
                        placeholder={!showCompanyInfo ? `Enter ${giggedClientTerminology.toLowerCase()} name` : "Enter display name"}
                        error={formState.errors.name}
                        tooltip={showCompanyInfo ? clientProfileEditTooltips.displayNameOtm : clientProfileEditTooltips.displayNameItm(giggedClientTerminology)}
                        {...register("name")}
                    />
                </div>
                <FormTextAreaInput
                    required
                    id="client-personal-information-about-me"
                    label={organizationConfig.hideGiggedClientCompanyInfo ? `${organizationConfig.giggedClientTerminology} overview` : "Company overview"}
                    placeholder={`Write an overview about ${organizationConfig.hideGiggedClientCompanyInfo ? `this ${giggedClientTerminology.toLowerCase()}` : "your company"}`}
                    error={formState.errors.aboutMe}
                    tooltip={!organizationConfig.hideGiggedClientCompanyInfo ? clientProfileEditTooltips.companyOverview : clientProfileEditTooltips.clientOverview(giggedClientTerminology)}
                    register={register("aboutMe")}
                />
                {showCompanyInfo && (
                    <div className="space-y-6">
                        <div className="md:flex space-y-6 md:space-x-6 md:space-y-0">
                            {allLocationOptions.length > 0 && (
                                <div className="w-full md:w-1/2">
                                    <Controller
                                        name="locationId"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDropdown
                                                required
                                                label="Country"
                                                value={value || ""}
                                                options={allLocationOptions}
                                                disabled={isValidating || isLoading}
                                                onChange={onChange}
                                                error={methods.formState.errors.locationId}
                                                isLoadingOptions={isLoadingLocationOptions}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            <div className="w-full md:w-1/2">
                                {selectedLocationLocalities && selectedLocationLocalities.length > 0 && (
                                    <Controller
                                        name="localityId"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormDropdown
                                                required
                                                label="Locality"
                                                value={value || ""}
                                                options={selectedLocationLocalities}
                                                disabled={selectedLocationLocalities.length < 1 || isValidating || isLoading}
                                                onChange={onChange}
                                                error={methods.formState.errors.localityId}
                                                isLoadingOptions={isLoadingLocationOptions}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="md:flex md:space-x-6 md:space-y-0">
                            <FormTextInput
                                label="Contact number"
                                type="tel"
                                placeholder="Enter telephone number"
                                error={formState.errors.phoneNumber}
                                tooltip={clientProfileEditTooltips.phoneNumber}
                                {...register("phoneNumber")}
                            />
                        </div>
                        <div className="md:flex md:space-x-6 md:space-y-0">
                            <FormTextInput
                                label="Company tagline"
                                placeholder="Enter company tagline"
                                error={formState.errors.companyTagline}
                                tooltip={clientProfileEditTooltips.companyTagline}
                                {...register("companyTagline")}
                            />
                        </div>
                        <div className="md:flex md:space-x-6 md:space-y-0">
                            <FormTextInput
                                label="Company website"
                                placeholder="Enter company website"
                                error={formState.errors.companyWebsite}
                                tooltip={clientProfileEditTooltips.companyWebsite}
                                {...register("companyWebsite")}
                            />
                        </div>
                    </div>
                )}
                <FormImageUploadInput
                    {...fileInputProps}
                    label="Profile image"
                    helpText="Recommended minimum size: 300x300px"
                    inputId="client-profile-image-upload-button"
                    onLoadFile={loadFile}
                    error={profileImageError}
                    tooltip={clientProfileEditTooltips.profileImage(giggedClientTerminology)}
                    dataUrl={profileImageDataUri}
                    isDirty={isProfileImageDirty}
                    previewImgClassName="object-cover"
                />
            </div>

            <GeneralFormError error={submissionError} className="ml-8" />

            <div className="md:flex mt-8">
                <Button
                    className="md:ml-auto w-full md:w-auto"
                    loading={isUpdatingClientPersonalInformation || isLoading || isValidating}
                    disabled={!formState.isDirty && !isProfileImageDirty || !!profileImageError}
                    type="submit"
                >
                    Save &amp; update
                </Button>
            </div>
        </form >
    );
};

export default ClientPersonalDetailsForm;