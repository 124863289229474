import { format as formatDateFn } from "date-fns"; 
import moment from "moment";
import tz from "moment-timezone"; // Required for adjoining moment usage

export const formatMessageDate = (date: Date, hideCurrentYear?: boolean): string => {
    try {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            console.error("Invalid date input:", date);
            return "Invalid date";
        }

        const isCurrentYear = date.getFullYear() === new Date().getFullYear();
        const formattedDate = formatDateFn(date, "do MMMM yyyy");

        return hideCurrentYear && isCurrentYear ? formattedDate.replace(` ${date.getFullYear()}`, "") : formattedDate;
    } catch (error) {
        console.error("Error formatting date:", error);
        return "Error in date formatting";
    }
};

export const formatTime = (date:Date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();    
    const ampm = hours >= 12 ? "pm" : "am";
  
    hours %= 12;
    hours = hours || 12;    
    const tempMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${hours}:${tempMinutes}${ampm}`;
  
};
//Commenting out as not currently used but may be reassigned or removed in the future
// const nth = function (date: Date): string {
//     if (date.getDate() > 3 && date.getDate() < 21) 
//         return "th";
    
//     switch (date.getDate() % 10) {
//     case 1:
//         return "st";
//     case 2:
//         return "nd";
//     case 3:
//         return "rd";
//     default:
//         return "th";
//     }
// };

export const todayAtMidnight = () => {
    const date = new Date();
    date.setHours(0,0,0,0);

    return date;
};

export const tomorrowAtMidnight = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1); // Add one day to the current date
    date.setHours(0, 0, 0, 0); 

    return date;
};

export const formatDateTime = (date: Date) => {
    return formatDateFn(date, "p, MMMM, dd, yyyy");
};

const getOrdinalSuffix = (day: number): string => {
    if (day % 100 >= 11 && day % 100 <= 13) return "th";
    return (day % 10) === 1 ? "st" : (day % 10) === 2 ? "nd" : (day % 10) === 3 ? "rd" : "th";
};

export const formatDate = (date: Date): string => {
    const formattedDay = `${date.getDate()}${getOrdinalSuffix(
        date.getDate()
    )}`;
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    const formattedMonth = months[date.getMonth()];
    const formattedYear = date.getFullYear();
    let formattedHours = date.getHours();
    const formattedMinutes =
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const ampm = formattedHours >= 12 ? "pm" : "am";
    formattedHours = formattedHours % 12 || 12; // Handle midnight (0) and noon (12)
    const formattedTime = `${formattedHours}:${formattedMinutes}${ampm}`;

    return `${formattedDay} ${formattedMonth}, ${formattedYear} at ${formattedTime}`;
};

/**
 * Formats a date to a time string in a specified timezone.
 * @param {Date | string} date - The date to format
 * @param {string} timezone - The timezone to use for formatting (e.g., 'America/New_York')
 * @returns {string} Formatted time string (e.g., "3:30pm")
 */
export const formatLocationTimeToString = (date: Date | string, timezone: string): string => {
    return moment(date).tz(timezone).format("h:mma");
};

/**
 * Formats a date to a date string in a specified timezone.
 * @param {Date | string} date - The date to format
 * @param {string} timezone - The timezone to use for formatting (e.g., 'America/New_York')
 * @returns {string} Formatted date string (e.g., "Jun 15th 2023")
 */
export const formatLocationDateToString = (date: Date | string, timezone: string): string => {
    return moment(date).tz(timezone).format("MMM Do YYYY");
};

/**
 * Formats a date to a date and time string in a specified timezone.
 * @param {Date | string} date - The date to format
 * @param {string} timezone - The timezone to use for formatting (e.g., 'America/New_York')
 * @returns {string} Formatted date and time string (e.g., "Jun 15th 2023 3:30pm")
 */
export const formatLocationDateTimeToString = (date: Date | string, timezone: string): string => {
    return moment(date).tz(timezone).format("MMM Do YYYY h:mma");
};

/**
 * Formats a date to a full date and time string with timezone information.
 * @param {Date | string} date - The date to format
 * @param {string} timezone - The timezone to use for formatting (e.g., 'America/New_York')
 * @returns {string} Formatted full date and time string (e.g., "Thu Jun 15 2023 15:30:00 GMT-0400 (EDT)")
 */
export const formatLocationDateTimeToFullString = (date: Date | string, timezone: string): string => {
    const dateObject = moment(date).tz(timezone).toDate();
    return moment(dateObject).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
};

/**
 * Converts a UTC date/time to a local time in a specified timezone.
 * 
 * This function is critical for ensuring that event times stored in UTC are displayed
 * in their original local time, regardless of the user's browser timezone. For example,
 * an event starting at 9 AM in New York will be shown as 9 AM even to a user in the UK,
 * not converted to the UK time (e.g., 2 PM).
 * 
 * @param {string | Date} utcDateInput - The UTC date/time to convert. Can be a string (ISO 8601 format) or a Date object.
 * @param {string} timezone - The target timezone to convert to (e.g., 'America/New_York').
 * 
 * @returns {ConversionResult & { get: function }} An object containing:
 *   - localDateTimeString: The converted date/time as an ISO 8601 string without timezone info.
 *   - offset: The timezone offset (e.g., '+05:00').
 *   - zoneName: The abbreviated timezone name (e.g., 'EST').
 *   - dateObject: A JavaScript Date object representing the converted time.
 *   - get: A function to retrieve individual properties of the result.
 * 
 * @example
 * // Convert a UTC time to New York time
 * const result = convertUTCToLocalTime('2023-06-15T13:00:00Z', 'America/New_York');
 * console.log(result.localDateTimeString); // '2023-06-15T09:00:00'
 * console.log(result.get('offset')); // '-04:00'
 */
export const convertUTCToLocalTime = (utcDateInput: string | Date, timezone: string): ConversionResult & {
    get: <K extends keyof ConversionResult>(prop: K) => ConversionResult[K];
} => {
    const utcMoment = moment.utc(utcDateInput); // Parse the UTC date
    const localMoment = utcMoment.tz(timezone); // Convert to the specified timezone
    
    const result: ConversionResult = {
        localDateTimeString: localMoment.format("YYYY-MM-DDTHH:mm:ss"), // ISO format the date and time as needed for DateInput component
        offset: localMoment.format("Z"), //If we need the offset for display purposes
        zoneName: localMoment.format("z"), //If we need the offset for display purposes
        dateObject: localMoment.toDate()
    };
  
    return {
        ...result,
        get: <K extends keyof ConversionResult>(prop: K) => result[prop]
    };
};
type ConversionResult = {
    localDateTimeString: string;
    offset: string;
    zoneName: string;
    dateObject: Date;
};

/**
 * Formats a date to a locale-specific time string.
 * @param {Date | string} date - The date to format
 * @returns {string} Formatted time string (e.g., "3:30pm EDT")
 */
export const formatLocationTimeToLocaleString = (date: Date | string): string => {
    return moment(date).tz(moment.tz.guess()).format("h:mma z");
};

/**
 * Formats a date to a locale-specific date string.
 * @param {Date | string} date - The date to format
 * @returns {string} Formatted date string (e.g., "Jun 15th 2023")
 */
export const formatLocationDateToLocaleString = (date: Date | string): string => {
    return moment(date).tz(moment.tz.guess()).format("MMM Do YYYY");
};

/**
 * Formats a date to a locale-specific date and time string.
 * @param {Date | string} date - The date to format
 * @returns {string} Formatted date and time string (e.g., "Jun 15th 2023, 3:30pm EDT(-0400)")
 */
export const formatLocationDateTimeToLocaleString2 = (date: Date | string): string => {
    return moment(date).tz(moment.tz.guess()).format("MMM Do YYYY, h:mma z(Z)");
};

/**
 * Formats a date to a locale-specific date and time string with full timezone information.
 * 
 * @param {Date | string} date - The date to format
 * @param {string} [timezone] - Optional timezone (defaults to local timezone if not provided)
 * @returns {{ datePart: string, timezonePart: string }} An object containing:
 *   - datePart: Formatted date and time (e.g., "Jun 15th 2023, 3:30pm")
 *   - timezonePart: Full timezone information (e.g., "Eastern Daylight Time (EDT -0400)")
 * 
 * @example
 * const result = formatLocationDateTimeToLocaleString(new Date(), 'America/New_York');
 * console.log(result.datePart); // "Jun 15th 2023, 3:30pm"
 * console.log(result.timezonePart); // "Eastern Daylight Time (EDT -0400)"
 */
export const formatLocationDateTimeToLocaleString = (date: Date | string, timezone?: string): { datePart: string, timezonePart: string} => {
    const momentDate = timezone ? moment(date).tz(timezone) : moment(date).tz(moment.tz.guess());
    const formattedDate = momentDate.format("MMM Do YYYY, h:mma");
    const tzAbbr = momentDate.format("z");
    const tzOffset = momentDate.format("Z");
    const tzFullName = getLongTimezoneName(momentDate.toDate(), momentDate.tz());

    return {
        datePart: formattedDate,
        timezonePart: `${tzFullName} (${tzAbbr} ${tzOffset})`
    };
};

/**
 * Retrieves the long name of a timezone for a given date.
 * 
 * This helper function is necessary because the moment library doesn't provide
 * a native way to get the full timezone name. It uses the Intl.DateTimeFormat API
 * to retrieve this information.
 * 
 * @param {Date} date - The date for which to get the timezone name
 * @param {string} [timezone] - The timezone to use (if not provided, uses the system default)
 * @returns {string} The long name of the timezone, or the provided timezone string if unavailable
 * 
 * @example
 * const longName = getLongTimezoneName(new Date(), 'America/New_York');
 * console.log(longName); // "Eastern Daylight Time"
 */
export const getLongTimezoneName = (date: Date, timezone?: string) => {
    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: timezone,
        timeZoneName: "long",
    });
    const parts = formatter.formatToParts(date);
    const timezoneName = parts.find(part => part.type === "timeZoneName")?.value;
    return timezoneName || timezone;
};

/**
 * Extracts raw date and time components from a Date object or string.
 * @param {Date | string} date - The date to extract components from
 * @returns {{ dateTimeString: string }} An object containing the formatted date-time string
 * @private
 */
const extractDateTimeString = (date: Date | string): { dateTimeString: string } => {
    const rawDate = moment(date).format("YYYY-MM-DD");
    const rawTime = moment(date).format("HH:mm");
    const dateTimeString = `${rawDate}T${rawTime}`;
    return { dateTimeString };
};

/**
 * Converts an event's time from its original timezone to the user's local equivalent time.
 * 
 * This function is crucial for displaying event times correctly across different timezones.
 * For example, 9am in New York might be 2pm in London and 3pm in Paris.
 * 
 * @param {Date | string} date - The original date and time of the event
 * @param {string} eventTimezoneId - The timezone ID of the event's location (e.g., 'America/New_York')
 * @returns {Date} A Date object representing the event time in the user's local timezone
 * 
 * @example
 * // If it's currently 2pm in London, and the event is at 9am in New York:
 * const localTime = getLocalEquivalentDateTime(new Date('2023-06-15T09:00:00'), 'America/New_York');
 * console.log(localTime); // Will output a Date object representing 2pm in the user's timezone
 */
export const getLocalEquivalentDateTime = (date: Date | string, eventTimezoneId: string): Date => {
    const { dateTimeString } = extractDateTimeString(date);  
    // Create a moment object in the event's timezone
    const eventTime = moment.tz(dateTimeString, eventTimezoneId);  
    // Get the user's local timezone
    const userTimezone = moment.tz.guess();  
    // Convert to the user's timezone
    const localEquivalent = eventTime.clone().tz(userTimezone);  
    return localEquivalent.toDate();
};

/**
 * Similar to getLocalEquivalentDateTime, but returns the result as an ISO string.
 * 
 * @param {Date | string} date - The original date and time of the event
 * @param {string} eventTimezoneId - The timezone ID of the event's location (e.g., 'America/New_York')
 * @returns {string} An ISO string representing the event time in the user's local timezone
 * 
 * @example
 * const localTimeString = getLocalEquivalentDateTimeString(new Date('2023-06-15T09:00:00'), 'America/New_York');
 * console.log(localTimeString); // Will output something like "2023-06-15T14:00:00.000Z"
 */
export const getLocalEquivalentDateTimeString = (date: Date | string, eventTimezoneId: string): string => {
    const { dateTimeString } = extractDateTimeString(date);  
    // Create a moment object in the event's timezone
    const eventTime = moment.tz(dateTimeString, eventTimezoneId);  
    // Get the user's local timezone
    const userTimezone = moment.tz.guess();  
    // Convert to the user's timezone
    const localEquivalent = eventTime.clone().tz(userTimezone);  
    return localEquivalent.toISOString();
};

/**
 * Converts a date to a UTC ISO string, stripping any timezone information.
 * 
 * @param {Date | string} date - The date to convert
 * @returns {string} A UTC ISO string representation of the input date
 * 
 * @example
 * const utcString = getRawUtcDateTimeString(new Date('2023-06-15T09:00:00-04:00'));
 * console.log(utcString); // Will output "2023-06-15T13:00:00.000Z"
 */
export const getRawUtcDateTimeString = (date: Date | string): string => {
    const { dateTimeString } = extractDateTimeString(date);
    return moment(dateTimeString).utc().toISOString();
};