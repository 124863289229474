import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { CreateGigDto, GigOrderByFieldKeys, GigSummary } from "../models/app/gig";
import { ApiCreateGigDto, ApiGigSummary } from "../models/api/gig";
import { mapFromApiGigSummary, mapToApiGig } from "../models/mappers/gig";
import { useStateParams } from "../hooks/useStateParams";

const useCreateGig = () => useAuthenticatedGigApiFetcher<ApiCreateGigDto, string, CreateGigDto>("POST",
    (dto) => ({
        url: "api/me/gigs",
        body: mapToApiGig(dto),
    }));

export type UseGigsParams = {
    initialPageSize?: number
    gigStatusIds?: number[]
    initialOrderBy?: GigOrderByFieldKeys
    initialSortOrder?: SortOrder
    giggedClientId?: string
    pauseFetching?: boolean
    allClients?: boolean
    hiredTalentId?: string
}

export type UseGigsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiGigSummary>>, "data"> &
    Partial<Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiGigSummary>>["data"], "items">> &
    {
        gigs: GigSummary[]
        sortOrder: SortOrder
        orderBy: GigOrderByFieldKeys
        skillIds: string[]
        expectedDurationIds: number[]
        searchTerm: string
        hasClientHistory: string
        setSearchTerm: (value: string) => void
        setSkillIds: (skillIds: string[]) => void
        setExpectedDurationIds: (expectedDurationIds: number[]) => void
        setSortOrder: (value: SortOrder) => void
        setOrderBy: (value: GigOrderByFieldKeys) => void
        setPageIndex: (value: number) => void
        setPageSize: (value: number) => void
        setHasClientHistory: (value: string) => void
        refetchGigs: () => void
        createGig: (dto: CreateGigDto) => Promise<GigApiFetcherResponse<string>>
        isCreatingGig: boolean
    }

export type UseGigs = (params?: UseGigsParams) => UseGigsReturn;

export const useGigs: UseGigs = ({
    initialPageSize,
    gigStatusIds,
    initialOrderBy,
    initialSortOrder,
    giggedClientId,
    pauseFetching,
    allClients,
    hiredTalentId,
} = {}) => {
    const [searchTerm, setSearchTerm] = useStateParams("", "searchTerm", "string");
    const [skillIds, setSkillIds] = useStateParams<string[]>([], "skillIds", "stringArray");
    const [expectedDurationIds, setExpectedDurationIds] = useStateParams<number[]>([], "expectedDurationIds", "stringArray");
    const [hasClientHistory, setHasClientHistory] = useStateParams<string>("", "hasClientHistory", "string");
    const [sortOrder, setSortOrder] = useState<SortOrder>(initialSortOrder || "asc");
    const [orderBy, setOrderBy] = useState<GigOrderByFieldKeys>(initialOrderBy || "Title");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize || 100);

    const searchTermQuery = searchTerm && searchTerm !== "" ? `&searchTerm=${searchTerm}` : "";
    const skillsQuery = skillIds.length > 0 ? `&skillIds=${skillIds.join(",")}` : "";
    const expectedDurationsQuery = expectedDurationIds.length > 0 ? `&expectedDurationIds=${expectedDurationIds.join(",")}` : "";
    const gigStatusQuery = gigStatusIds && gigStatusIds.length > 0 ? `&gigStatusIds=${gigStatusIds?.join(",")}` : "";

    const url = `api/${allClients ? "" : "me/"}gigs?` +
        `pageIndex=${pageIndex}` +
        `&pageSize=${pageSize}` +
        `&sortOrder=${sortOrder}` +
        `&orderBy=${orderBy}` +
        (hiredTalentId ? `&hiredTalentId=${hiredTalentId}` : "") +
        (giggedClientId ? `&giggedClientId=${giggedClientId}` : "") +
        gigStatusQuery +
        searchTermQuery +
        skillsQuery +
        expectedDurationsQuery;

    const { data: apiCallData, ...apiCall } =
        useGiggedApiSWR<GigApiPaginatedData<ApiGigSummary>>(pauseFetching ? null : url);

    const [createGig, isCreatingGig] = useCreateGig();

    const gigs = apiCallData?.items ? apiCallData?.items.map(mapFromApiGigSummary) : [];

    return {
        ...apiCall,
        ...apiCallData,
        gigs,
        skillIds,
        expectedDurationIds,
        searchTerm,
        sortOrder,
        orderBy,
        hasClientHistory,
        setHasClientHistory,
        setSearchTerm,
        setSkillIds,
        setExpectedDurationIds,
        setSortOrder,
        setOrderBy,
        setPageIndex,
        setPageSize,
        refetchGigs: apiCall.refetch,
        createGig: async (dto: CreateGigDto) => {
            const response = await createGig(dto);

            if (response.success) {
                apiCall.mutate();
            }
            
            return response;
        },
        isCreatingGig,
    };
};