import { useState } from "react";
import Typography from "../components/Typography";
import Button from "../components/Button";
import { useOrganizationContext } from "../api/current-organization/organizationContext";
import { useTalentGigInvites } from "../api/talentGigInvites";
import { successToast } from "../toast";
import DialogBox from "../components/DialogBox";
import GeneralFormError from "./GeneralFormError";
import ApiError from "../api/common/apiError";

export type GigChangesAcceptButtonProps = {
    gigInviteId: string;
    className?: string;
    mutate: () => void
};

export const GigChangesAcceptButton = ({
    gigInviteId,
    className,
    mutate
}: GigChangesAcceptButtonProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { acceptGigChanges, isAcceptingChanges } = useTalentGigInvites();
    const { gigTerminology } = useOrganizationContext();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>(undefined);

    const handleAccept = async () => {
        if (!gigInviteId) return;

        setIsSubmitting(true);

        const response = await acceptGigChanges(gigInviteId);

        setIsSubmitting(false);

        if (response.success) {
            mutate();
            setIsDialogOpen(false);
            successToast("Changes accepted. Event has been updated.");
            return;
        }

        setSubmissionError(response.error);
    };

    return (
        <span className={className}>
            <Button
                type="button" 
                className="w-full sm:w-auto text-center sm:flex-grow mt-2 sm:mt-0" 
                onClick={() => setIsDialogOpen(true)}
                disabled={!gigInviteId || isAcceptingChanges}
                loading={!gigInviteId}
            >
                Accept changes
            </Button>
            <DialogBox
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                title={`Accept ${gigTerminology.toLowerCase()} changes`}
            >
                <GeneralFormError error={submissionError} className="ml-8" />
                <div className="w-full space-y-6 flex flex-col">
                    <Typography variant="body" component="p">
                        Are you sure you want to accept these changes?
                    </Typography>
                    <div className="w-full justify-between flex text-right">                        
                        <Button
                            className="w-fit"
                            type="button"
                            disabled={isSubmitting}
                            onClick={() => setIsDialogOpen(false)}
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            className="w-fit"
                            type="submit"
                            loading={isSubmitting}
                            onClick={handleAccept}
                        >
                            Accept changes
                        </Button>
                    </div>
                </div>
            </DialogBox>
        </span>
    );
};