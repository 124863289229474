import { MsalProvider } from "@azure/msal-react";
import { initializeMsal, msalInstance } from ".";
import AuthContext from "./authContext";
import { useLoginRequest } from "./useLoginRequest";
import Loader from "../components/Loader";
import { useEffect, useState } from "react";

export type AuthContextProviderProps = {
    children: React.ReactNode
}

const AuthContextProvider = ({
    children,
}: AuthContextProviderProps) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const { loginRequest, isLoading } = useLoginRequest();

    useEffect(() => {
        const init = async () => {
            await initializeMsal();
            setIsInitialized(true);
        };

        init();
    }, []);

    if (!isInitialized || isLoading || !loginRequest) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider value={{
            loginRequest
        }}>
            <MsalProvider instance={msalInstance}>
                {children}
            </MsalProvider>
            
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;