import clsx from "clsx";
import { X } from "phosphor-react";

export type ClearFiltersButtonProps = {
    onClick: () => void
    disabled?: boolean
}

const ClearFiltersButton = ({
    onClick,
    disabled,
}: ClearFiltersButtonProps) => {

    return (
        <div className={clsx(
            "border-b border-surface p-4 m-auto")}>
            <button
                className="flex mx-auto items-center disabled:text-disabled-text"
                onClick={onClick}
                disabled={disabled}
            >
                <X weight="bold" size="20" className="mr-2" />
                Clear filters
            </button>
        </div>
    );
};

export default ClearFiltersButton;
