import Chip, { ChipContainer } from "../../components/Chip";
import { TalentIndustrialExperienceDto } from "../../models/app/talentIndustrialExperience";

export type TalentIndustrialExperienceChipsProps = {
    industrialExperience: TalentIndustrialExperienceDto[],
    removeIndustrialExperience: (indsutrialExperienceId: string) => void,
    isRemovingIndustrialExperience: boolean
}

const TalentIndustrialExperienceChips = ({
    industrialExperience,
    removeIndustrialExperience,
    isRemovingIndustrialExperience,
}: TalentIndustrialExperienceChipsProps) => {

    if (isRemovingIndustrialExperience) return (
        <p className="mt-2">Loading...</p>
    );

    return (
        <ChipContainer className="pt-6">
            {industrialExperience
                .sort((a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()))
                .map(industry => (
                    <Chip
                        key={`talent-industrial-experience-${industry.industrialExperienceId}`}
                        label={industry.industrialExperienceName}
                        onDelete={() => removeIndustrialExperience(industry.industrialExperienceId)}
                    />
                ))
            }
        </ChipContainer>
    );
};

export default TalentIndustrialExperienceChips;