import gigApiFetcher from "./common/fetching";
import { IndustrialExperienceDto } from "../models/app/industrialExperience";

export const fetchIndustrialExperienceList = async (query: string | undefined, accessToken: string | null): Promise<IndustrialExperienceDto[]> => {
    const queryString = query ? `?searchTerm=${query}` : "";

    const response = await gigApiFetcher<never, IndustrialExperienceDto[]>({
        url: `api/industrial-experience${queryString}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchIndustrialExperienceOptions = async (query: string, accessToken: string | null) => {
    const industryExperiencesResponse = await fetchIndustrialExperienceList(query, accessToken);
    
    return industryExperiencesResponse
        .map(industry => ({
            value: industry.id,
            label: industry.name,
        }));
};