import DialogBox from "../DialogBox";
import { Chip, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { EventImpl } from "@fullcalendar/core/internal";
import Typography from "../Typography";
import CardDataFieldLabel from "../Card/CardDataFieldLabel";
import { Eye } from "phosphor-react";
import { Link } from "react-router-dom";
import { useAppPaths } from "../../Routes";
import { Info } from "phosphor-react";
import { getLongTimezoneName } from "../../utils/dateFormatters";

interface EventDescriptionProps {
    text: string;
}
  
const EventDescription: React.FC<EventDescriptionProps> = ({ text }) => (
    <>
        {text.split(".").map((line, index) => {
            const [label, ...rest] = line.split(":");
            const content = rest.join(":");
            
            return (
                <Typography
                    variant="body"
                    component="p"
                    className="whitespace-pre-wrap"
                    key={index}
                >
                    <em>{label.trim()}</em>
                    {content ? " :" : ""}
                    {content && ` ${content.trim()}`}
                </Typography>
            );
        })}
    </>
);

const EventDetailsDialog = ({
    event,
    isOpen,
    onClose,
}: {
    event: EventImpl;
    isOpen: boolean;
    onClose: () => void;
}) => {
    const appPaths = useAppPaths();
    // Had to be done this way since Nylas doesn't allow you to set key names
    const getValueFromOldMetadata = (metadata: { [key: string]: string }, searchKey: string) => {
        if (metadata) {
            // Loop through the keys of the metadata object
            for (const key of Object.keys(metadata)) {
                // Split the key into key and value using ':'
                const [keyPart, valuePart] = metadata[key].split(":");
                // Check if the key part matches the searchKey
                if (keyPart.trim() === searchKey) {
                    return valuePart.trim(); // Return the value part
                }
            }
        }
        return null; // Return null if key is not found or metadata is null
    };
    // Previous method was inaccurate as per Nylas docs: https://developer.nylas.com/docs/dev-guide/metadata/#what-is-metadata%3F
    const getValueFromMetadata = (metadata: { [key: string]: string }, searchKey: string): string | null => {
        if (metadata && searchKey in metadata) {
            return metadata[searchKey];
        }
        //If not found it's possible that previously styled events metadata contains the key in a different format 
        // so let's forward to getValueFromOldMetadata to handle old events metadata formatting getValueFromOldMetadata(metadata, searchKey);
        return getValueFromOldMetadata(metadata, searchKey);
    };

    const extendedProps = event?.extendedProps;
    const gigId = getValueFromMetadata(extendedProps.metaData, "gigId");
    const pendingChanges = getValueFromMetadata(extendedProps.metaData, "pendingChanges");
    const expectedNumberParticipants = getValueFromMetadata(extendedProps.metaData, "expectedNumberParticipants");
    const description = extendedProps.description;
    const location = extendedProps.location;
    const timezone = extendedProps.timezone;
    const timezoneName = extendedProps.timezone ? getLongTimezoneName(event.start ?? new Date(), timezone) : "";
    const startTime = event?.startStr ? moment(event.startStr).format("MMM Do YYYY, h:mma") : "-";
    const endTime = event?.endStr ? moment(event.endStr).format("MMM Do YYYY, h:mma") : "-";
    const locationStartTime = moment.tz(event.start, timezone).format("MMM Do YYYY, h:mma z");

    return (
        <DialogBox isOpen={isOpen} title={"Event Details"} onClose={onClose}>
            <div className="md:mr-8 overflow-hidden relative">
                <div className="space-y-6">
                    <div className="space-y-4">
                        <Typography variant="title-medium" component="h3">
                            {event?.title}
                        </Typography>
                    </div>
                    {gigId && (
                        <Typography variant="body" component="p">
                            <b>Event ID:</b> {gigId}
                        </Typography>
                    )}
                    {pendingChanges && (
                        <Chip
                            label={pendingChanges === "false" ? "Confirmed" : "Pending changes"}
                            color={pendingChanges === "false" ? "success" : "warning"}
                            variant="filled"
                        />
                    )}
                    <div className="space-y-4">
                        <div>
                            <div className="flex flex-row gap-2">
                                <CardDataFieldLabel>Start: </CardDataFieldLabel>
                                <Typography variant="body" component="p">
                                    {startTime}
                                </Typography>
                                <Tooltip title="Local time: Times are relative to your local timezone.">
                                    <Info size={22}/>                                
                                </Tooltip>  
                            </div>
                            <div className="flex flex-row gap-2">
                                <CardDataFieldLabel>End: </CardDataFieldLabel>
                                <Typography variant="body" component="p">
                                    {endTime}
                                </Typography>
                                <Tooltip title="Local time: Times are relative to your local timezone.">
                                    <Info size={22}/>                                
                                </Tooltip>                                
                            </div>                            
                        </div>
                        {description && (
                            <div className="flex flex-col">
                                <CardDataFieldLabel>Description: </CardDataFieldLabel>
                                <EventDescription text={description}/>
                            </div>
                        )}                        
                        {expectedNumberParticipants && (
                            <div className="flex flex-row gap-2">
                                <CardDataFieldLabel>Number of participants: </CardDataFieldLabel>
                                <Typography
                                    variant="body"
                                    component="p"
                                    className="whitespace-pre-wrap"
                                >
                                    {expectedNumberParticipants}
                                </Typography>
                            </div>
                        )}
                        {location && (
                            <div className="flex flex-row gap-2">
                                <CardDataFieldLabel>Location: </CardDataFieldLabel>
                                <Typography
                                    variant="body"
                                    component="p"
                                    className="whitespace-pre-wrap"
                                >
                                    {location}
                                </Typography>
                            </div>
                        )}
                        {timezone && (
                            <div className="flex flex-row gap-2">
                                <CardDataFieldLabel>Timezone: </CardDataFieldLabel>
                                <Typography variant="body" component="p">
                                    {timezone} ({timezoneName})
                                </Typography>
                                <Tooltip title={`Start time in event location: ${locationStartTime}`}>
                                    <Info size={22}/>                                
                                </Tooltip>
                            </div>
                        )}
                        {gigId && (
                            <IconButton>
                                <Link to={appPaths.gigs.details(gigId)}>
                                    <Eye size={20} />
                                </Link>
                            </IconButton>
                        )}
                    </div>
                </div>
            </div>
        </DialogBox>
    );
};

export default EventDetailsDialog;
