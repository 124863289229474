import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import {
    EventClickArg,
    EventInput,
    EventSourceFuncArg
} from "@fullcalendar/core";
import { CircularProgress } from "@mui/material";
import { useIsLargeScreen } from "../../hooks/screenSizes";

type CalendarProps = {
    events: (
        arg: EventSourceFuncArg,
        successCallback: (events: EventInput[]) => void,
        failureCallback: (error: Error) => void
    ) => void;
    onEventClick: (clickInfo: EventClickArg) => void;
    contentHeight?: string | number;
};

export const Calendar: React.FC<CalendarProps> = ({ 
    events, 
    onEventClick, 
    contentHeight, 
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const isLargeScreen = useIsLargeScreen();

    return (
        <>
            <div className="relative">
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, momentTimezonePlugin]}
                    headerToolbar={{
                        left: isLargeScreen ? "today prev,next" : "prev,next",
                        center: isLargeScreen ? "title" : "",
                        right: isLargeScreen ? "dayGridMonth timeGridWeek timeGridDay listMonth" : "title"
                    }}
                    footerToolbar={{
                        left: !isLargeScreen ? "today" : "",
                        right: !isLargeScreen ? "dayGridMonth timeGridWeek timeGridDay listMonth" : "",
                    }}
                    weekNumbers
                    contentHeight={contentHeight}
                    buttonText={{
                        "day": "Day",
                        "week": "Week",
                        "month": "Month",
                        "today": "Today",
                        "list": "List",
                    }}
                    events={events}
                    lazyFetching={true}
                    editable={true}
                    eventClick={(clickInfo) => {
                        onEventClick(clickInfo);
                    }}
                    loading={setIsLoading}                    
                    eventTimeFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        omitZeroMinute: true,
                        meridiem: "short",
                        timeZoneName: "short", // Adds GMT/UTC offset
                    }}
                    eventDidMount={(info) => {
                        // Access the DOM element of the event
                        const eventElement = info.el;
                    
                        // Manipulate the inner content
                        const titleElement = eventElement.querySelector(".fc-event-title") as HTMLElement;
                        if (titleElement) {
                            titleElement.style.whiteSpace = "nowrap";
                            titleElement.style.overflow = "hidden";
                            titleElement.style.textOverflow = "ellipsis";
                        }

                        // Browser native tooltip
                        eventElement.setAttribute("title", info.event.title);
                    }}
                    timeZone="local"
                    themeSystem="standard"
                />
                {isLoading && (
                    <div className="absolute top-16 left-0 right-0 bottom-0 bg-white bg-opacity-50 z-10 flex justify-center items-center">
                        <CircularProgress sx={{ color: "#36dd93" }} />
                    </div>
                )}
            </div>
        </>
    );
};

export default Calendar;
