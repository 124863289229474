import { Plus } from "phosphor-react";
import Button from "../../components/Button";

export type HistoryListAddItemButtonProps = {
    children: string
    onClick: () => void
}

const HistoryListAddItemButton = ({
    children,
    onClick,
}: HistoryListAddItemButtonProps) => {

    return (
        <div className="flex justify-center border-2 border-dashed rounded-lg p-4">
            <Button variant="secondary" onClick={onClick} className="flex items-center">
                <Plus className="mr-2" /> {children}
            </Button>
        </div>
    );
};

export default HistoryListAddItemButton;