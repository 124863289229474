import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

import Button from "../Button";
import CreateGigFormPageLayout from "./CreateGigFormPageLayout";
import { GigFormValues, buildGigFormValidationSchemaFields } from "../GigForm";
import { formClassNames } from "./classNames";
import FormRadioGroup from "../FormRadioGroup";
import { radioOptions } from "../../models/app/gig";
import FormTextInput from "../FormTextInput";
import { useEffect } from "react";

export type CreateGigFormStep3Fields = Pick<GigFormValues,
    "isDesignFeeTypeRequired" |
    "isConsultancyFeeTypeRequired" |
    "isPrepAndEvalFeeTypeRequired" |
    "designFeeTypeHours" |
    "consultancyFeeTypeHours" |
    "prepAndEvalFeeTypeHours"
>;

export type CreateGigFormStep3Props = {
    onSubmit: (value: CreateGigFormStep3Fields) => void
    onBackClick: (value: CreateGigFormStep3Fields) => void
    defaultValues: CreateGigFormStep3Fields
}

const CreateGigFormStep3 = ({
    onSubmit,
    onBackClick,
    defaultValues,
}: CreateGigFormStep3Props) => {

    const validationSchema = yup.object({
        isDesignFeeTypeRequired: buildGigFormValidationSchemaFields.isDesignFeeTypeRequired,
        isConsultancyFeeTypeRequired: buildGigFormValidationSchemaFields.isConsultancyFeeTypeRequired,
        isPrepAndEvalFeeTypeRequired: buildGigFormValidationSchemaFields.isPrepAndEvalFeeTypeRequired,
        designFeeTypeHours: buildGigFormValidationSchemaFields.designFeeTypeHours,
        consultancyFeeTypeHours: buildGigFormValidationSchemaFields.consultancyFeeTypeHours,
        prepAndEvalFeeTypeHours: buildGigFormValidationSchemaFields.prepAndEvalFeeTypeHours

    });

    const methods = useForm<CreateGigFormStep3Fields>({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const handleBackClick = () => {
        onBackClick(methods.getValues());
    };

    const isDesignFeeRequired = methods.watch("isDesignFeeTypeRequired");
    const isConsultancyFeeRequired = methods.watch("isConsultancyFeeTypeRequired");
    const isPrepAndEvalFeeReqired = methods.watch("isPrepAndEvalFeeTypeRequired");

    useEffect(function resetFeeTypeFieldsWhenFeeIsFalse() {
        if (isDesignFeeRequired === "false") methods.setValue("designFeeTypeHours", undefined);
        if (isConsultancyFeeRequired === "false") methods.setValue("consultancyFeeTypeHours", undefined);
        if (isPrepAndEvalFeeReqired === "false") methods.setValue("prepAndEvalFeeTypeHours", undefined);
    }, [isDesignFeeRequired, isConsultancyFeeRequired, isPrepAndEvalFeeReqired]);

    return (
        <form
            className={formClassNames}
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
        >
            <CreateGigFormPageLayout
                title="Event Customisation"
                description="Please indicate whether the event requires any customisation by the AC."
                content={
                    <span className="space-y-6">
                        <Controller
                            name="isDesignFeeTypeRequired"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormRadioGroup
                                    required
                                    label="Does the event require customisation of content and/or addition of new content?"
                                    options={radioOptions}
                                    error={methods.formState.errors.isDesignFeeTypeRequired}
                                    defaultValue={value}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                        {isDesignFeeRequired == "true" && (
                            <FormTextInput
                                required={isDesignFeeRequired == "true" ? true : false}
                                id="create-gig-form-design-fee-hours"
                                label="No. of design hours"
                                placeholder="Number of hours"
                                error={methods.formState.errors.designFeeTypeHours}
                                defaultValue={defaultValues.designFeeTypeHours}
                                type="number"
                                onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                {...methods.register("designFeeTypeHours")}
                            />
                        )}
                        <Controller
                            name="isConsultancyFeeTypeRequired"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormRadioGroup
                                    required
                                    label="Does the event require time spent using consultancy expertise to assist the client in achieveing the value or impact required?"
                                    options={radioOptions}
                                    error={methods.formState.errors.isConsultancyFeeTypeRequired}
                                    defaultValue={value}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                        {isConsultancyFeeRequired == "true" && (
                            <FormTextInput
                                required={isConsultancyFeeRequired == "true" ? true : false}
                                id="create-gig-form-consultancy-fee"
                                label="No. of consultancy hours"
                                placeholder="Number of hours"
                                error={methods.formState.errors.consultancyFeeTypeHours}
                                defaultValue={defaultValues.consultancyFeeTypeHours}
                                type="number"
                                onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                {...methods.register("consultancyFeeTypeHours")}
                            />
                        )}
                        <Controller
                            name="isPrepAndEvalFeeTypeRequired"
                            control={methods.control}
                            render={({ field: { onChange, value } }) => (
                                <FormRadioGroup
                                    required
                                    label="Does the event require additional time spent in preparation or evaluation to achieve a successful outcome? (prep and eval)?"
                                    options={radioOptions}
                                    error={methods.formState.errors.isPrepAndEvalFeeTypeRequired}
                                    defaultValue={value}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                        {isPrepAndEvalFeeReqired == "true" && (
                            <FormTextInput
                                required={isPrepAndEvalFeeReqired == "true" ? true : false}
                                id="create-gig-form-prep-and-eval-fee"
                                label="No. of prep and eval hours"
                                placeholder="Number of hours"
                                error={methods.formState.errors.prepAndEvalFeeTypeHours}
                                defaultValue={defaultValues.prepAndEvalFeeTypeHours}
                                type="number"
                                onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                {...methods.register("prepAndEvalFeeTypeHours")}
                            />
                        )}
                    </span>
                }
                iconName="Path"
                buttons={
                    <>
                        <Button variant="secondary" onClick={handleBackClick} type="button">Back</Button>
                        <Button type="submit">Next</Button>
                    </>
                }
            />
        </form>
    );
};

export default CreateGigFormStep3;