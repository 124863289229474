import { Event } from "../models/api/nylas";

export type ParsedWhenObject = {
    start: Date;
    end: Date;
    timezone?: string;
    isAllDay: boolean;
}

export const parseWhenObject = (when: Event["when"]): ParsedWhenObject | Error => {
    const time = when?.time || 0;
    const tTimezone = when?.timezone;
    const start_time = when?.start_time || 0;
    const end_time = when?.end_time || 0;
    const start_timezone = when?.start_timezone;
    const date = when?.date || "";
    const start_date = when?.start_date || "";
    const end_date = when?.end_date || "";

    if (when.object === "timespan") {
        return {
            start: new Date(start_time * 1000),
            end: new Date(end_time * 1000),
            timezone: start_timezone,
            isAllDay: false
        };
    } else if (when.object === "time") {
        return {
            start: new Date(time * 1000),
            end: new Date(time * 1000 + 60 * 60 * 1000), // Add one hour as default duration
            timezone: tTimezone,
            isAllDay: false
        };
    } else if (when.object === "datespan") {
        const start = new Date(start_date);
        const end = new Date(end_date);
        start.setHours(0, 0, 0, 0); // Ensure start at midnight
        end.setHours(23, 59, 59, 999); // Ensure full-day coverage
        return { start, end, isAllDay: true };
    } else if (when.object === "date") {
        const startDate = new Date(date);
        startDate.setHours(0, 0, 0, 0); // Ensure start at midnight
        const endDate = new Date(startDate);
        endDate.setHours(23, 59, 59, 999); // Ensure full-day coverage
        return {
            start: startDate,
            end: endDate,
            isAllDay: true
        };
    } else {
        throw new Error(`Unsupported when object type: ${when.object}`);
    }
};