import { useState, useEffect, useCallback } from "react";
import { Event } from "../../models/api/nylas";
import { useFetchEvents, useFetchMyEvents } from "../../api/nylas";
import { EventInput } from "@fullcalendar/core";
import { ParsedWhenObject, parseWhenObject } from "../../utils/nylasEventWhenObjectParser";

function isParseWhenObjectResult(result: ReturnType<typeof parseWhenObject>): result is ParsedWhenObject {
    return (result as ParsedWhenObject).start !== undefined;
}

function useNylasCalendar(talentId?: string) {
    const [events, setEvents] = useState<EventInput[]>([]);
    const [timeRange, setTimeRange] = useState<{ start?: number; end?: number }>({});    

    // Use the timeRange state in the SWR hooks
    const { data: eventsData, error, mutate } = talentId
        ? useFetchEvents(talentId, timeRange.start, timeRange.end)
        : useFetchMyEvents(timeRange.start, timeRange.end);

    useEffect(() => {
        if (eventsData) {
            const formattedEvents = eventsData
                .map((event: Event) => {
                    const parseResult = parseWhenObject(event.when);
            
                    if (isParseWhenObjectResult(parseResult)) {
                        const { start: parsedStart, end: parsedEnd, timezone, isAllDay } = parseResult;
                        return {
                            id: event.id,
                            title: event.title,
                            start: parsedStart,
                            end: parsedEnd,
                            allDay: isAllDay,
                            extendedProps: {
                                calendarId: event.calendar_id,
                                description: event.description || "",
                                location: event.location || "",
                                metaData: event.metadata,
                                timezone: timezone,
                            },
                        } as EventInput;
                    } else {
                        console.error(`Error parsing event '${event.title}': `,  parseResult.message);
                        return null;
                    }
                })
                .filter((event): event is EventInput => event !== null);
        
            setEvents(formattedEvents);
        }
    }, [eventsData]);

    const fetchEvents = useCallback((start: number, end: number) => {
        setTimeRange({ start, end });
        return mutate();
    }, [mutate]);

    return { events, error, fetchEvents };
}

export default useNylasCalendar;
