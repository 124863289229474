import { useNavigate } from "react-router-dom";
import { hasClaim } from "../auth/hasClaim";
import useCurrentUser from "../auth/useCurrentUser";
import Button from "../components/Button";
import PageContent from "../components/PageContent";
import Typography from "../components/Typography";
import { UserRole } from "../models/app/userMe";

export const FourOThreePage = () => {
    const navigate = useNavigate();
    const { claims } = useCurrentUser();

    const handleGoBack = () => {
        navigate(-2);
    };

    return (
        <PageContent>
            <Typography variant="display-large" component="h1" gutterBottom className="text-center text-primary font-bold md:text-[12rem] mt-8">403</Typography>
            <Typography variant="title-large" component="p" gutterBottom className="text-center md:text-title-large text-title-small">You're not authorized to view the requested resource.</Typography>
            {(hasClaim(UserRole.AC, claims) || hasClaim(UserRole.Default, claims)) && (
                <div className="text-center mt-8">
                    <Button variant="primary" onClick={handleGoBack}>
                        Go Back
                    </Button>
                </div>
            )}
        </PageContent>
    );
};
