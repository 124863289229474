import gigApiFetcher from "./common/fetching";
import { LocalityDto, LocationDto } from "../models/app/location";
import { FormDropdownOption } from "../components/FormDropdown";

export const fetchLocations = async (query = "", accessToken: string | null): Promise<LocationDto[]> => {
    const response = await gigApiFetcher<never, LocationDto[]>({
        url: `api/locations?searchTerm=${query}`,
        method: "GET",
        body: undefined,
        token: accessToken || undefined,
    });

    return response;
};

export const fetchLocationOptions = async (query: string, accessToken: string | null): Promise<FormDropdownOption<string>[]> => {
    const locationsResponse = await fetchLocations(query, accessToken);

    return locationsResponse
        .map(location => ({
            value: location.id,
            label: location.country,
            localities: location.localities.map((locality: LocalityDto) => ({
                value: locality.localityId,
                label: locality.locality,
                ianaId: locality.ianaId
            }))
        }));
};