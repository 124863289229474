import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { AddTalentLanguageDto, ApiTalentLanguageDto } from "../models/api/talentLanguage";

const useAddTalentLanguage = () => useAuthenticatedGigApiFetcher<AddTalentLanguageDto, void, { languageId: string, languageProficiencyId: number }>("POST", 
    ({ languageId, languageProficiencyId }) => ({
        url: `api/me/language/${languageId}`,
        body: {languageProficiencyId}
    })
);

const useRemoveTalentLanguage = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (languageId: string) => ({
        url: `api/me/language/${languageId}`,
    })
);

export type UseTalentLanguageReturn =
    UseGiggedApiSWRReturn<ApiTalentLanguageDto[]> &
    {
        talentLanguage: ApiTalentLanguageDto[]
        addTalentLanguage: (languageId: string, languageProficiencyId: number) => Promise<GigApiFetcherResponse<void>>
        removeTalentLanguage: (languageId: string) => Promise<GigApiFetcherResponse<void>>
        isAddingTalentLanguage: boolean
        isRemovingTalentLanguage: boolean
    }

export const useTalentLanguage = (): UseTalentLanguageReturn => {
    const apiCall = useGiggedApiSWR<ApiTalentLanguageDto[]>("api/me/language");
    const [addTalentLanguage, isAddingTalentLanguage] = useAddTalentLanguage();
    const [removeTalentLanguage, isRemovingTalentLanguage] = useRemoveTalentLanguage();

    return {
        ...apiCall,
        talentLanguage: apiCall.data || [],
        addTalentLanguage: async (languageId: string, languageProficiencyId: number) => {
            const response = await addTalentLanguage({ languageId, languageProficiencyId });

            if (response.success) apiCall.mutate();

            return response;
        },
        removeTalentLanguage: async (industrialExperienceId: string) => {
            const response = await removeTalentLanguage(industrialExperienceId);

            if (response.success) apiCall.mutate();

            return response;
        },
        isAddingTalentLanguage,
        isRemovingTalentLanguage
    };
};